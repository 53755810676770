import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Avatar, Button, Typography } from 'antd';
import { UserService, auth, MerchantService } from '../../../../services';
import { handleApiExceptions, alertify, resolve_server_image } from '../../../../app_methods';
import './index.scss';

class MerchantBasicDetails extends Component {
    // fetchMerchant = this.fetchMerchant.bind(this);
    state = {
        processing: false,
        icon: {}
    };

    componentDidMount() {
        this.props.form.setFieldsValue({ merchant: this.props.merchant });
        // if (this.props.merchant.is_edit) {
        // }
    }

    // fetchMerchant() {
    //     MerchantService.show({ id: auth.user.merchant.id }).then((response) => {
    //         if (response.success) {
    //             const icon = this.state.icon;
    //             icon.avatar = response.data.image ? resolve_server_image(response.data.image) : undefined;
    //             this.setState({
    //                 merchant: response.data,
    //                 icon: icon
    //             })
    //             this.props.form.setFieldsValue({ merchant: response.data });
    //         }
    //     }).catch((error) => {
    //         handleApiExceptions(error);
    //     })
    // }

    componentWillReceiveProps(nextProps) {
        if (this.props.merchant.id !== nextProps.merchant.id)
            nextProps.form.setFieldsValue({ merchant: nextProps.merchant });
    }

    updateAvatar(id) {
        let formData = new FormData();
        formData.set('image', this.state.icon.avatarFile);
        MerchantService.update_icon(
            { formdata: formData, id },
            { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then((response) => {
            if (response.success) {
                alertify("Awesome!!!", `Service image updated successfully`, "success");
            }
            else {
                alertify("Oops!!!", response.message, "error");
                this.setState({ processing: false });
            }
        });
    }

    createMerchant(e) {
        e.preventDefault();
        if (this.props.merchant.is_edit) {
            this.updateMerchant();
            return;
        }
        this.props.form.validateFields((err, values) => {
            if (err) { return; }
            this.setState({ processing: true });
            MerchantService.create({ ...values.merchant }).then((response) => {
                if (response.success) {
                    this.updateAvatar(response.data.id);
                    alertify("Awesome!!!", "Business created successfully", "success");
                    this.setState({ processing: false });
                    this.props.afterSubmit();
                }
                else {
                    alertify("Oops!!!", response.message, "error");
                    this.setState({ processing: false });
                }
            }).catch((err) => {
                handleApiExceptions(err);
                this.setState({ processing: false });
            });
        });
    }

    updateMerchant() {
        this.props.form.validateFields((err, values) => {
            if (err) { return; }
            this.setState({ processing: true });
            MerchantService.update({ ...values.merchant, id: auth.user.merchant.id }).then((response) => {
                if (response.success) {
                    this.updateAvatar(response.data.id);
                    alertify("Awesome!!!", "Business updated successfully", "success");
                    this.setState({ processing: false });
                    this.props.afterSubmit();
                }
                else {
                    alertify("Oops!!!", response.message, "error");
                    this.setState({ processing: false });
                }
            }).catch((err) => {
                handleApiExceptions(err);
                this.setState({ processing: false });
            });
        });
    }

    handleAvatarChange(e) {
        const icon = this.state.icon;
        icon.avatarFile = e.target.files[0];
        icon.avatar = URL.createObjectURL(e.target.files[0]);
        this.setState({ icon });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="mx-auto mt-4 merchant-basic-details-form-wrapper">
                <Form layout="vertical" onSubmit={this.createMerchant.bind(this)}>
                    <div className="service-icon p-relative mx-auto c-pointer mb-5" style={{ width: 120, height: 120, overflow: "hidden", borderRadius: "50%" }}>
                        <img onClick={() => { this.refs.avatarUploader.click(); }} src={this.state.icon.avatar || require('../../../../assets/images/default.png')} className="w-100" />
                        <input type="file" id="avatar" ref="avatarUploader" onChange={this.handleAvatarChange.bind(this)} style={{ display: "none" }} />
                    </div>
                    {
                        this.props.hideBookingUrl ? null : (
                            <div className="booking-link mb-3">
                                <Typography.Text strong>Booking Link:</Typography.Text>
                                <Typography.Text className="ml-3 c-pointer"><Link to={`/booking/${auth.user.merchant.uid}`} target="_blank">{`/booking/${auth.user.merchant.uid}`}</Link></Typography.Text>
                            </div>
                        )
                    }
                    {
                        this.props.hideBookingUrl ? null : (
                            <div className="booking-link mb-3">
                                <Typography.Text strong>Customer Login Link:</Typography.Text>
                                <Typography.Text className="ml-3 c-pointer"><Link to={`/customer/login/${auth.user.merchant.uid}`} target="_blank">{`/customer/login/${auth.user.merchant.uid}`}</Link></Typography.Text>
                            </div>
                        )
                    }
                    <Form.Item label="Business Name">
                        {getFieldDecorator('merchant.name', {
                            rules: [
                                { required: true, message: "Business Name is mandatory." }
                            ]
                        })(
                            <Input placeholder="Business/Company name" />
                        )}
                    </Form.Item>
                    <div>
                        <Form.Item label="Owner Name" className="d-inline-block" style={{ width: "49%" }} >
                            {getFieldDecorator('merchant.spoc_name', {
                                rules: [
                                    { required: true, message: "Owner Name is mandatory." }
                                ]
                            })(
                                <Input disabled={this.props.processing} placeholder="Business owner name" />
                            )}
                        </Form.Item>
                        <Form.Item label="Owner Phone" className="d-inline-block ml-2" style={{ width: "49%" }}>
                            {getFieldDecorator('merchant.spoc_number', {
                                rules: [
                                    { required: true, message: "Owner mobile number is mandatory." }
                                ]
                            })(
                                <Input disabled={this.props.processing} placeholder="Business owner phone" />
                            )}
                        </Form.Item>
                    </div>
                    <Form.Item label="Business Email">
                        {getFieldDecorator('merchant.spoc_email', {
                            rules: [
                                { required: true, message: "Owner email is mandatory." }
                            ]
                        })(
                            <Input placeholder="Business email address" disabled={this.props.merchant.is_edit} />
                        )}
                    </Form.Item>
                    <Form.Item label="Business Address" >
                        {getFieldDecorator('merchant.address', {})(
                            <Input.TextArea disabled={this.props.processing} placeholder="Business address" />
                        )}
                    </Form.Item>
                    <Form.Item className="float-right">
                        <Button loading={this.props.processing} htmlType="submit" type="primary">Update</Button>
                    </Form.Item>
                    <div className="clearfix"></div>
                </Form>
            </div>
        );
    }
}

MerchantBasicDetails.defaultProps = {
    afterSubmit: () => { },
    hideBookingUrl: false
};


const FormWrapper = Form.create()(MerchantBasicDetails);
export default FormWrapper;
