import React from 'react';
import { isMobile } from 'react-device-detect';
import { Select, Popover, Typography, Button } from 'antd';
import { PermissionHoc } from '../../../HOC';
const PermissableButton = PermissionHoc(Button, '', { is_component: true, fallbackComponent: null });

const CategoryListMobile = (props) => {
    console.log('CategoryListMobile', props);
    const handleCategoryChange = (id) => {
        const active_category = props.categories.find(x => x.id === id);
        props.selectCategory(active_category);
    };

    return (
        <div className={`w-100 mb-2 category-list-select-box ${isMobile ? 'd-flex' : 'd-none'}`}>
            <Select placeholder="Select Category" className="w-100" value={props.activeCategory.id} onChange={handleCategoryChange}>
                {
                    props.categories &&
                    props.categories.map((category, index) => {
                        return (
                            <Select.Option key={index} value={category.id}>{category.name}</Select.Option>
                        );
                    })
                }
            </Select>
            <Popover placement="bottom" className="ml-2" trigger={["click", "hover"]} title={<Typography.Text strong>Add Category</Typography.Text>} content={props.renderAddCategory()}>
                <PermissableButton required_permission={["SETTINGS:SERVICE:ADD"]} icon="plus" />
            </Popover>
        </div>
    );
};

CategoryListMobile.defaultProps = {
    activeCategory: {}
};

export default CategoryListMobile;