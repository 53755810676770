import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Layout, Button } from 'antd';
import { isMobile } from 'react-device-detect';
import { auth } from '../../services';
const { Content } = Layout;

class OpenBookingLayout extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(this.props.location.search);
        this.state = {
            hideNavbar: params.get('framed') || false
        }
        // this.state = {date: new Date()};
    }
    getContentStyles() {
        return this.state.hideNavbar ? styles.contentWithoutHeader : styles.content;
    }
    render() {
        return (
            <div className="customer-layout-container">
                <div className="customer-layout-body" style={{minHeight: "100%"}}>
                    <Layout className="main-layout-content-wrapper" style={styles.layoutInnerCollapsed}>
                        {!this.state.hideNavbar &&
                        <Layout.Header className="am-navbar lobo-bg-white d-flex justify-content-between align-items-center" style={{ ...styles.header }}>
                            <div className="px-3 w-100 d-flex align-items-center justify-content-between">
                                <h1 className="Rancho color-white"
                                    style={{marginBottom: 0}}>Appointment Ninja</h1>
                                {/* <Button>Login</Button> */}
                            </div>
                        </Layout.Header>}
                        <Content style={this.getContentStyles()}>
                            {React.cloneElement(this.props.children)}
                        </Content>
                    </Layout>
                </div>
            </div>
        )
    }
}

const styles = {
    layoutInnerCollapsed: {
        marginLeft: 0,
        transition: 'all .2s',
        WebkitTransition: 'all .2s'
    },
    layoutInner: {
        marginLeft: isMobile ? 0 : 0,
        transition: 'all .2s',
        WebkitTransition: 'all .2s'
    },
    content: {
        // background: '#F1F5F7',
        backgroundColor: 'white',
        padding: '0px',
        overflow: 'initial',
        marginTop: 60
    },
    contentWithoutHeader: {
        // background: '#F1F5F7',
        padding: '0px',
        overflow: 'initial',
    },
    header: {
        padding: 0,
        height: "60px",
        lineHeight: "60px",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9,
        transition: 'all .2s',
        WebkitTransition: 'all .2s',
        backgroundColor: 'white'
    }
    
}

export default withRouter(OpenBookingLayout);
