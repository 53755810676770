
import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';

const TableToolbar = (props) => {
  return (
    <div className="w-100" style={{
      padding: 16,
      border: '1px solid #e8e8e8',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottomWidth: 0,
      backgroundColor: '#FAFAFA'
    }}>
      <div className="d-flex">
        <div className="d-flex justify-content-between w-100">
          <Input.Search
            placeholder="Search"
            allowClear
            onSearch={value => props.onSearch(value)}
            style={{ width: '100%', maxWidth: 240 }}
          />
          <div>
            <Button shape='circle' icon='reload' onClick={props.onRefresh} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableToolbar;