import React, { Component } from 'react';
import _flatten from 'lodash/flatten';
import { CalendarView, SessionForm } from '../../Common';
import moment, { invalid } from 'moment/moment';
import { Select, Button, Typography, Tag, message } from 'antd';
import { EaseModal } from '../../../libs/EaseModal';
import { AppointmentService, auth, UserService, WorkingHourService, BreakHourService, BusinessService, HolidayService } from '../../../services';
import { appointment_date_format, user_fullname, handleApiExceptions } from '../../../app_methods';
import { PermissionHoc } from '../../HOC';
import './index.scss';
import { isMobile } from 'react-device-detect';
import { width } from '@fortawesome/free-solid-svg-icons/faPowerOff';

class AdminCalendar extends Component {
    user_permissions = auth.permissions();
    calendar = null;

    state = {
        settings: auth.settings(),
        appointments: [],
        appointmentsOriginal: [],
        staffList: [],
        activeAppointment: {},
        appointmentModal: false,
        activeStaff: {},
        activeService: {},
        activeCustomer: {},
        staffWorkingHours: [],
        staffBreakHours: [],
        businessHours: [],
        services: [],
        customers: [],
        holidays: []
    };

    componentDidMount() {
        this.fetchStaff();
        this.fetchServices();
        this.fetchCustomers();
        this.fetchHolidays();
    }


    fetchHolidays = () => {
        this.setState({ processing: true });
        HolidayService.list({ merchant: auth.user.merchant.id }).then((response) => {
            if (response.success) {
                this.setState({ holidays: response.data, processing: false });
            }
        }).catch((error) => {
            this.setState({ processing: false });
            handleApiExceptions(error);
        });
    };

    fetchStaff = () => {
        const calendar_permission = this.user_permissions.APPOINTMENT_CALENDAR;
        UserService.getStaffList({
            merchant: auth.user.merchant.id,
            id: (calendar_permission && calendar_permission === "SELF") ?
                auth.user.id : undefined
        }).then((response) => {
            if (response.success) {
                const staffList = response.data.results.sort((a, b) => a.first_name.localeCompare(b.first_name));
                this.setState({ staffList }, () => {
                    // this.setState({ activeStaff: this.state.staffList[0] || {} }, () => {
                    this.fetchAppointments(this.getCalendarDate(), this.getCalendarView());
                    //     this.fetchStaffWorkingHours();
                    // });
                    console.log('stafflist', this.state.staffList);
                });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchServices = () => {
        const calendar_permission = this.user_permissions.APPOINTMENT_CALENDAR;
        BusinessService.list({
            merchant: auth.user.merchant.id,
            users__id: (calendar_permission && calendar_permission === "SELF") ? auth.user.id : undefined
        }).then((response) => {
            if (response.success) {
                const services = response.data.sort((a, b) => a.name.localeCompare(b.name));
                this.setState({ services });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchCustomers = () => {
        UserService.getCustomerList({ merchant: auth.user.merchant.id }).then((response) => {
            if (response.success) {
                this.setState({ customers: response.data.results });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchStaffWorkingHours = () => {
        if (!this.state.activeStaff.id) { return; }
        WorkingHourService.list({ staff: this.state.activeStaff.id }).then((response) => {
            if (response.success) {
                this.setState({ staffWorkingHours: response.data }, () => this.fetchStaffBreakHours());
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchStaffBreakHours = () => {
        if (!this.state.activeStaff.id) { return; }
        BreakHourService.list({ staff: this.state.activeStaff.id }).then((response) => {
            if (response.success) {
                this.setState({ staffBreakHours: response.data }, () => this.makeBusinessHours());
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchAppointments = (date, calendarView) => {
        console.log('fetchAppointments date', date);
        // if (!this.state.activeStaff.id) { return; }
        let queryParams = {
            session_requests__staff: this.state.activeStaff.id ? this.state.activeStaff.id : null,
            session_requests__service: this.state.activeService.id ? this.state.activeService.id : null,
            customer: this.state.activeCustomer.id ? this.state.activeCustomer.id : null,
            page_size: 50,
        };
        if (this.calendar) {
            const dtformat = "YYYY-MM-DD HH:mm";
            let queryDate = date ? moment(date) : moment();
            let dStart = null;
            let dEnd = null;
            const sow = this.state.settings.week_start_from || 1;
            switch (calendarView) {
                case 'timeGridWeek':
                    dStart = moment(this.calendar.view.activeStart).startOf('day');
                    dEnd = moment(this.calendar.view.activeEnd).startOf('day');
                    break;
                case 'timeGridDay':
                    dStart = moment(queryDate).startOf('day');
                    dEnd = moment(queryDate).endOf('day');
                    break;
                case 'dayGridMonth':
                    dStart = moment(queryDate).startOf('month').startOf('day');
                    dEnd = moment(queryDate).endOf('month').endOf('day');
                    break;
                default:
                    dStart = moment(this.calendar.view.activeStart).startOf('day');
                    dEnd = moment(this.calendar.view.activeEnd).startOf('day');
            }
            queryParams.scheduled_at__gte = dStart.format(dtformat);
            queryParams.scheduled_at__lte = dEnd.format(dtformat);
        }

        AppointmentService.list(queryParams).then((response) => {
            if (response.success) {
                // console.log('appointments response', response.data);
                this.setState({
                    appointments: this.generateDisplayEvents(response.data.results || []),
                    appointmentsOriginal: this.generateDisplayEvents(response.data.results || [])
                }, () => {
                    //     this.handleCalendarViewChange(this.calendar.view.type);
                    if (calendarView === "listDay") {
                        const events = this.makeEventsWithSummary();
                        this.setState({ appointments: events });
                    }
                });
                // { id: 1, title: 'Event Now', start: new Date("05-03-2019 15:00"), end: new Date("05-03-2019 17:30"), provider: 1, service: 1, customer: 1 }
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    generateDisplayEvents = (data) => {
        data.map((x) => {
            x.title = `${x.services_requested[0].service.name} - ${user_fullname(x.customer_obj)}`;
            x.description = user_fullname(x.customer_obj);
            x.start = moment(x.scheduled_at).format("YYYY-MM-DD HH:mm");
            if (!x.end) {
                const service_minutes = x.services_requested[0].service.service_minutes;
                x.end = moment(x.scheduled_at).add(service_minutes, "m").format("YYYY-MM-DD HH:mm");
            }
            x.canEdit = true;
            return x;
        });
        // console.log('generateDisplayEvents', data);
        return data;
    };

    makeBusinessHours = () => {
        let businessHours = this.state.staffWorkingHours.map((x) => {
            if (!x.currently_active) {
                return {
                    daysOfWeek: [x.day],
                    startTime: "00:00",
                    endTime: "00:00"
                };
            }

            const _break = this.state.staffBreakHours.find(b => b.day === x.day);
            if (_break && _break.currently_active) {
                return [
                    {
                        daysOfWeek: [x.day],
                        startTime: moment(x.day_start_time, "HH:mm").format("HH:mm"),
                        endTime: moment(_break.break_start_time, "HH:mm").format("HH:mm")
                    },
                    {
                        daysOfWeek: [x.day],
                        startTime: moment(_break.break_end_time, "HH:mm").format("HH:mm"),
                        endTime: moment(x.day_end_time, "HH:mm").format("HH:mm")
                    }
                ];
            }

            return {
                daysOfWeek: [x.day],
                startTime: moment(x.day_start_time, "HH:mm").format("HH:mm"),
                endTime: moment(x.day_end_time, "HH:mm").format("HH:mm")
            };
        });
        businessHours = _flatten(businessHours);
        this.setState({ businessHours: businessHours.length ? businessHours : undefined });
        return businessHours;
    };

    getCalendarDate = () => {
        if (this.calendar) {
            return this.calendar.getDate();
        }
    };

    getCalendarView = () => {
        if (this.calendar) {
            return this.calendar.view.type;
        }
        return 'timeGridWeek';
    };

    handleStaffChange = (value) => {
        const activeStaff = isNaN(value) ? {} : this.state.staffList.find(x => x.id === value) || {};
        this.setState({ activeStaff }, () => {
            this.fetchAppointments(this.getCalendarDate(), this.getCalendarView());
            this.fetchStaffWorkingHours();
        });
    };

    handleServiceChange = (value) => {
        const activeService = isNaN(value) ? {} : this.state.services.find(x => x.id === value) || {};
        this.setState({ activeService }, () => {
            this.fetchAppointments(this.getCalendarDate(), this.getCalendarView());
        });
    };

    handleCustomerChange = (value) => {
        this.setState({ activeCustomer: this.state.customers.find(x => x.id === value) || {} }, () => {
            this.fetchAppointments(this.getCalendarDate(), this.getCalendarView());
        });
    };

    renderStaffSelector = () => {
        if (this.user_permissions.APPOINTMENT_CALENDAR && this.user_permissions.APPOINTMENT_CALENDAR === "SELF") {
            return <></>;
        }
        return (
            <div className={`d-flex align-items-center ${isMobile ? 'w-100' : ''}`} key={1}>
                {/* <Typography.Text strong>Select Staff:</Typography.Text> */}
                <Select
                    style={{ width: isMobile ? "100%" : 250 }}
                    onChange={this.handleStaffChange}
                    defaultValue={this.state.activeStaff.id}
                    value={this.state.activeStaff.id ? this.state.activeStaff.id : {}}
                    className="mr-2"
                    notFoundContent="No staff added."
                    placeholder="Select Staff"
                // allowClear
                >
                    <Select.Option key={'all'} value={{}}>All Staff</Select.Option>
                    {
                        this.state.staffList.map((user, index) => (
                            <Select.Option key={index} value={user.id}>{user_fullname(user)}</Select.Option>
                        ))
                    }
                </Select>
            </div>
        );
    };

    renderServiceSelector = () => {
        return (
            <div className={`d-flex align-items-center ${isMobile ? 'w-100' : ''}`} key={2}>
                {/* <Typography.Text strong>Select Staff:</Typography.Text> */}
                <Select
                    style={{ width: isMobile ? "100%" : 250 }}
                    onChange={this.handleServiceChange}
                    value={this.state.activeService.id ? this.state.activeService.id : {}}
                    className="mr-2"
                    notFoundContent="No services added."
                    placeholder="Filter by service"
                // allowClear
                >
                    <Select.Option key={'all'} value={{}}>All Services</Select.Option>
                    {
                        this.state.services.map((service, index) => (
                            <Select.Option key={index} value={service.id}>{service.name}</Select.Option>
                        ))
                    }
                </Select>
            </div>
        );
    };

    renderCustomerSelector = () => {
        if (!auth.is_staff()) { return null; }
        return (
            <div className="d-flex align-items-center" key={2}>
                {/* <Typography.Text strong>Select Staff:</Typography.Text> */}
                <Select
                    style={{ width: 250 }}
                    onChange={this.handleCustomerChange}
                    value={this.state.activeCustomer.id}
                    className="mr-2"
                    placeholder="Filter by customer"
                    allowClear
                >
                    {
                        this.state.customers.map((customer, index) => (
                            <Select.Option key={index} value={customer.id}>{user_fullname(customer)}</Select.Option>
                        ))
                    }
                </Select>
            </div>
        );
    };

    handleEventClick = (info) => {
        if (!this.user_permissions["APPOINTMENT_CALENDAR:EDIT"] || !info.event.extendedProps.canEdit) { return; }
        if ((info.event.extendedProps || {}).disabled) { return; }
        if (!info.event.id) { this.handlelDateSelect({ start: info.event.start, end: info.event.start, is_edit: false }); return; }
        const appointment = { id: info.event.id, start: info.event.start, end: info.event.start, ...info.event.extendedProps, is_edit: true };
        this.setState({ activeAppointment: appointment }, () => { this.toggleAppointmentModal(); });
    };

    handlelDateSelect = (info) => {
        if (!this.user_permissions["APPOINTMENT_CALENDAR:ADD"]) { return; }
        const appointment = { start: appointment_date_format(info.start), end: appointment_date_format(info.end), is_edit: false };
        this.setState({ activeAppointment: appointment }, () => { this.toggleAppointmentModal(); });
    };

    toggleAppointmentModal = () => {
        this.setState({ appointmentModal: !this.state.appointmentModal });
    };

    makeEventsWithSummary = () => {
        const today = this.calendar.getDate();
        const holidayDates = this.state.holidays.map(h => h.holiday_date);
        const dateToday = moment(today).format("YYYY-MM-DD");
        if (holidayDates.includes(dateToday)) {
            return [];
        }
        const dayIndex = moment(today).day();
        const todayWorkHours = this.state.staffWorkingHours.find(x => x.day == dayIndex);
        const todayBreakHours = this.state.staffBreakHours.find(x => x.day == dayIndex);
        const appointments = this.state.appointmentsOriginal.filter(x => moment(x.scheduled_at).isSame(today, 'date'));
        const summaryData = [];
        const usedAppointments = [];
        console.log('todayWorkHours', todayWorkHours);
        if (todayWorkHours) {
            let _start = moment(today).set('h', todayWorkHours.day_start_time.split(":")[0])
                .set('m', todayWorkHours.day_start_time.split(":")[1])
                .format("YYYY-MM-DD HH:mm");
            if (appointments.length) {
                appointments.forEach((appointment) => {
                    let _end = moment(appointment.start).format("YYYY-MM-DD HH:mm");
                    if (_start && _end && !moment(_start).isSame(_end)) {
                        const free_slot = {
                            start: _start,
                            end: _end,
                            title: "Free Slot",
                            color: "green"
                        };
                        summaryData.push(free_slot);
                    }
                    _start = moment(appointment.end).format("YYYY-MM-DD HH:mm");
                });
                const lastAppointment = appointments[appointments.length - 1];
                if (lastAppointment) {
                    const todayBusinessHoursEnd = moment(today).set('h', todayWorkHours.day_end_time.split(":")[0])
                        .set('m', todayWorkHours.day_end_time.split(":")[1]);
                    if (moment(lastAppointment.end).isBefore(todayBusinessHoursEnd, 'minutes')) {
                        const free_slot = {
                            start: lastAppointment.end,
                            end: todayBusinessHoursEnd.format("YYYY-MM-DD HH:mm"),
                            title: "Free Slot",
                            color: "green"
                        };
                        summaryData.push(free_slot);
                    }
                }
            } else {
                let _end = moment(today).set('h', todayWorkHours.day_end_time.split(":")[0])
                    .set('m', todayWorkHours.day_end_time.split(":")[1])
                    .format("YYYY-MM-DD HH:mm");
                const free_slot = { start: _start, end: _end, title: "Free Slot", color: "green" };
                summaryData.push(free_slot);
            }

            if (todayBreakHours) {
                const break_hour_start = moment(today).set('h', todayBreakHours.break_start_time.split(":")[0])
                    .set('m', todayBreakHours.break_start_time.split(":")[1])
                    .format("YYYY-MM-DD HH:mm");
                const break_hour_end = moment(today).set('h', todayBreakHours.break_end_time.split(":")[0])
                    .set('m', todayBreakHours.break_end_time.split(":")[1])
                    .format("YYYY-MM-DD HH:mm");
                const invalid_slot_index = summaryData.findIndex(x => (
                    moment(x.start).isSameOrBefore(moment(break_hour_start)) &&
                    moment(x.end).isSameOrAfter(moment(break_hour_end))
                ));
                if (invalid_slot_index >= 0) {
                    const invalid_slot = summaryData[invalid_slot_index];
                    summaryData.splice(invalid_slot_index, 1);
                    summaryData.push({ start: break_hour_start, end: break_hour_end, title: "Break Hours", disabled: true, color: "red" });
                    summaryData.push({ start: invalid_slot.start, end: break_hour_start, title: "Free Slot", color: "green" });
                    summaryData.push({ start: break_hour_end, end: invalid_slot.end, title: "Free Slot", color: "green" });
                }
            }
            return [summaryData, appointments].flat();
        }
        return [];
    };

    handleCalendarViewChange = (view) => {
        setTimeout(() => this.fetchAppointments(this.getCalendarDate(), view), 500);
    };

    handleCalendarDateChange = (date) => {
        this.fetchAppointments(date, this.calendar.view.type);
    };

    render() {
        return (
            <div id="am-calendar-view-wrapper">
                <CalendarView
                    onReady={(calendar) => { this.calendar = calendar; }}
                    onEventClick={this.handleEventClick}
                    onDateSelect={this.handlelDateSelect}
                    onDateChange={this.handleCalendarDateChange}
                    calendarEvents={this.state.appointments}
                    customButtons={[this.renderStaffSelector(), this.renderServiceSelector(), this.renderCustomerSelector()]}
                    businessHours={this.state.businessHours}
                    firstDay={this.state.settings.week_start_from || 1}
                    defaultView={this.state.settings.calendar_default_view || "timeGridWeek"}
                    onViewChange={(view) => this.handleCalendarViewChange(view)}
                    minTime={(this.state.settings.business_hours || {}).start || "00:00"}
                    maxTime={(this.state.settings.business_hours || {}).end || "23:59"}
                    holidays={this.state.holidays}
                />
                <EaseModal
                    title="Appointment"
                    hideFooter
                    visible={this.state.appointmentModal}
                    onCancel={this.toggleAppointmentModal}
                >
                    <SessionForm
                        sessionType={'appointment'}
                        session={this.state.activeAppointment}
                        afterSubmit={() => {
                            this.fetchAppointments(this.getCalendarDate(), this.getCalendarView());
                            this.toggleAppointmentModal();
                        }} />
                </EaseModal>
            </div>
        );
    }
}

export default PermissionHoc(AdminCalendar, ["APPOINTMENT_CALENDAR"]);