import React, { Component } from 'react';
import { Layout, Menu, message } from 'antd';
import { isMobile } from 'react-device-detect';
import { Navbar, Sidebar, SidebarMenuItem } from '../../components/Common';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faUserClock } from '@fortawesome/free-solid-svg-icons/faUserClock';
import { auth } from '../../services';
const { Content } = Layout;

class AdminLayout extends Component {
  state = {
    sidebarCollapsed: isMobile ? true : false,
    activeSidebar: []
  };

  componentDidMount() {
    if (!auth.is_loggedin()) {
      message.warning("Seems like you are not logged in. Login again.");
      this.props.redirect('/users/login');
    }
  }

  toggleSidebar = () => {
    if (isMobile)
      this.setState({ sidebarCollapsed: !this.state.sidebarCollapsed });
  };

  onChildMount = (pathkey) => {
    this.setState({ activeSidebar: [pathkey || this.props.children.props.pathkey] });
  };

  render() {
    return (
      <div className="admin-layout-container h-100">
        <div className="admin-layout-body h-100">
          <Layout>
            <Sidebar sidebarCollapsed={this.state.sidebarCollapsed} >
              <div style={{ padding: 10, height: 60 }} className="sidebar-top">
                <div className="m-auto">
                  <img style={{ width: "100%" }} alt="" src="" />
                </div>
              </div>
              <Menu theme="light" mode="inline" selectedKeys={this.state.activeSidebar}>
                <SidebarMenuItem
                  permissions={["APPOINTMENT_CALENDAR"]}
                  key="admin_calendar"
                  onClick={this.props.redirect.bind(this, '/admin/calendar', this.toggleSidebar)}
                  label="Calendar"
                  icon={faCalendarAlt} />
                <SidebarMenuItem
                  permissions={["DAILY_QUEUE"]}
                  key="admin_appointment_queue"
                  onClick={this.props.redirect.bind(this, '/admin/appointments/queue', this.toggleSidebar)}
                  label="Appointment Queue"
                  icon={faUserClock} />
                <SidebarMenuItem
                  permissions={["CUSTOMER_MASTER"]}
                  key="admin_customers"
                  onClick={this.props.redirect.bind(this, '/admin/customer', this.toggleSidebar)}
                  label="Customers"
                  icon={faUserFriends} />
                <SidebarMenuItem
                  permissions={["DAILY_QUEUE"]}
                  key="admin_sessions"
                  onClick={this.props.redirect.bind(this, '/admin/sessions', this.toggleSidebar)}
                  label="History"
                  icon={faHistory} />
                <SidebarMenuItem
                  permissions={["SETTINGS"]}
                  key="admin_settings"
                  onClick={this.props.redirect.bind(this, '/admin/settings', this.toggleSidebar)}
                  label="Settings"
                  icon={faCog} />

              </Menu>
            </Sidebar>
            <Layout className="main-layout-content-wrapper"
              style={this.state.sidebarCollapsed ? styles.layoutInnerCollapsed : styles.layoutInner}>
              <Navbar
                showUser={true}
                showToggleIcon={false}
                toggleSidebar={this.toggleSidebar.bind(this)}
                sidebarCollapsed={this.state.sidebarCollapsed}
                redirect={this.props.redirect.bind(this)} />
              <Content style={styles.content}>
                {auth.is_loggedin() && React.cloneElement(this.props.children, {
                  onChildMount: this.onChildMount.bind(this),
                  changeTheme: this.props.changeTheme
                })}
              </Content>
            </Layout>
          </Layout>
        </div>
      </div>
    );
  }
}

const styles = {
  layoutInnerCollapsed: {
    marginLeft: 0,
    transition: 'all .2s',
    WebkitTransition: 'all .2s'
  },
  layoutInner: {
    marginLeft: isMobile ? 0 : 56,
    transition: 'all .2s',
    WebkitTransition: 'all .2s'
  },
  content: {
    background: '#fff',
    padding: '0px',
    overflow: 'initial',
    marginTop: 60
  }
};

export default AdminLayout;
