import React, { Component } from 'react';
import { DatePicker, Button, Table, Typography, Select, Tag } from 'antd';
import moment from 'moment/moment';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import StaffList from '../Settings/Staff/StaffList';
import { UserService, auth, AppointmentService } from '../../../services';
import './index.scss';
import { daterange_for_filter, humanizeDate, user_fullname, handleApiExceptions } from '../../../app_methods';
import { isMobile } from 'react-device-detect';
import { EaseModal } from '../../../libs/EaseModal';
import { SessionForm } from '../../Common';
import { DATE_RANGES } from '../../../constants';

class Appointments extends Component {
    columns = [{
        title: 'Token #',
        key: "index",
        render: (record) => record.booking_id
    }, {
        title: 'Schedule',
        key: "schedule",
        render: (record) => <div>{humanizeDate(record.scheduled_at)}</div>
    }, {
        title: 'Service',
        key: 'day',
        render: (record) => (
            <div className="d-flex flex-column">
                <div>{record.services_requested[0].service.name}</div>
                <Typography.Text type="secondary">{record.services_requested[0].service.description || "---"}</Typography.Text>
            </div>
        )
    }, {
        title: 'Provider',
        key: "provider",
        render: (record) => (
            <div className="d-flex flex-column">
                <div>{user_fullname(record.staff_obj)}</div>
                <Typography.Text type="secondary">{record.staff_obj.email || "---"}</Typography.Text>
            </div>
        )
    }, {
        title: 'Customer',
        key: "customer",
        render: (record) => (
            <div className="d-flex flex-column">
                <div>{user_fullname(record.customer_obj)}</div>
                {/* <Typography.Text type="secondary">{record.customer_obj.email || "---"}</Typography.Text> */}
                <Typography.Text type="secondary">{record.customer_obj.phone || "---"}</Typography.Text>
            </div>
        )
    }, {
        title: 'Amount',
        key: "amount",
        render: (record) => <Typography.Text>Rs. {record.amount}/-</Typography.Text>
    }, {
        title: 'Status Name',
        key: "status_name",
        render: (record) => <Typography.Text>{record.status_name || "---"}</Typography.Text>
    }, {
        title: 'Payment',
        key: "payment",
        render: (record) => <Tag color={record.is_paid ? "green" : "red"} style={{ width: 80 }} className="text-center">{record.is_paid ? "Paid" : "Not Paid"}</Tag>
    }, {
        title: 'Action',
        key: "action",
        render: (record) => <Button disabled={record.is_paid} onClick={() => this.toggleAppointmentModal(record)}>Modify</Button>
    }];

    state = {
        fetchingData: false,
        staffList: [],
        activeStaff: {},
        appointments: [],
        appointmentModal: false,
        activeAppointment: {},
        filters: {
            staff: undefined,
            scheduled_at__gte: daterange_for_filter(moment(), moment())[0],
            scheduled_at__lte: daterange_for_filter(moment(), moment())[1]
        }
    };

    componentDidMount() {
        this.fetchStaffList();
    }

    fetchStaffList = () => {
        UserService.getStaffList({ merchant: auth.user.merchant.id }).then((response) => {
            if (response.success) {
                this.setState({ staffList: response.data.results }, () => {
                    this.fetchAppointments();
                });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchAppointments = () => {
        this.setState({ fetchingData: true });
        AppointmentService.list(this.state.filters).then((response) => {
            if (response.success) {
                this.setState({
                    appointments: _orderBy(response.data.results, 'scheduled_at')
                });
            }
            this.setState({ fetchingData: false });
        }).catch((error) => {
            this.setState({ fetchingData: false });
            handleApiExceptions(error);
        });
    };

    selectStaff = (staff) => {
        const filters = this.state.filters;
        filters.staff = staff.id;
        this.setState({ activeStaff: staff, filters }, () => this.fetchAppointments());
    };

    setDateRange = (range) => {
        const date_range = daterange_for_filter(range[0], range[1]);
        const filters = this.state.filters;
        filters.scheduled_at__gte = date_range[0];
        filters.scheduled_at__lte = date_range[1];
        this.setState({ filters: filters });
    };

    setStatus = (id) => {
        const filters = this.state.filters;
        filters.status__in = (id || []).join(",");
        if (!id || !id.length) { filters.status__in = undefined; }
        this.setState({ filters: filters });
    };

    toggleAppointmentModal = (appointment) => {
        this.setState({
            appointmentModal: !this.state.appointmentModal,
            activeAppointment: { ...appointment, is_edit: true }
        });
    };

    render() {
        return (
            <div id="appointments-list-wrapper" className="d-flex w-100">
                {/* <div className="staff-list p-3">
                    <StaffList showAllVisible staffList={this.state.staffList} onStaffSelect={this.selectStaff} activeStaff={this.state.activeStaff} allowAdd={false} />
                </div> */}
                <div className="staff-appointments p-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <DatePicker.RangePicker
                                defaultValue={[moment(), moment()]}
                                ranges={DATE_RANGES}
                                // value={[moment(this.state.filters.scheduled_at__gte), moment(this.state.filters.scheduled_at__lte)]}
                                onChange={this.setDateRange}
                                allowClear={false}
                            />
                            <Select mode="multiple"
                                size="default"
                                placeholder="Select Status"
                                style={{ minWidth: 150, maxWidth: 350 }}
                                className="ml-2" onChange={this.setStatus} allowClear>
                                {
                                    (auth.user.merchant.appointment_status || []).map((status, index) => (
                                        <Select.Option key={index} value={status.id}>{status.key_label}</Select.Option>
                                    ))
                                }
                            </Select>
                        </div>
                        <Button icon="search" onClick={this.fetchAppointments}>Apply Filter</Button>
                    </div>
                    <div className="appointment-list mt-2">
                        <Table
                            loading={this.state.fetchingData}
                            columns={this.columns}
                            dataSource={this.state.appointments}
                            scroll={{ x: isMobile ? true : false }} />
                    </div>
                </div>
                <EaseModal
                    title="Appointment"
                    hideFooter
                    visible={this.state.appointmentModal}
                    onCancel={() => this.toggleAppointmentModal({}, false)}
                >
                    <SessionForm
                        sessionType={'appointment'}
                        disableCustomer
                        defaultCustomer={this.state.activeAppointment.contact_obj}
                        session={this.state.activeAppointment}
                        afterSubmit={() => { this.fetchAppointments(); this.toggleAppointmentModal({}); }}
                    />
                </EaseModal>
            </div>
        );
    }
}

export default Appointments;