import React from 'react';
import { isMobile } from 'react-device-detect';
import { Select, Popover, Typography, Button } from 'antd';

const CustomerListMobile = (props) => {

    const handleCustomerChange = (id) => {
        const active_customer = props.customers.find(x => x.id === id);
        props.selectCustomer(active_customer);
    };

    return (
        <div className={`w-100 mb-2 staff-list-select-box ${isMobile ? 'd-flex' : 'd-none'}`}>
            <Select placeholder="Select Customer" className="w-100" value={props.activeCustomer.id} onChange={handleCustomerChange} >
                {
                    props.customers.map((customer, index) => {
                        return (
                            <Select.Option key={index} value={customer.id}>{customer.first_name} {customer.last_name}</Select.Option>
                        );
                    })
                }
            </Select>
            {
                isMobile ? (
                    <Popover placement="bottom"
                        visible={props.customerPopoverVisible}
                        title={
                            <div className="d-flex align-items-center justify-content-between">
                                <Typography.Text strong>Add Customer</Typography.Text>
                                <span className="c-pointer" onClick={props.customerPopoverToggle}>x</span>
                            </div>
                        }
                        content={props.renderAddCustomer()}>
                        <Button className="ml-2" icon="plus" onClick={props.customerPopoverToggle} />
                    </Popover>
                ) : null
            }
        </div>
    );
};

export default CustomerListMobile;