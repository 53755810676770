import React from 'react';
import moment from 'moment/moment';
import { Typography, Button, DatePicker, Icon } from 'antd';
import { TimePicker } from '../../index';
import { auth } from '../../../../services';
import { get_unavailable_hours, get_unavailable_dates, findHoursForSelectedDay } from '../../../../app_methods';
import { useWindowDimensions, WindowSizes } from '../../../../utils/windowDimesions';

const Scheduler = (props) => {
    const { winsize } = useWindowDimensions();
    const inactive_days = (props.workHours.filter(x => !x.currently_active) || []).map(x => x.display_day.toLowerCase());
    const settings = auth.settings() || {};
    const disabled_hours =
        get_unavailable_hours(props.selectedDate, props.workHours,
            props.breakHours, props.staffAppointmentForSelectedDate, props.selectedService);
    const disabled_dates = get_unavailable_dates(props.holidays);

    const getDefaultDate = () => {
        if (props.selectedDate) {
            return moment(props.selectedDate);
        };
        return moment();
    };
    const datetimeDivStyles = winsize < WindowSizes.MD ? styles.mobileDateTimeDiv : styles.desktopDateTimeDiv;
    const pickersDivStyles = winsize < WindowSizes.MD ? styles.mobilePickersDiv : styles.pickersDiv;
    // console.log("ST: ", props.selectedTime);
    // console.log("disabled_hours: ", disabled_hours);
    return (
        <div className="p-relative h-100">
            <div className="booking-service-scheduler">
            <Typography.Text className="section-title-text">Pick Date &amp; Time</Typography.Text>
            </div>

            <div  style={datetimeDivStyles}>
                <div style={styles.pickersDiv}>
                    <DatePicker
                        style={{ width: 300 }}
                        defaultValue={getDefaultDate()}
                        onChange={props.selectDate}
                        disabledDate={(date) => { return getDisabledDate(date, inactive_days, props.workHours) || (disabled_dates || []).includes(date.format("YYYY-MM-DD")); }}
                    />
                    <TimePicker
                        className="ml-2"
                        style={{ width: 100 }}
                        disabled={!props.selectedDate}
                        minTime={disabled_hours.minTime || (settings.business_hours || {}).start}
                        maxTime={disabled_hours.maxTime || (settings.business_hours || {}).end} get_unavailable_dates
                        disabledRanges={disabled_hours.unavailables}
                        defaultValue={props.selectedTime ? moment(props.selectedTime, "HH:mm") : undefined}
                        value={props.selectedTime ? moment(props.selectedTime, "HH:mm") : undefined}
                        onChange={props.selectTime}
                    />
                </div>
                {
                    (props.selectedDate && props.selectedTime) ? (
                        <div className="mt-4">
                            <Typography.Text>
                                You have selected <Typography.Text strong>{moment(`${props.selectedDate} ${props.selectedTime}`).format("LLLL")}</Typography.Text>
                            </Typography.Text>
                        </div>
                    ) : null
                }
        
            </div>
            
            {!props.editView &&
                <>
                <div className="mt-4 float-left">
                    <Button
                        type="secondary"
                        onClick={() => props.goBack()}><Icon type="left" type="arrow-left" />Previous</Button>
                </div>
                <div className="mt-4 float-right">
                    <Button type="primary" onClick={props.selectSchedule}>Proceed<Icon type="right" type="arrow-right" /></Button>
                </div>
                </>
            }
            {props.editView &&
                <div className="mt-4 float-right">
                    <Button
                        type="primary"
                        onClick={() => {
                            props.goBackToConfirm(2, null)}
                        }><Icon type="left" type="rollback" /> Go Back</Button>
                </div>
            }
            <div className="clearfix"></div>
        </div>
    );
};

const getDisabledDate = (date, inactive_days, workHours) => {
    const calender_day = moment(date).format("dddd").toLowerCase();
    const dateFormat = 'YYYY-MM-DD';
    const dateToday = moment().format(dateFormat);
    const currentDate = moment(date).format(dateFormat);
    // console.log('inactive_days', inactive_days);
    let shouldDisableDate = false;
    const fullTimeFormat = "HH:mm:ss";
    const selected_day = moment().format("dddd").toLowerCase();
    const work_hours_for_selected_day = workHours.find(x => findHoursForSelectedDay(x, selected_day));
    if (work_hours_for_selected_day) {
        if (moment(work_hours_for_selected_day.day_end_time, fullTimeFormat).isBefore(moment()) && currentDate === dateToday) {
            shouldDisableDate = true;
        }
    }
    // return inactive_days.includes(calender_day) || moment(date).isBefore(moment(),'date');
    // return inactive_days.includes(calender_day) || moment(currentDate).isBefore(dateToday);
    return inactive_days.includes(calender_day) || moment(currentDate).isBefore(dateToday) || shouldDisableDate;
};

const styles = {
    pickersDiv: {
        display: 'flex'
    },
    mobilePickersDiv: {
        display: 'flex',
        flexDirection: 'column',

    },
    mobileDateTimeDiv: {
        height: "86%",
        minHeight: "86%",
        overflow: "auto",
        paddingTop: "1.5rem"
    },
    desktopDateTimeDiv: {
        height: "66%",
        minHeight: "66%",
        overflow: "auto",
        marginTop: "1.5rem"
    }
}

export default Scheduler;