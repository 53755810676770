import React, { Component } from 'react';
import { Form, Input, Avatar, Button } from 'antd';
import { UserService } from '../../../../services';
import { handleApiExceptions, alertify, resolve_server_image } from '../../../../app_methods';

class BasicDetails extends Component {
    state = {
        processing: false,
        avatar: {}
    }

    componentDidMount() {
        this.props.form.setFieldsValue({ staff: this.props.staffDetails });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.staffDetails.id !== nextProps.staffDetails.id)
            nextProps.form.setFieldsValue({ staff: nextProps.staffDetails });
        const avatar = this.state.avatar;
        avatar.avatar = this.props.staffDetails.image ? resolve_server_image(this.props.staffDetails.image) : undefined;
        this.setState({ avatar });
    }

    updateAvatar(id) {
        if (!this.state.avatar.avatarFile) { return; }
        let formData = new FormData();
        formData.set('image', this.state.avatar.avatarFile);
        UserService.update_avatar(
            { formdata: formData, id },
            { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then((response) => {
            if (response.success) {
                alertify("Awesome!!!", `User avatar updated successfully`, "success");
            } else {
                alertify("Oops!!!", response.message, "error");
                this.setState({ processing: false });
            }
        }).catch((error) => {
            debugger;
        })
    }

    updateUser(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) { return; }
            this.setState({ processing: true });
            UserService.update({ ...values.staff, id: this.props.staffDetails.id }).then((response) => {
                if (response.success) {
                    alertify("Awesome!!!", "Staff updated successfully", "success");
                    this.updateAvatar(response.data.id)
                    this.setState({ processing: false });
                    this.props.afterSubmit();
                }
                else {
                    alertify("Oops!!!", response.message, "error");
                    this.setState({ processing: false });
                }
            }).catch((err) => {
                handleApiExceptions(err);
                this.setState({ processing: false });
            });
        });
    }

    handleAvatarChange(e) {
        const avatar = this.state.avatar;
        avatar.avatarFile = e.target.files[0];
        avatar.avatar = URL.createObjectURL(e.target.files[0]);
        this.setState({ avatar });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="mx-auto mt-4 basic-details-form-wrapper">
                <Form layout="vertical" onSubmit={this.updateUser.bind(this)}>
                    <div className="service-icon p-relative mx-auto c-pointer mb-5" style={{ width: 120, height: 120, overflow: "hidden", borderRadius: "50%" }}>
                        <img onClick={() => { this.refs.avatarUploader.click(); }} src={this.state.avatar.avatar || require('../../../../assets/images/default.png')} className="w-100" />
                        <input type="file" id="avatar" ref="avatarUploader" onChange={this.handleAvatarChange.bind(this)} style={{ display: "none" }} />
                    </div>
                    <div >
                        <Form.Item label="First Name" className="d-inline-block" style={{ width: "49%" }} >
                            {getFieldDecorator('staff.first_name', {
                                rules: [
                                    { required: true, message: "First Name is mandatory." }
                                ]
                            })(
                                <Input disabled={this.props.processing} placeholder="Staff first name" />
                            )}
                        </Form.Item>
                        <Form.Item label="Last Name" className="d-inline-block ml-2" style={{ width: "49%" }}>
                            {getFieldDecorator('staff.last_name', {
                                rules: [
                                    { required: true, message: "Last Name is mandatory." }
                                ]
                            })(
                                <Input disabled={this.props.processing} placeholder="Staff last name" />
                            )}
                        </Form.Item>
                    </div>
                    <Form.Item label="Email">
                        {getFieldDecorator('staff.email', {
                            rules: [
                                { required: true, message: 'Please input email.' },
                                {
                                    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                                    message: "Email is not a valid format."
                                }
                            ]
                        })(
                            <Input disabled={this.props.processing} placeholder="Email Address" />
                        )}
                    </Form.Item>
                    <Form.Item label="Mobile" >
                        {getFieldDecorator('staff.phone', {
                            rules: [
                                { required: false, message: 'Please input mobile number.' },
                                { pattern: /^[0-9]\w{9}\b$/, message: "Please input valid mobile number." }
                            ]
                        })(
                            <Input disabled={this.props.processing} placeholder="Staff mobile number" />
                        )}
                    </Form.Item>
                    <Form.Item className="float-right">
                        <Button loading={this.props.processing} htmlType="submit" type="primary">Submit</Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

BasicDetails.defaultProps = {
    afterSubmit: () => { }
}


const FormWrapper = Form.create()(BasicDetails);
export default FormWrapper;
