import React from 'react';
import { Switch } from 'react-router-dom';
import { BlankLayout, OpenBookingLayout, CustomerLayout } from '../../layouts';
import { Booking, CustomerBooking, CustomerSettings, Dashboard, Profile, SessionList } from './index';
import { CustomerLogin } from '../User';

const CustomerRouter = (props) => {
    const AppRoute = props.AppRoute;
    return (
        <Switch>
            <AppRoute exact path="/booking/:merchant_uid" layout={OpenBookingLayout} component={Booking} pathkey="open_booking" />
            <AppRoute exact path="/customer/login/:merchant_uid" layout={BlankLayout} component={CustomerLogin} pathkey="customer_login" />
            <AppRoute exact path="/customer/profile/" layout={CustomerLayout} component={Profile} pathkey="customer_dashboard" />
            <AppRoute exact path="/customer/sessions/" layout={CustomerLayout} component={SessionList} pathkey="customer_sessions" />
            <AppRoute exact path="/customer/booking/" layout={CustomerLayout} component={CustomerBooking} pathkey="customer_booking" />
        </Switch>
    );
};

export default CustomerRouter;
