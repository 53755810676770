import React, { useState } from 'react';
import moment from 'moment/moment';
import { Typography, Table, Avatar, Button, DatePicker, TimePicker, Icon, Form, Input, Select, Divider } from 'antd';

const CustomerInfo = (props) => {
    const { getFieldDecorator } = props.form;
    const [otp_input, setOtpInput] = useState("");
    const [existingCustomerPhone, setExistingCustomerPhone] = useState("8754602437");
    return (
        <>
            <div className="booking-service-customer">
                <Typography.Title level={4}>Customer Information</Typography.Title>
            </div>
            <div className="mx-auto mt-4 p-relative customer-form-wrapper" style={{ width: "50%" }}>
                <div>
                    <Typography.Paragraph strong>Existing Customer</Typography.Paragraph>
                    <Form layout="vertical">
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <Form.Item label="Country Code" >
                                    {getFieldDecorator('existingCustomer.phone_code', {
                                        initialValue: props.existingCustomer.phone_code,
                                    })(
                                        <Select
                                            disabled={props.processing}
                                            style={{ width: 150 }}>
                                            {
                                                props.countryCodes &&
                                                props.countryCodes.length > 0 &&
                                                props.countryCodes.map(p => (
                                                    <Select.Option key={p.id} value={p.phonecode}>{`${p.iso} (+${p.phonecode})`}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Phone" className="w-100">
                                    <Input disabled={props.processing} placeholder="Phone"
                                        value={existingCustomerPhone}
                                        onChange={(e) => setExistingCustomerPhone(e.target.value)} />
                                </Form.Item>
                            </div>
                            <Form.Item className="customer-btn" style={{ alignSelf: 'flex-end', marginLeft: 16 }}>
                                <Button loading={props.processing} type="primary"
                                    disabled={props.otpSent}
                                    onClick={() => props.sendOtp(existingCustomerPhone)}>Send OTP</Button>
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <Form.Item label="Enter Otp">
                                <Input disabled={props.processing} placeholder="OTP"
                                    value={otp_input}
                                    onChange={(e) => setOtpInput(e.target.value)} />
                            </Form.Item>
                            <Form.Item className="customer-btn" style={{ alignSelf: 'flex-end', marginLeft: 16 }}>
                                <Button loading={props.processing} type="primary"
                                    disabled={props.otpVerified}
                                    onClick={() => props.verifyOtp(existingCustomerPhone, otp_input, 'login')}>Verify OTP</Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                {
                    props.otpVerified &&
                    !props.isExistingCustomer &&
                    <div style={{ fontWeight: 600, padding: 16 }}>Customer does not exist!</div>
                }
                <Divider style={{ marginBottom: 16, marginTop: 8 }} />
                <Typography.Paragraph strong>New Customer</Typography.Paragraph>
                <Form layout="vertical" onSubmit={props.setCustomer.bind(this, props.form)}>
                    {/* {getFieldDecorator('customer.merchant', { initialValue: auth.user.merchant.id })(
                        <Input hidden />
                    )}
                    <Form.Item className="text-center">
                        {getFieldDecorator('customer.avatar', {})(
                            <Avatar style={{ width: 120, height: 120 }} />
                        )}
                    </Form.Item> */}
                    <div className="d-flex">
                        <Form.Item label="First Name" className="w-50">
                            {getFieldDecorator('customer.first_name', {
                                initialValue: props.customer.first_name,
                                rules: [{
                                    required: true, message: 'Please input first name.',
                                }]
                            })(
                                <Input prefix={<Icon type="user" />} disabled={props.processing} placeholder="First name" />
                            )}
                        </Form.Item>
                        <Form.Item label="Last Name" className="ml-1 w-50">
                            {getFieldDecorator('customer.last_name', {
                                initialValue: props.customer.last_name,
                                rules: [{
                                    required: true, message: 'Please input last name.',
                                }]
                            })(
                                <Input prefix={<Icon type="user" />} disabled={props.processing} placeholder="Last name" />
                            )}
                        </Form.Item>
                    </div>
                    <Form.Item label="Email" >
                        {getFieldDecorator('customer.email', {
                            initialValue: props.customer.email,
                            rules: [
                                { required: true, message: 'Please input email.' },
                                {
                                    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                                    message: "Email is not a valid format."
                                }
                            ]
                        })(
                            <Input prefix={<Icon type="mail" />} disabled={props.processing} placeholder="Email Address" />
                        )}
                    </Form.Item>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <Form.Item label="Country Code" >
                            {getFieldDecorator('customer.phone_code', {
                                initialValue: props.customer.phone_code,
                                rules: [
                                    { required: true, message: 'Please select country code.' },
                                ]
                            })(
                                <Select
                                    disabled={props.processing}
                                    style={{ width: 150 }}>
                                    {
                                        props.countryCodes &&
                                        props.countryCodes.length > 0 &&
                                        props.countryCodes.map(p => (
                                            <Select.Option key={p.id} value={p.phonecode}>{`${p.iso} (+${p.phonecode})`}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Mobile Number" >
                            {getFieldDecorator('customer.phone', {
                                initialValue: props.customer.phone,
                                rules: [
                                    { required: true, message: 'Please input mobile number.' },
                                    { pattern: /^[0-9]\w{9}\b$/, message: "Please input valid mobile number." }
                                ]
                            })(
                                <Input type="number" prefix={<Icon type="mobile" />} disabled={props.processing} placeholder="Mobile Number" />
                            )}
                        </Form.Item>
                    </div>
                    <Form.Item label="Additional Information" >
                        {getFieldDecorator('customer.note', {
                            initialValue: props.customer.note,
                        })(
                            <Input.TextArea disabled={props.processing} placeholder="Please enter additional information if any." />
                        )}
                    </Form.Item>

                    <Form.Item style={{ position: "absolute", bottom: 30, right: 30 }} className="customer-btn">
                        <Button
                            loading={props.processing}
                            htmlType="submit"
                            type="primary">Proceed<Icon type="arrow-right" /></Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

const FormWrapper = Form.create()(CustomerInfo);
export default FormWrapper;