import axios from 'axios';
import { LocalStorage } from './';
import { get_authentication_token, alertify } from '../app_methods';
import { auth } from './Authentication';
const storage = new LocalStorage();
const axiosIns = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL,
  headers: {}
});

axiosIns.interceptors.request.use(function (config) {
  config.headers['Authorization'] = get_authentication_token();
  // console.log('config', config);
  return config;
}, function (error) {
  return Promise.reject(error);
});

axiosIns.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (!error.response.config.url.includes('/auth/login') && error.response.status === 401) {
    alertify("Oops!!!", "Seems like you are not logged in. Login again.", "error");
    setTimeout(() => {
      auth.remove_user();
      window.location = '/users/login';
    }, 2000);
  }
  return Promise.reject(error);
});

export { axiosIns };
