import React, { Component } from 'react';
import './index.scss';
import { Typography, List } from 'antd';

class SettingOptions extends Component {
    state = {
        merchant: {}
    }
    
    render() {
        return (
            <div>
                <div className="list">
                    <List
                        bordered={false}
                        dataSource={this.props.options}
                        renderItem={item => (<List.Item className={`mb-1 category-item c-pointer ${item.id === this.props.activeOption.id ? 'active' : ''}`} onClick={this.props.selectOption.bind(this, item)}>
                            <div className="d-flex align-items-center justify-content-between w-100 px-2">
                                <Typography.Text className="ml-3 category-name">{item.name}</Typography.Text>
                            </div>
                        </List.Item>)}
                    />
                </div>
            </div>
        )
    }
}

SettingOptions.defaultProps = {

}

export default SettingOptions;