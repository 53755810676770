import React, { Component } from 'react';
import './index.scss';
import { Table, Button, Typography, Avatar, Input, Popconfirm } from 'antd';
import FormDrawer from './FormDrawer';
import _isEmpty from 'lodash/isEmpty';
import ServiceAssignDrawer from './ServiceAssignDrawer';
import { BusinessService, BusinessCategoryService, auth } from '../../../../services';
import { alertify, resolve_server_image } from '../../../../app_methods';
import CategoryList from './CategoryList';
import CategoryListMobile from './CategoryListMobile';
import { isMobile, BrowserView, isBrowser } from 'react-device-detect';
import { PermissionHoc } from '../../../HOC';
const PermissableButton = PermissionHoc(Button, '', { is_component: true, fallbackComponent: null });
class ServiceSettings extends Component {
    user_permissions = auth.permissions();
    columns = [{
        title: 'Service',
        key: 'name',
        // width: "50%",
        width: 500,
        fixed: "left",
        render: (record) => {
            return (
                <div className="d-flex">
                    <div>
                        <Avatar src={record.service_image ?
                            resolve_server_image(record.service_image) :
                            require('../../../../assets/images/default.png')} icon="user" />
                    </div>
                    <div className="d-flex flex-column align-items-start ml-2">
                        <Typography.Text>{record.name}</Typography.Text>
                        <Typography.Text type="secondary">{record.description}</Typography.Text>
                    </div>
                </div>
            );
        }
    }, {
        title: 'Color',
        width: 150,
        key: 'color_tag',
        render: (record) => {
            return (
                <div style={{ background: record.color_tag, borderRadius: 5, color: "#fff", width: 80, height: 32 }}></div>
            );
        }
    }, {
        title: 'Amount',
        dataIndex: 'sell_price',
        width: 150,
        key: 'sell_price',
    }, {
        title: 'Duration',
        dataIndex: 'service_minutes',
        width: 150,
        key: 'service_minutes',
    }, {
        title: 'Buffer Time',
        dataIndex: 'buffer_minutes',
        width: 150,
        key: 'buffer_minutes',
    }, {
        title: 'Action',
        key: 'action',
        // width: "20%",
        width: 250,
        fixed: "right",
        render: (record) => {
            return (
                <>
                    <PermissableButton required_permission={["SETTINGS:SERVICE:EDIT"]} onClick={this.toggleFormDrawer.bind(this, record, true)}>Edit</PermissableButton>
                    <PermissableButton required_permission={["SETTINGS:SERVICE:EDIT"]} onClick={this.toggleServiceAssignDrawer.bind(this, record)} className="ml-1">Assign</PermissableButton>
                    <Popconfirm title="Are you sure you want to delete service?" okText="Delete" className="ml-1" onConfirm={this.deleteService.bind(this, record)}>
                        <PermissableButton required_permission={["SETTINGS:SERVICE:EDIT"]} type="danger">Delete</PermissableButton>
                    </Popconfirm>
                </>
            );
        }
    }];


    toggleFormDrawer = this.toggleFormDrawer.bind(this);
    toggleServiceAssignDrawer = this.toggleServiceAssignDrawer.bind(this);
    renderAddCategory = this.renderAddCategory.bind(this);
    handleCategoryFieldChange = this.handleCategoryFieldChange.bind(this);
    selectCategory = this.selectCategory.bind(this);
    toggleCategoryForm = this.toggleCategoryForm.bind(this);
    deleteService = this.deleteService.bind(this);
    deleteCategory = this.deleteCategory.bind(this);

    state = {
        services: [],
        activeCategory: {},
        categories: [],
        category: {},
        service: {},
        formDrawer: false,
        serviceAssignDrawer: false,
        categoryFormVisible: false,
        processing: false
    };

    componentDidMount() {
        this.fetchCategories();
    }

    fetchServices() {
        if (!this.state.activeCategory.id) return;
        BusinessService.list({ category: this.state.activeCategory.id }).then((response) => {
            if (response.success) {
                this.setState({ services: response.data });
            } else {
                alertify("Oops!!!", "Something went wrong. Try again", "error");
            }
        }).catch((error) => {
            alertify("Oops!!!", "Something went wrong. Try again", "error");
        });
    }

    fetchCategories() {
        BusinessCategoryService.list({ merchant: auth.user.merchant.id }).then((response) => {
            if (response.success) {
                this.setState({ categories: response.data, activeCategory: response.data[0] || {} }, () => {
                    this.fetchServices();
                });
            } else {
                alertify("Oops!!!", "Something went wrong. Try again", "error");
            }
        }).catch((error) => {
            alertify("Oops!!!", "Something went wrong. Try again", "error");
        });
    }

    deleteService(service) {
        BusinessService.delete({ id: service.id }).then((response) => {
            if (response.success) {
                this.fetchServices();
            } else {
                alertify("Oops!!!", "Something went wrong. Try again", "error");
            }
        }).catch((error) => {
            alertify("Oops!!!", "Something went wrong. Try again", "error");
        });
    }

    deleteCategory(category) {
        BusinessCategoryService.delete({ id: category.id }).then((response) => {
            if (response.success) {
                this.fetchCategories();
            } else {
                alertify("Oops!!!", "Something went wrong. Try again", "error");
            }
        }).catch((error) => {
            alertify("Oops!!!", "Something went wrong. Try again", "error");
        });
    }

    renderAddCategory() {
        return (
            <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                    <Input name="name" disabled={this.state.processing} onChange={this.handleCategoryFieldChange} placeholder="Category Name" style={{ width: "200px" }} />
                    <Input name="description" disabled={this.state.processing} onChange={this.handleCategoryFieldChange} placeholder="Category Description" style={{ width: "200px" }} className="mt-1" />
                </div>
                <Button type="primary" loading={this.state.processing} onClick={this.addCategory.bind(this)} className="mt-2">{this.state.processing ? "Adding Category..." : "Add"}</Button>
            </div>
        );
    }

    toggleCategoryForm() {
        this.setState({ categoryFormVisible: !this.state.categoryFormVisible });
    }

    handleCategoryFieldChange(e) {
        const category = this.state.category;
        category[e.target.name] = e.target.value;
        this.setState({ category });
    }

    selectCategory(category) {
        this.setState({ activeCategory: category }, () => {
            this.fetchServices();
        });
    }

    addCategory() {
        const category = this.state.category;
        if (!category.name || category.name === "" || category.name.trim() === "") { return; };
        this.setState({ processing: true });
        BusinessCategoryService.create({
            name: category.name, description: category.description, merchant: auth.user.merchant.id
        }).then((response) => {
            if (response.success) {
                this.setState({ category: {} });
                this.toggleCategoryForm();
                alertify("Awesome", "Category succesfully created.", "success");
                this.fetchCategories();
            } else {
                alertify("Oops!!!", "Something went wrong. Try again", "error");
            }
            this.setState({ processing: false });
        }).catch((error) => {
            this.setState({ processing: false });
            alertify("Oops!!!", "Something went wrong. Try again", "error");
        });
    }

    toggleFormDrawer(record, is_edit = false) {
        const service = { ...record, is_edit: is_edit };
        this.setState({ formDrawer: !this.state.formDrawer, service });
    }

    toggleServiceAssignDrawer(record = {}) {
        const service = { ...record };
        this.setState({ serviceAssignDrawer: !this.state.serviceAssignDrawer, service });
    }

    afterServiceSave() {
        this.toggleFormDrawer();
        this.fetchServices();
    }

    render() {
        console.log('isBrowser', isBrowser);
        console.log('isMobile', isMobile);
        return (
            <div id="service-settings-wrapper" className="d-flex w-100">
                <div className={`categories-list p-3 ${isMobile ? 'd-none' : ''}`}>
                    <CategoryList categoryFormVisible={this.state.categoryFormVisible} deleteCategory={this.deleteCategory} toggleCategoryForm={this.toggleCategoryForm} activeCategory={this.state.activeCategory} selectCategory={this.selectCategory} categories={this.state.categories} addCategory={this.addCategory.bind(this)} renderAddCategory={this.renderAddCategory} />
                </div>
                <div className={`category-details p-3 ${isMobile ? 'w-100' : ''}`}>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <Typography.Title level={4}>All Services</Typography.Title>
                        <PermissableButton required_permission={["SETTINGS:SERVICE:ADD"]} icon="plus" onClick={() => { if (!this.state.activeCategory.id) { alertify("Oops!!!", "You have not selected any category.", "error"); return; } this.toggleFormDrawer({}, false); }}>Add Service</PermissableButton>
                    </div>
                    <CategoryListMobile categories={this.state.categories} activeCategory={this.state.activeCategory} selectCategory={this.selectCategory} renderAddCategory={this.renderAddCategory} />
                    <Table scroll={{ x: isMobile ? 100 : isBrowser ? 1300 : 100 }} columns={this.columns} dataSource={this.state.services} pagination={false} bordered={false} />
                </div>
                <FormDrawer activeCategory={this.state.activeCategory} toggleFormDrawer={this.toggleFormDrawer} formDrawer={this.state.formDrawer} service={this.state.service} afterSubmit={this.afterServiceSave.bind(this)} />
                <ServiceAssignDrawer toggleServiceAssignDrawer={this.toggleServiceAssignDrawer} serviceAssignDrawer={this.state.serviceAssignDrawer} service={this.state.service} />
            </div>
        );
    }
}

export default ServiceSettings;