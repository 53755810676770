import React, { Component } from 'react';
import { Layout, Menu, message, Divider } from 'antd';
import { isMobile } from 'react-device-detect';
import { Navbar, SidebarFull, SidebarFullMenuItem } from '../../components/Common';
import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { auth } from '../../services';
import { PageTitles } from './PageTitle';
const { Content } = Layout;

class CustomerLayout extends Component {
  state = {
    sidebarCollapsed: isMobile ? true : false,
    activeSidebar: [],
    pageTitle: PageTitles.customerVisits.pageTitle
  };

  componentDidMount() {
    if (!auth.is_loggedin()) {
      message.warning("Seems like you are not logged in. Login again.");
      this.props.redirect(`/customer/login/${auth.user.merchant.id}`); // needs to be updated for customer logins
    }
    if (this.props.history && this.props.history.location) {
      const path = this.props.history.location.pathname;
      const pageTitleArray = Object.keys(PageTitles).map(k => PageTitles[k]);
      const pageTitleObj = pageTitleArray.find(t => path.includes(t.matchRoute));
      this.setState({ pageTitle: pageTitleObj.pageTitle });
    }

  }

  toggleSidebar = () => {
    // if (isMobile) 
    this.setState({ sidebarCollapsed: !this.state.sidebarCollapsed });
  };

  onChildMount = (pathkey) => {
    this.setState({ activeSidebar: [pathkey || this.props.children.props.pathkey] });
  };

  getCurrentRoute = () => {
    if (this.props.history && this.props.history.location) {
      return this.props.history.location.pathname;
    }
    return null;
  };

  getMerchantName = () => {
    if (auth.user && auth.user.merchant) {
      return auth.user.merchant.name;
    }
    return '';
  };

  getLoginRoute = () => {
    if (auth.user && auth.user.merchant) {
      return `/customer/login/${auth.user.merchant.uid}`;
    }
    return '/customer/login/';
  };

  changePageTitle = (pageTitle) => {
    this.setState({ pageTitle });
  };

  handleMenuItemClick = (pageTitleObj) => {
    this.setState({ pageTitle: pageTitleObj.pageTitle });
    if (isMobile) {
      this.toggleSidebar();
    }
    this.props.redirect(pageTitleObj.routeTo);
  };

  render() {
    const currentRoute = this.getCurrentRoute();
    const showToolTip = this.state.sidebarCollapsed && !isMobile;
    return (
      <div className="admin-layout-container h-100">
        <div className="admin-layout-body h-100">
          <Layout style={isMobile && !this.state.sidebarCollapsed ? { backgroundColor: 'rgba(0, 0, 0, 0.75)' } : {}}>
            <SidebarFull sidebarCollapsed={this.state.sidebarCollapsed} >
              <div style={{
                padding: 10, height: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }} className="sidebar-top">
                {/* <div className="m-auto">
                  <img style={{ width: "100%" }} alt="" src="" />
                </div> */}
                <div style={{
                  fontSize: '1.4rem',
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%'
                }}>
                  {
                    !this.state.sidebarCollapsed &&
                    <div style={{ paddingLeft: isMobile ? 16 : 0, width: '100%' }}>{this.getMerchantName()}</div>
                  }
                  {
                    isMobile &&
                    <div style={{ margin: '0 16px' }}>
                      <FAI icon={faChevronLeft} color="#fff" size="1x"
                        className="c-pointer ml-2 sidebar-trigger"
                        onClick={this.toggleSidebar} />
                    </div>
                  }
                  {
                    !isMobile && this.state.sidebarCollapsed &&
                    <div>
                      <FAI icon={faBars} color="#fff" size="1x"
                        className="c-pointer ml-2 sidebar-trigger"
                        onClick={this.toggleSidebar} />
                    </div>
                  }
                </div>
              </div>
              <Menu theme="light" mode="inline" selectedKeys={this.state.activeSidebar}>
                <SidebarFullMenuItem
                  permissions={[]}
                  key="customer_booking"
                  routeTo="booking"
                  showToolTip={showToolTip}
                  onClick={() => this.handleMenuItemClick(PageTitles.customerBooking)}
                  label="New Appointment"
                  icon={faPlusCircle} />
                <SidebarFullMenuItem
                  permissions={[]}
                  key="customer_sessions"
                  routeTo="sessions"
                  showToolTip={showToolTip}
                  onClick={() => this.handleMenuItemClick(PageTitles.customerVisits)}
                  label="My Visits"
                  icon={faCalendarAlt} />
                <SidebarFullMenuItem
                  permissions={[]}
                  key="customer_profile"
                  routeTo="profile"
                  showToolTip={showToolTip}
                  onClick={() => this.handleMenuItemClick(PageTitles.customerProfile)}
                  label="My Profile"
                  icon={faUserCircle} />
                <Divider />
                <SidebarFullMenuItem
                  permissions={[]}
                  key="signout"
                  showToolTip={showToolTip}
                  onClick={this.props.redirect.bind(this, this.getLoginRoute(), () => { auth.remove_user(); })}
                  label="Sign Out"
                  icon={faSignOutAlt} />

              </Menu>
            </SidebarFull>
            <Layout className="main-layout-content-wrapper"
              style={this.state.sidebarCollapsed ? styles.layoutInnerCollapsed : { ...styles.layoutInner, zIndex: isMobile ? -1 : 'inherit' }}>
              <Navbar
                pageTitle={this.state.pageTitle}
                drawerWidth={240}
                showUser={false}
                showToggleIcon={true}
                loginRoute={this.getLoginRoute()}
                toggleSidebar={this.toggleSidebar.bind(this)}
                sidebarCollapsed={this.state.sidebarCollapsed}
                redirect={this.props.redirect.bind(this)} />
              <Content style={{ ...styles.content, marginTop: isMobile ? 0 : 60 }}>
                {auth.is_loggedin() && React.cloneElement(this.props.children, {
                  onChildMount: this.onChildMount.bind(this),
                  changeTheme: this.props.changeTheme
                })}
              </Content>
            </Layout>
          </Layout>
        </div>
      </div>
    );
  }
}

const styles = {
  layoutInnerCollapsed: {
    marginLeft: isMobile ? 0 : 56,
    transition: 'all .2s',
    WebkitTransition: 'all .2s'
  },
  layoutInner: {
    marginLeft: isMobile ? 0 : 240,
    transition: 'all .2s',
    WebkitTransition: 'all .2s',
    background: 'transparent !important'
  },
  content: {
    background: '#f5f5f5',
    padding: '0px',
    overflow: 'initial',
    marginTop: 60
  }
};

export default CustomerLayout;
