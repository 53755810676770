import React, { Component } from 'react';
import _isEqual from 'lodash/isEqual';
import { Typography, Divider, Button, List, Table, Descriptions, Tag, Icon } from 'antd';
import { humanizeDate, user_fullname, handleApiExceptions, alertify } from '../../../app_methods';
import { AppointmentService, SessionService } from '../../../services';
import { isMobile } from 'react-device-detect';
import moment from 'moment/moment';
import { EaseModal } from '../../../libs/EaseModal';
import ServiceProvided from '../ServiceProvided';

class Checkout extends Component {

  constructor(props) {
    super(props);
    let services_provided = props.session.services_provided;
    if (props.session && props.session.services_provided.length === 0) {
      services_provided = [...props.session.services_requested];
    }
    this.state = {
      processing: false,
      is_paid: false,
      is_updated: false,
      session: {
        ...props.session,
        services_provided: services_provided
      },
      serviceProvidedModalVisible: false
    };

  }

  markAsPaid = () => {
    this.setState({ processing: true });
    AppointmentService.update({ id: this.props.session.id, is_paid: true }).then((response) => {
      if (response.success) {
        alertify("Awesome!!!", "Appointment marked as paid.", "success");
        this.setState({ processing: false, is_paid: true }, () => {
          this.props.afterUpdate();
        });
      } else {
        this.setState({ processing: false });
        alertify("Oops!!!", "Could not update appintment. Try again", "error");
      }
    }).catch((error) => {
      this.setState({ processing: false });
      handleApiExceptions(error);
    });
  };

  removeServiceProvided = ({ service, staff }) => {
    const session = { ...this.state.session };
    const servicesProvided = session.services_provided.filter(s => s.service !== service && s.staff !== staff);
    session.services_provided = servicesProvided;
    this.setState({ session });
  };

  saveServicesProvided = () => {
    this.setState({ processing: true });
    let services_provided = [];
    // if (this.props.session.services_provided.length === 0) {
    services_provided = this.state.session.services_provided.map((s) => { return { service: s.service.id, staff: s.staff }; });
    // }
    const payload = {
      session: this.props.session.id,
      services_provided: services_provided
    };
    console.log('payload', payload);
    SessionService.updateServicesProvided(payload).then((response) => {
      if (response.success) {
        alertify("Awesome!!!", "List of services provided updated. Receipt generated successfully!", "success");
        this.setState({ processing: false, session: response.data });
      } else {
        this.setState({ processing: false });
        alertify("Oops!!!", "Could not update list of services provided. Try again", "error");
      }
    }).catch((error) => {
      this.setState({ processing: false });
      handleApiExceptions(error);
    });
  };

  checkoutCustomer = () => {
    if (this.state.session.is_paid) {
      this.setState({ processing: true });
      SessionService.checkout(this.state.session).then((response) => {
        if (response.success) {
          alertify("Awesome!!!", "Customer has checked out.", "success");
          this.setState({ processing: false }, () => this.props.afterUpdate());
        } else {
          this.setState({ processing: false });
          alertify("Oops!!!", "Could not checkout customer. Try again", "error");
        }
      }).catch((error) => {
        this.setState({ processing: false });
        handleApiExceptions(error);
      });
    }
  };

  toggleServiceProvidedModal = () => {
    this.setState({ serviceProvidedModalVisible: !this.state.serviceProvidedModalVisible });
  };

  getCheckoutTime = () => {
    const dateFormat = 'DD-MMM-YY h:mm A';
    if (this.props.session.checkout_time) {
      return moment(this.props.session.checkout_time).format(dateFormat);
    }
    return moment().format(dateFormat);
  };

  getTotal = () => {
    if (this.state.session.services_provided &&
      this.state.session.services_provided.length > 0) {
      return this.state.session.services_provided.reduce((acc, curr) => acc + curr.service.sell_price, 0);
    }
    return '';
  };

  render() {
    console.log('checkout modal session', this.state.session);
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Tag color={this.state.session.is_paid ? "green" : "red"}
            style={{ padding: 8, fontSize: 24, marginRight: 0 }}
            className="text-center">{this.state.session.is_paid ? "Paid" : "Not Paid"}</Tag>
        </div>
        <Descriptions title="Customer Info" layout="vertical" style={{ marginTop: -24, fontWeight: 'bold' }}>
          <Descriptions.Item label="Name" style={{ fontWeight: 'bold !important' }}>{user_fullname(this.props.session.customer_obj)}</Descriptions.Item>
          <Descriptions.Item label="Phone" >{this.props.session.customer_obj.phone}</Descriptions.Item>
          <Descriptions.Item label="Email">{this.props.session.customer_obj.email}</Descriptions.Item>
        </Descriptions>
        <Descriptions layout="horizontal">
          <Descriptions.Item label="Date">{this.getCheckoutTime()}</Descriptions.Item>
        </Descriptions>
        <Descriptions title="Services Provided" layout="vertical" style={{ marginTop: 12, marginBottom: 0 }} />
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', marginTop: -54, marginBottom: 12 }}>
          <div>
            <Button onClick={this.toggleServiceProvidedModal}>
              <Icon type="plus" />
            </Button>
          </div>
        </div>
        <Table
          loading={this.state.fetchingData}
          columns={this.columns}
          pagination={false}
          dataSource={this.state.session.services_provided}
          style={{ border: '1px solid #e8e8e8', borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomWidth: 0 }}
          scroll={{ x: isMobile ? true : false }} />
        {
          this.state.session.services_provided &&
          this.state.session.services_provided.length > 0 &&
          <div style={{
            display: 'flex', flexDirection: 'row', padding: 12, fontSize: 20,
            border: '1px solid #e8e8e8',
            borderTopWidth: 0,
            borderBottomLeftRadius: 4, borderBottomRightRadius: 4
          }}>
            <div style={{ flex: 2 }}></div>
            <div style={{ flex: '1 1 8%', display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1 }}>
                <Typography.Text className="d-block" strong>{'Total:'}</Typography.Text>
              </div>
              <div style={{ flex: 1, textAlign: 'center' }}>
                <Typography.Text className="d-block" strong>{this.getTotal()}</Typography.Text>
              </div>
              {/* <Descriptions layout="horizontal" bordered size={'middle'}>
                <Descriptions.Item label="Total">{this.getTotal()}</Descriptions.Item>
              </Descriptions> */}
            </div>
            {/* <Typography.Text className="d-block" strong>{'Total: ' + this.getTotal()}</Typography.Text> */}
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
          <Button onClick={() => {
            if (this.state.is_updated) {
              this.props.afterUpdate();
            } else {
              this.props.onCancel();
            }
          }}>Cancel</Button>
          {/* <Button className="ml-2" onClick={this.saveServicesProvided}>Save Services Provided</Button> */}
          <Button type="primary" className="ml-2"
            onClick={this.saveServicesProvided}>Save & Generate Receipt</Button>
          <Button type="primary" className="ml-2"
            disabled={this.state.session.is_paid ? false : true}
            onClick={this.checkoutCustomer}>Checkout</Button>
        </div>
        <EaseModal
          title={'Add Service Provided'}
          hideFooter
          visible={this.state.serviceProvidedModalVisible}
          onCancel={() => {
            this.toggleServiceProvidedModal();
          }}
        >
          <ServiceProvided onSubmit={(data) => {
            const sp = [...this.state.session.services_provided];
            sp.push(data);
            const session = { ...this.state.session, services_provided: sp };
            this.setState({ session }, () => this.toggleServiceProvidedModal());
          }} />
        </EaseModal>
      </div>
    );
  }

  columns = [
    {
      title: 'No.',
      key: "index",
      render: (text, record, index) => {
        return (<Typography.Text >{index + 1}</Typography.Text>);
      }
    },
    {
      title: 'Service',
      key: 'service',
      render: (record) => <div>{record.service.name}</div>
    },
    {
      title: 'Description',
      key: 'desc',
      render: (record) => (
        <Typography.Text type="secondary">{record.service.description || "---"}</Typography.Text>
      )
    },
    {
      title: 'Staff',
      key: 'staff',
      render: (record) => (
        <Typography.Text type="secondary">{user_fullname(record.staff_obj)}</Typography.Text>
      )
    },
    {
      title: 'Amount',
      key: "amount",
      render: (record) => <Typography.Text >{record.service.sell_price}</Typography.Text>
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (record) => <Button size='small' onClick={() => this.removeServiceProvided(record)}>Delete</Button>,
    },
  ];

  // render() {
  //     return (
  //         <div>
  //             <Typography.Title title={4} style={{ fontSize: 24 }}>Invoice</Typography.Title>
  //             <Typography.Text className="float-right">{humanizeDate(this.props.session.scheduled_at, false)}</Typography.Text>
  //             <div className="clearfix" />
  //             <Divider className="m-1" />
  //             <div className="customer-details">
  //                 <Typography.Text className="d-block" strong>{user_fullname(this.props.session.customer_obj)}</Typography.Text>
  //                 <Typography.Text className="d-block">{this.props.session.customer_obj.email}</Typography.Text>
  //                 <Typography.Text className="d-block">{this.props.session.customer_obj.phone}</Typography.Text>
  //             </div>
  //             <div className="invoice-items d-table w-100 mt-4">
  //                 <div className="d-table-row">
  //                     <div className="d-table-cell pr-3 border-bottom">
  //                         <Typography.Text strong>Service</Typography.Text>
  //                     </div>
  //                     <div className="d-table-cell pr-3 border-bottom">
  //                         <Typography.Text strong>Duration</Typography.Text>
  //                     </div>
  //                     <div className="d-table-cell pr-3 border-bottom">
  //                         <Typography.Text strong>Rate</Typography.Text>
  //                     </div>
  //                 </div>
  //                 <div className="d-table-row">
  //                     <div className="d-table-cell pr-3 pt-2">
  //                         <div className="d-flex flex-column">
  //                             <Typography.Text>{this.props.session.services_requested[0].service.name}</Typography.Text>
  //                             {/* <Typography.Text type="secondary">{this.props.session.service_obj.description}</Typography.Text> */}
  //                         </div>
  //                     </div>
  //                     <div className="d-table-cell pr-3 pt-2">{this.props.session.services_requested[0].service.service_minutes} minutes</div>
  //                     <div className="d-table-cell pr-3 pt-2">Rs. {this.props.session.amount}/-</div>
  //                 </div>
  //             </div>
  //             <div className="staff-notes mt-4">
  //                 <Typography.Text className="d-block" strong>Staff Note:</Typography.Text>
  //                 <Typography.Text className="d-block">{this.props.session.services_requested[0].note_by_staff || "---"}</Typography.Text>
  //             </div>

  //             <Button className="float-right mt-3"
  //                 loading={this.state.processing}
  //                 disabled={this.state.is_paid || this.props.session.is_paid}
  //                 onClick={this.markAsPaid}>Mark as Paid</Button>
  //             <div className="clearfix" />
  //         </div>
  //     );
  // }
}

Checkout.defaultProps = {
  session: {},
  servicesProvided: [],
  onCancel: () => { },
  afterUpdate: () => { }
};

export default Checkout;