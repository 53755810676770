import React, { Component } from 'react';
import { Select, Button, Icon } from 'antd';
import { humanizeDate } from '../../../app_methods';
import { isMobile } from 'react-device-detect';

class CalendarToolbar extends Component {
    render() {
        const { defaultView, onCalendarViewChange, customButtons } = this.props;
        return (
            <div className="am-calendar-toolbar px-2 py-2 d-flex justify-content-between align-items-center" style={{ position: isMobile ? 'static' : 'fixed' }}>
                <div className={`left-corner d-flex flex-wrap ${isMobile ? 'w-100' : ''}`}>
                    {customButtons}
                </div>
                <div className="center">
                    <Button icon="arrow-left" onClick={this.props.calendarPrev}>Prev</Button>
                    <Button className="ml-1 mr-1" onClick={this.props.calendarToday}>Today</Button>
                    <Button onClick={this.props.calendarNext}>Next<Icon type="arrow-right" /></Button>
                </div>
                <div className={`right-corner ${isMobile ? 'w-100' : ''}`}>
                    {
                        this.props.calendar ? (
                            <Button className={`mr-2 ${isMobile ? 'w-100' : ''}`}>{humanizeDate(this.props.calendar.getDate(), false)}</Button>
                        ) : null
                    }
                    <Select
                        style={{ width: isMobile ? '100%' : 200 }}
                        onChange={onCalendarViewChange}
                        defaultValue={defaultView}
                    >
                        <Select.Option key={1} value="timeGridDay">Daily</Select.Option>
                        <Select.Option key={2} value="timeGridWeek">Weekly</Select.Option>
                        <Select.Option key={3} value="dayGridMonth">Monthly</Select.Option>
                        <Select.Option key={4} value="listDay">Summary</Select.Option>
                    </Select>
                </div>
            </div>
        )
    }
}

export { CalendarToolbar }