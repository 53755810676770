import React, { Component } from 'react';
import { Button, Input } from 'antd';
import StaffList from './StaffList';
import _isEmpty from 'lodash/isEmpty';
import './index.scss';
import StaffWizard from './StaffWizard';
import data from '../../../data.json';
import StaffListMobile from './StaffListMobile';
import { BrowserView, isMobile } from 'react-device-detect';
import { UserService, auth } from '../../../../services';
import { handleApiExceptions, alertify } from '../../../../app_methods';

class StaffSetting extends Component {
    user_permissions = auth.permissions();

    state = {
        addStaffVisible: false,
        staffList: [],
        activeStaff: {},
        staff: {
            service_list: []
        },
        processing: false
    };

    componentDidMount() {
        this.fetchStaffList();
    }

    fetchStaffList = () => {
        const staff_settings_permission = this.user_permissions["SETTINGS:STAFF"];
        UserService.getStaffList({
            merchant: auth.user.merchant.id,
            id: staff_settings_permission == "SELF" ? auth.user.id : undefined
        }).then((response) => {
            if (response.success) {
                this.setState({ staffList: response.data.results });
                if (_isEmpty(this.state.activeStaff)) {
                    this.setState({ activeStaff: response.data[0] || {} });
                }
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchStaffInfo = (staff) => {
        this.setState({ activeStaff: staff });
    };

    renderAddStaff = () => {
        return (
            <div className="d-flex flex-column">
                <Input
                    name="first_name"
                    disabled={this.state.processing}
                    onChange={this.handleStaffFieldChange}
                    value={this.state.staff.first_name}
                    placeholder="First Name"
                    style={{ width: "200px" }} />
                <Input
                    name="last_name"
                    disabled={this.state.processing}
                    onChange={this.handleStaffFieldChange}
                    value={this.state.staff.last_name}
                    placeholder="Last Name"
                    style={{ width: "200px" }}
                    className="mt-2" />
                <Input
                    name="email"
                    disabled={this.state.processing}
                    onChange={this.handleStaffFieldChange}
                    value={this.state.staff.email}
                    placeholder="Email Address"
                    style={{ width: "200px" }}
                    className="mt-2" />
                <Button
                    type="primary"
                    loading={this.state.processing}
                    onClick={this.addStaff}
                    className="mt-2">{this.state.processing ? "Adding Staff..." : "Add"}</Button>
            </div>
        );
    };

    handleStaffFieldChange = (e) => {
        const staff = this.state.staff;
        staff[e.target.name] = e.target.value;
        this.setState({ staff });
    };

    toggleAddStaff = () => {
        this.setState({ addStaffVisible: !this.state.addStaffVisible });
    };

    addStaff = () => {
        const { staff } = this.state;
        if (!staff.first_name || !staff.last_name || !staff.email || !staff.first_name.trim() || !staff.last_name.trim() || !staff.email.trim()) {
            alertify("Oops!!!", "Staff name and email is mandatory.", "error");
            return;
        }
        this.setState({ processing: true });
        UserService.create({
            ...this.state.staff, merchant: auth.user.merchant.id, role: 'staff'
        }).then((response) => {
            if (response.success) {
                this.setState({ staff: {} });
                // this.setState(() => ({ staff: {first_name: "", last_name: "", email: ""} }));                
                this.fetchStaffList();
                this.toggleAddStaff();
            } else {
                alertify("Oops!!!", response.message, "error");
            }
            this.setState({ processing: false });
        }).catch((error) => {
            this.setState({ processing: false });
            handleApiExceptions(error);
        });
    };

    render() {
        return (
            <div id="staff-settings-wrapper" className="d-flex w-100">
                <div className={`staff-list p-3 ${isMobile ? 'd-none' : ''}`}>
                    <StaffList
                        addStaffVisible={this.state.addStaffVisible}
                        toggleAddStaff={this.toggleAddStaff}
                        staffList={this.state.staffList}
                        addStaff={this.addStaff}
                        onStaffSelect={this.fetchStaffInfo}
                        activeStaff={this.state.activeStaff}
                        renderAddStaff={this.renderAddStaff} />
                </div>
                <div className={`staff-details p-3 ${isMobile ? 'w-100' : ''}`}>
                    <StaffListMobile
                        staffList={this.state.staffList}
                        activeStaff={this.state.activeStaff}
                        onStaffSelect={this.fetchStaffInfo}
                        renderAddStaff={this.renderAddStaff} />
                    <StaffWizard
                        fetchStaffList={this.fetchStaffList}
                        staffDetails={this.state.activeStaff} />
                </div>
            </div>
        );
    }
}

export default StaffSetting;