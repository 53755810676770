import React from 'react';
import { isMobile } from 'react-device-detect';
import { Select, Popover, Typography, Button } from 'antd';
import { PermissionHoc } from '../../../HOC';
const PermissableButton = PermissionHoc(Button, '', { is_component: true, fallbackComponent: null });

const StaffListMobile = (props) => {

    const handleStaffChange = (id) => {
        const active_staff = props.staffList.find(x => x.id === id);
        props.onStaffSelect(active_staff);
    }

    return (
        <div className={`w-100 mb-2 staff-list-select-box ${isMobile ? 'd-flex' : 'd-none'}`}>
            <Select placeholder="Select Staff" className="w-100" value={props.activeStaff.id} onChange={handleStaffChange} >
                {
                    props.staffList.map((staff, index) => {
                        return (
                            <Select.Option key={index} value={staff.id}>{staff.first_name} {staff.last_name}</Select.Option>
                        )
                    })
                }
            </Select>
            <Popover placement="bottom" className="ml-2" trigger={["click", "hover"]} title={<Typography.Text strong>Add Staff</Typography.Text>} content={props.renderAddStaff()}>
                <PermissableButton required_permission={["SETTINGS:STAFF:ADD"]} icon="plus" />
            </Popover>
        </div>
    )
}

export default StaffListMobile;