import React, { Component } from 'react';
import { Form, Input, Avatar, Button, Icon } from 'antd';
import { SketchPicker } from 'react-color';
import { auth, BusinessService } from '../../../services';
import { alertify, handleApiExceptions, resolve_server_image } from '../../../app_methods';

class ServiceForm extends Component {
    toggleColorPicker = this.toggleColorPicker.bind(this);
    state = {
        serviceColor: "rgba(0, 198, 191, 0.3)",
        processing: false,
        icon: {
            avatar: this.props.service.is_edit && this.props.service.service_image ? resolve_server_image(this.props.service.service_image) : undefined
        }
    }

    componentDidMount() {
        const { props } = this;
        props.form.setFieldsValue({ service: props.service });
        if(props.service.is_edit && props.service.color_tag){        
            this.setState({ serviceColor: props.service.color_tag });
        }
    }

    toggleColorPicker() {
        this.setState({ colorPickerVisible: !this.state.colorPickerVisible });
    }

    updateAvatar(id) {
        if (!this.state.icon.avatarFile) { this.props.afterSubmit(); return; }
        let formData = new FormData();
        formData.set('image', this.state.icon.avatarFile);
        BusinessService.update_icon(
            { formdata: formData, id },
            { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then((response) => {
            if (response.success) {
                alertify("Awesome!!!", `Service image updated successfully`, "success");
                this.props.afterSubmit();
            }
            else {
                alertify("Oops!!!", response.message, "error");
                this.setState({ processing: false });
            }
        })
    }

    createService(e) {
        e.preventDefault();
        if (this.props.service.is_edit) { this.updateService(); return; }
        this.props.form.validateFields((err, values) => {
            if (err) { return; }
            this.setState({ processing: true });
            values.service.color_tag = this.state.serviceColor;
            BusinessService.create({ ...values.service }).then((response) => {
                if (response.success) {
                    if (response.success) {
                        alertify("Awesome!!!", `Service added successfully`, "success");
                        this.setState({ processing: false });
                        this.updateAvatar(response.data.id);
                    }
                    else {
                        alertify("Oops!!!", response.message, "error");
                        this.setState({ processing: false });
                    }
                }
                else {
                    alertify("Oops!!!", response.message, "error");
                    this.setState({ processing: false });
                }
            }).catch((err) => {
                handleApiExceptions(err);
                this.setState({ processing: false });
            });
        });
    }

    updateService() {
        this.props.form.validateFields((err, values) => {
            if (err) { return; }
            this.setState({ processing: true });
            values.service.color_tag = this.state.serviceColor;
            BusinessService.update({ ...values.service, id: this.props.service.id }).then((response) => {
                if (response.success) {
                    alertify("Awesome!!!", `Service updated successfully`, "success");
                    this.setState({ processing: false });
                    this.updateAvatar(response.data.id);
                }
                else {
                    alertify("Oops!!!", response.message, "error");
                    this.setState({ processing: false });
                }
            }).catch((err) => {
                handleApiExceptions(err);
                this.setState({ processing: false });
            });
        });
    }

    handleAvatarChange(e) {
        const icon = this.state.icon;
        icon.avatarFile = e.target.files[0];
        icon.avatar = URL.createObjectURL(e.target.files[0]);
        this.setState({ icon });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="mx-auto mt-4" style={{ width: "80%" }}>
                <Form layout="vertical" onSubmit={this.createService.bind(this)}>
                    {getFieldDecorator('service.merchant', { initialValue: auth.user.merchant.id })(
                        <Input hidden />
                    )}
                    {getFieldDecorator('service.category', { initialValue: this.props.activeCategory.id })(
                        <Input hidden />
                    )}

                    <div className="service-icon p-relative mx-auto c-pointer mb-3" style={{ width: 120, height: 120, overflow: "hidden", borderRadius: "50%" }}>
                        <img onClick={() => { this.refs.avatarUploader.click(); }} src={this.state.icon.avatar || require('../../../assets/images/default.png')} className="w-100" />
                        <input type="file" id="avatar" ref="avatarUploader" onChange={this.handleAvatarChange.bind(this)} style={{ display: "none" }} />
                    </div>
                    <Form.Item label="Category Name" >
                        <Input disabled value={this.props.activeCategory.name} />
                    </Form.Item>
                    <Form.Item label="Name" >
                        {getFieldDecorator('service.name', {
                            rules: [{
                                required: true, message: 'Please input service name.',
                            }]
                        })(
                            <Input disabled={this.state.processing} placeholder="Service name" />
                        )}
                    </Form.Item>
                    <Form.Item label="Color" help="We will apply default opacity 0.3 to your selected color.">
                        <div>
                            <div className="d-flex">
                                <div className="w-100 mb-1 p-2 text-center c-pointer" style={{ height: 32, background: this.state.serviceColor, color: "#fff", borderRadius: 5 }}>
                                    {this.state.serviceColor.toUpperCase()}
                                </div>
                                <Button className="ml-1" onClick={this.toggleColorPicker}>{this.state.colorPickerVisible ? "Close" : "Open"}</Button>
                            </div>
                            {
                                this.state.colorPickerVisible ? (
                                    <SketchPicker
                                        color={this.state.serviceColor}
                                        onChangeComplete={(color) => { this.setState({ serviceColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, 0.3)` }) }}
                                        width="94%"
                                    />
                                ) : null
                            }
                        </div>
                    </Form.Item>
                    <Form.Item label="Description" >
                        {getFieldDecorator('service.description', {
                            rules: [{
                                required: true, message: 'Please input service description.',
                            }]
                        })(
                            <Input disabled={this.state.processing} placeholder="Service description" />
                        )}
                    </Form.Item>
                    <Form.Item label="Cost (₹)" >
                        {getFieldDecorator('service.sell_price', {
                            rules: [{
                                required: true, message: 'Please input service cost.',
                            }]
                        })(
                            <Input disabled={this.state.processing} placeholder="Service cost in rupees" />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Form.Item label="Duration (mins)" className="d-inline-block" style={{ width: "48%" }}>
                            {getFieldDecorator('service.service_minutes', {
                                rules: [{
                                    required: true, message: 'Please input service duration in minutes.',
                                }]
                            })(
                                <Input disabled={this.state.processing} placeholder="Service duration in minutes" />
                            )}
                        </Form.Item>
                        <Form.Item label="Buffer Time (mins)" className="d-inline-block ml-2" style={{ width: "49%" }}>
                            {getFieldDecorator('service.buffer_minutes', {
                                initialValue: 0,
                                rules: [{
                                    required: true, message: 'Please input service buffer time. Enter 0 for no buffer time.',
                                }]
                            })(
                                <Input disabled={this.state.processing} placeholder="Service buffer time in minutes" />
                            )}
                        </Form.Item>
                    </Form.Item>
                    <Form.Item className="float-right">
                        <Button loading={this.state.processing} htmlType="submit" type="primary">{this.props.service.is_edit ? "Update Service" : "Add Service"}</Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

ServiceForm.defaultProps = {
    afterSubmit: () => { }
}


const FormWrapper = Form.create()(ServiceForm);
export default FormWrapper;
