import React from 'react';
import { Switch } from 'react-router-dom'
import { SuperAdminLayout } from '../../layouts';
import { SuperAdminMerchant } from './index';

const SuperAdminRouter = (props) => {
    const AppRoute = props.AppRoute;
    return (
        <Switch>
            <AppRoute exact path="/superadmin/merchants/list" layout={SuperAdminLayout} component={SuperAdminMerchant} pathkey="superadmin_merchant" />
        </Switch>
    )
}

export default SuperAdminRouter;
