import React, { Component } from 'react';
import './index.scss';

class CustomerDashboard extends Component {
    render() {
        return (
            <div>
                Customer Dashboard
            </div>
        );
    }
}

export default CustomerDashboard;