import React, { Component } from 'react';
import './index.scss';

class CustomerSettings extends Component {
    render() {
        return (
            <div>
                Customer Settings
            </div>
        );
    }
}

export default CustomerSettings;