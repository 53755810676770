import React, { Component } from 'react';
import { Form, Steps, Typography, Tag } from 'antd';
import moment from 'moment/moment';
import _isEmpty from 'lodash/isEmpty';
import { BusinessService, BreakHourService, WorkingHourService, AppointmentService, auth, OTPService, HolidayService, CountryCodeService, UserService } from '../../../../services';
import { handleApiExceptions, alertify, amAlert, user_fullname } from '../../../../app_methods';
import Service from './Service';
import Staff from './Staff';
import Scheduler from './Scheduler';
import CustomerInfo from './CustomerInfo';
import Confirmation from './Confirmation';
import { isMobile } from 'react-device-detect';
import { useWindowDimensions, WindowSizes } from '../../../../utils/windowDimesions';

class BookingForm extends Component {

    

    constructor(props) {
        super(props);
        const { winsize, height } = useWindowDimensions();
        console.log("winsize constructor", winsize);
        this.state = {
            services: [],
            staffList: [],
            workHours: [],
            breakHours: [],
            currentStep: 0,
            selectedService: {},
            selectedStaff: {},
            selectedDate: this.initDate(),
            selectedTime: undefined,
            selectedSchedule: "",
            customer: {},
            staffAppointmentForSelectedDate: [],
            holidays: [],
            countryCodes: [],
            geoIpData: {},
            otpSent: false,
            otpVerified: false,
            winsize: winsize,
            cardStyles: styles.desktopCard,
            editView: false
        };
        
    }

    initDate = () => {
        const { business_hours } = auth.user.merchant_settings;
        if (business_hours) {
            if (moment(business_hours.end, "HH:mm").isBefore(moment())) {
                return moment().add(1, 'day').startOf('day').format("YYYY-MM-DD");
            }
        }
        return moment().format("YYYY-MM-DD");
    };

    componentDidMount() {
        this.fetchServices();
        this.fetchHolidays();
        this.fetchAppointments();
        this.fetchCountryCodes();
        setTimeout(() => this.fetchIpLocation(), 1000);
        this.setState({cardStyles: this.state.winsize < WindowSizes.MD ? styles.mobileCard : styles.desktopCard});
        // console.log(this.state.cardStyles, this.winsize)
    }

    fetchCountryCodes = () => {
        CountryCodeService.list().then((response) => {
            if (response.success) {
                this.setState({ countryCodes: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchExistingCustomer = (phone_number) => {
        UserService.otpLogin({ phone: phone_number }).then((response) => {
            console.log(response.data);
            if (response.data.detail === 'Not Found.') {
                this.setState({ isExistingCustomer: false });

            } else {
                this.setState({ customer: response.data, isExistingCustomer: true });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchIpLocation = () => {
        CountryCodeService.getIpLocation().then((response) => {
            if (response) {
                const data = {
                    geoIpData: response,
                    customer: {},
                    existingCustomer: {}
                };
                if (this.state.countryCodes.length > 0) {
                    const countryCode = this.state.countryCodes.find(c => c.iso === response.country_code);
                    if (countryCode) {
                        data.customer.country_code = countryCode.phonecode;
                    }
                }
                this.setState({ ...data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchAppointments = () => {
        AppointmentService.list({
            scheduled_at__gte: moment(this.state.selectedDate).startOf('day').format("YYYY-MM-DD HH:mm"),
            scheduled_at__lte: moment(this.state.selectedDate).endOf('day').format("YYYY-MM-DD HH:mm"),
            staff: this.state.selectedStaff.id
        }).then((response) => {
            if (response.success) {
                this.setState({ staffAppointmentForSelectedDate: response.data.results });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchServices = () => {
        BusinessService.list({ merchant: this.props.merchant.id }).then((response) => {
            if (response.success) {
                const services = response.data.map(s => {
                    s.key = String(s.id);
                    return s;
                });
                this.setState({ services });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchServiceUsers = (service_id) => {
        BusinessService.getUsers({ id: service_id }).then((response) => {
            if (response.success) {
                this.setState({ staffList: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchStaffBreakHours = (id) => {
        BreakHourService.list({ staff: id }).then((response) => {
            if (response.success) {
                this.setState({ breakHours: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchStaffWorkingHours = (id) => {
        WorkingHourService.list({ staff: id }).then((response) => {
            if (response.success) {
                this.setState({ workHours: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchHolidays = () => {
        HolidayService.list({ merchant: this.props.merchant.id }).then((response) => {
            if (response.success) {
                this.setState({ holidays: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    setCustomer = (form, e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) { return; }
            this.setState({
                customer: { ...values.customer },
                currentStep: this.state.currentStep + 1
            }, () => {
                this.sendOtp(this.state.customer.phone, this.state.customer.country_code);
            });
        });

    };

    sendOtp = (phone, country_code) => {
        this.setState({ otp_verified: false });
        OTPService.generate({ phone_number: phone, country_code }).then((response) => {
            if (response.status) {
                alertify("Awesome", `OTP successfully sent on ${phone}.`, "success");
                this.setState({ otpSent: true });
            } else {
                alertify("Oops", `Could not send OTP. Try again.`, "error");
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    verifyOtp = (phone, otp, action) => {
        OTPService.verify({ phone_number: phone, otp }).then((response) => {
            if (response.status) {
                this.setState({ otp_verified: true });
                alertify("Awesome", `OTP successfully verified.`, "success");
                if (action === 'book_appointment') {
                    this.bookAppointment();
                } else if (action === 'login') {
                    this.fetchExistingCustomer(phone);
                }
            } else {
                alertify("Oops", `Could not verify OTP. Try again.`, "error");
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    bookAppointment = (form) => {
        if (!this.state.otp_verified) {
            alertify("OTP Verificataion Pending", "OTP verification is mandatory to book an appointment.", "info");
            return;
        }
        const note = this.state.customer.note || "CREATED BY CUSTOMER";
        delete this.state.customer.note;
        const statuses = auth.user.merchant.appointment_status;
        console.log('statuses', statuses);
        const status = statuses.filter(s => s.key === 'pending')[0];
        const payload = {
            merchant: this.props.merchant.id,
            services_requested: [
                {
                    staff: this.state.selectedStaff.id,
                    service_id: this.state.selectedService.id,
                    note: note
                }
            ],
            // service: this.state.selectedService.id,
            staff: this.state.selectedStaff.id,
            customer_info: this.state.customer,
            scheduled_at: this.state.selectedSchedule,
            amount: this.state.selectedService.sell_price,
            service_minutes: this.state.selectedService.service_minutes,
            note: note,
            created_by: 1,
            status: status.id
        };

        AppointmentService.create(payload).then((response) => {
            if (response.success) {
                amAlert('Awesome!!!', "Your appointment is booked successfully. You will receive an email & SMS regarding your appointment confirmation.", "success", () => { window.location.reload(); });
            }
            else {
                alertify("Oops!!!", response.message, "error");
                this.setState({ processing: false });
            }
        }).catch((err) => {
            if (err && err.response && err.response.status) {
                if (err.response.data && err.response.data.message && err.response.data.message.availability_status) {
                    if (!err.response.data.message.availability_status.available) {
                        amAlert('Oops!!!', "Staff is not available on selected hours.", "warning");
                    }
                }
            } else {
                handleApiExceptions(err);
            }
            this.setState({ processing: false });
        });
    };

    selectService = (service) => {
        console.log("here wasi")
        console.log(this)
        this.setState({
            selectedService: service,
            selectedStaff: {},
            currentStep: this.state.currentStep + 1
        }, () => {
            this.fetchServiceUsers(service.id);
        });
    };

    selectStaff = (staff) => {
        this.setState({
            selectedStaff: staff,
            currentStep: this.state.currentStep + 1
        }, () => {
            this.fetchStaffWorkingHours(staff.id);
            this.fetchStaffBreakHours(staff.id);
        });
    };

    selectDate = (date) => {
        this.setState({
            selectedTime: undefined,
            selectedDate: date ? date.format("YYYY-MM-DD") : undefined
        }, () => date ? this.fetchAppointments() : null);
    };

    selectTime = (time, a, b) => {
        this.setState({
            selectedTime: time.format("HH:mm")
        });
    };

    selectSchedule = () => {
        if (!this.state.selectedDate || !this.state.selectedTime) {
            alertify("Oops!!!", "Select date & time of appointment to proceed.", "warning");
            return;
        }
        const schedule = `${this.state.selectedDate} ${this.state.selectedTime}`;
        this.setState({
            selectedSchedule: schedule,
            currentStep: this.state.currentStep + 1
        });
    };

    goToStep = (step) => {
        if (step < this.state.currentStep) {
            this.setState({
                currentStep: step,
                selectedDate: step < 2 ? undefined : this.state.selectedDate,
                selectedTime: step < 2 ? undefined : this.state.selectedTime,
            });
        }
        // this.setState({
        //     currentStep: step,
        //     selectedDate: step < 2 ? undefined : this.state.selectedDate,
        //     selectedTime: step < 2 ? undefined : this.state.selectedTime,
        // });
    };
    editStep = (step) => {
        this.setState({
            currentStep: step,
            selectedDate: step < 2 ? undefined : this.state.selectedDate,
            selectedTime: step < 2 ? undefined : this.state.selectedTime,
            editView: true
        });
        console.log("this.state.editView", this.state.editView)
    };
    goBackToConfirm = (step, data) => {
        switch(step) {
            case 0:
                this.setState({
                    selectedService: data
                });
                break;
            case 1:
                this.setState({
                    selectedStaff: data
                });
                break;
            case 2:
                if (!this.state.selectedDate || !this.state.selectedTime) {
                    alertify("Oops!!!", "Select date & time of appointment to proceed.", "warning");
                    return;
                }
                const schedule = `${this.state.selectedDate} ${this.state.selectedTime}`;
                this.setState({
                    selectedSchedule: schedule
                });
                break;
        }
        this.setState({
            currentStep: 4,
            editView: false
        });
        console.log("this.state.editView", this.state.editView)
    }

    renderStepDescription = (description, value) => {
        return (
            <div className="stepper-content">
                <span>{description}</span>
                {
                    (!value) ? null :
                        <div style={{ paddingLeft: 14 }}>
                            <Tag color="green" className="mt-1">{value}</Tag>
                        </div>
                }
            </div>
        );
    };

    // getMobileStepComponentStyles = () => {
    //     return { 
    //         width: "100%",
    //         height: height - 
    //     }
    // }

    render() {
        const formComponents = {
            0: <Service
                service={this.state.selectedService}
                merchant={this.props.merchant}
                services={this.state.services}
                selectService={this.selectService}
                editView={this.state.editView}
                goBackToConfirm={this.goBackToConfirm}
            />,
            1: <Staff
                merchant={this.props.merchant}
                staffList={this.state.staffList}
                selectStaff={this.selectStaff}
                staff={this.state.selectedStaff}
                goBack={() => this.goToStep(0)}
                editView={this.state.editView}
                goBackToConfirm={this.goBackToConfirm}
            />,
            2: <Scheduler
                merchant={this.props.merchant}
                workHours={this.state.workHours}
                businessHours={auth.user.merchant_settings.business_hours}
                breakHours={this.state.breakHours}
                selectDate={this.selectDate}
                selectTime={this.selectTime}
                selectSchedule={this.selectSchedule}
                selectedDate={this.state.selectedDate}
                selectedTime={this.state.selectedTime}
                selectedService={this.state.selectedService}
                staffAppointmentForSelectedDate={this.state.staffAppointmentForSelectedDate}
                holidays={this.state.holidays}
                goBack={() => this.goToStep(1)}
                editView={this.state.editView}
                goBackToConfirm={this.goBackToConfirm}
            />,
            3: <CustomerInfo
                countryCodes={this.state.countryCodes}
                geoIpData={this.state.geoIpData}
                merchant={this.props.merchant}
                setCustomer={this.setCustomer}
                customer={this.state.customer}
                existingCustomer={this.state.existingCustomer}
                verifyOtp={this.verifyOtp}
                sendOtp={this.sendOtp}
                otpSent={this.state.otpSent}
                otpVerified={this.state.otp_verified}
                isExistingCustomer={this.state.isExistingCustomer}
                goBack={() => this.goToStep(2)}
                editView={this.state.editView}
                goBackToConfirm={this.goBackToConfirm}
            />,
            4: <Confirmation
                merchant={this.props.merchant}
                service={this.state.selectedService}
                staff={this.state.selectedStaff}
                schedule={this.state.selectedSchedule}
                customer={this.state.customer}
                bookAppointment={this.bookAppointment}
                verifyOtp={this.verifyOtp}
                sendOtp={this.sendOtp}
                goBack={() => this.goToStep(3)}
                editStep={this.editStep}
                editView={this.state.editView}
                goBackToConfirm={this.goBackToConfirm}
            />
        };

        return (
            <div className="w-100" style={this.state.cardStyles}>
                {this.state.winsize >= WindowSizes.MD &&
                <Typography.Text className="mb-3 d-block text-center"
                    type="danger" strong
                    style={{ width: "100%", fontSize: 16, margin: 'auto' }}>Booking Workflow:</Typography.Text>
                }
                {this.state.winsize >= WindowSizes.MD &&
                <Steps progressDot current={this.state.currentStep} direction={isMobile ? 'vertical' : 'horizontal'}>
                    <Steps.Step className="c-pointer"
                        onClick={() => this.goToStep(0)}
                        title="Service"
                        description={this.renderStepDescription("Select service you want to take.", this.state.selectedService.name)} />
                    <Steps.Step className="c-pointer"
                        onClick={() => this.goToStep(1)}
                        title="Staff"
                        description={this.renderStepDescription("Select service provider from the list.", user_fullname(this.state.selectedStaff))} />
                    <Steps.Step className="c-pointer"
                        onClick={() => this.goToStep(2)}
                        title="Date"
                        description={this.renderStepDescription("Pick appointment date & time.",
                            !this.state.selectedSchedule ? "" : moment(this.state.selectedSchedule).format("LLLL"))} />
                    <Steps.Step className="c-pointer"
                        onClick={() => this.goToStep(3)}
                        title="Customer Info"
                        description={this.renderStepDescription("Enter your booking details.", user_fullname(this.state.customer))} />
                    <Steps.Step className="c-pointer"
                        onClick={() => this.goToStep(4)}
                        title="Review & Confirm"
                        description="Review & confirm your appointment." />
                </Steps>
                }
                {this.state.winsize >= WindowSizes.MD &&
                    <div className="step-component mt-1 mx-auto" style={styles.desktopStepComponent}>
                        {formComponents[this.state.currentStep]}
                    </div>
                }

                {this.state.winsize < WindowSizes.MD &&
                    <div className="step-component mx-auto" style={styles.mobileStepComponent}>
                        {formComponents[this.state.currentStep]}
                    </div>
                }
            </div>
        );
    }
};

const styles = {
    mobileStepComponent: { 
        width: "100%",
        height: "100%",
        // display: 'flex',
        // flexDirection: 'column'
    },
    desktopStepComponent: { 
        width: "100%",
        height: "100%",
        // display: 'flex',
        // flexDirection: 'column'
    },
    mobileCard: {
        height: "100%"
    },
    desktopCard: {
        height: "100%"
    }
    
}

BookingForm.defaultProps = {
    afterSubmit: () => { }
};

const FormWrapper = Form.create()(BookingForm);
export default FormWrapper;