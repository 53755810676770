import React, { useState, useEffect } from 'react';
import { Button, Icon, Form, Input, Select, Typography } from 'antd';
import { NumericInput } from '../../../Common/NumericInput';
import { auth, UserService, OTPService } from '../../../../services';
import { isMobile } from 'react-device-detect';
import { alertify, handleApiExceptions } from '../../../../app_methods';
import "./index.scss";

const UserInfo = (props) => {
  console.log('props', props);
  const [isPhoneOtpVerified, setPhoneOtpVerified] = useState(false);
  const [processing, setProcessing] = useState(false);
  // const [isEmailOtpVerified, setEmailOtpVerified] = useState(false);
  const [isPhoneOtpSent, setPhoneOtpSent] = useState(false);
  const [isEmailOtpSent, setEmailOtpSent] = useState(false);
  const [phoneData, setPhoneData] = useState(null);
  const [otp, setOtp] = useState('');
  const { getFieldDecorator } = props.form;

  useEffect(() => {
    props.form.setFieldsValue({ user: props.user });
  }, [props.user]);

  const updatePhone = (payload) => {
    setProcessing(true);
    console.log('props', payload);
    UserService.update(payload).then(response => {
      setProcessing(false);
      alertify('Success!', 'Phone Number has been updated!');
      auth.set_user({ ...auth.user, ...response.data, merchant: auth.user.merchant });
      setPhoneOtpSent(false);
      setPhoneData(null);
      props.toggleEditPhone();
    }).catch((error) => {
      setProcessing(false);
      handleApiExceptions(error);
    });
  };

  const onUpdateName = () => {
    props.form.validateFields((err, values) => {
      if (err) {
        console.log('err', err);
      }
      console.log('userInfo', values);
      const { first_name, last_name, merchant } = values.user;
      const user = { first_name, last_name, merchant: auth.user.merchant.id, id: props.user.id };
      setProcessing(true);
      console.log('props', user);
      UserService.update(user).then(response => {
        setProcessing(false);
        alertify('Success!', 'Your Name has been updated!');
        auth.set_user({ ...auth.user, ...response.data, merchant: auth.user.merchant });
        props.toggleEditName();
      }).catch((error) => {
        setProcessing(false);
        handleApiExceptions(error);
      });
    });
  };
  const updateEmail = () => {
    props.form.validateFields((err, values) => {
      if (err) {
        console.log('err', err);
      }
      console.log('userInfo', values);
      const { merchant, email } = values.user;
      const user = { email, merchant: auth.user.merchant.id, id: props.user.id };
      setProcessing(true);
      UserService.update(user).then(response => {
        setProcessing(false);
        alertify('Success!', 'Your Email Address has been updated!');
        auth.set_user({ ...auth.user, ...response.data, merchant: auth.user.merchant });
        props.toggleEditEmail();
      }).catch((error) => {
        setProcessing(false);
        handleApiExceptions(error);
      });
    });
  };

  const disableOtpRequest = () => {
    if (processing || isPhoneOtpSent) return true;
    else if (props.form.getFieldValue('user.phone').length < 10) {
      return true;
    } else if (props.form.getFieldValue('user.phone') === auth.user.phone) {
      return true;
    }
    return false;
  };


  const sendOtp = () => {
    props.form.validateFields((err, values) => {
      if (err) { }
      const phoneData = {
        phone_number: values.user.phone,
        country_code: values.user.country_code
      };
      console.log(phoneData);
      if (phoneData && phoneData.phone_number) {
        setPhoneOtpVerified(false);
        OTPService.generate(phoneData).then((response) => {
          if (response.status) {
            alertify("Awesome", `OTP successfully sent on ${phoneData.phone_number}.`, "success");
            setPhoneOtpSent(true);
            setPhoneData(phoneData);
          } else {
            setPhoneData(null);
            alertify("Oops", `Could not send OTP. Try again.`, "error");
          }
        }).catch((error) => {
          handleApiExceptions(error);
        });
      }
    });

  };

  const verifyOtp = () => {
    OTPService.verify({ ...phoneData, otp: otp }).then((response) => {
      if (response.status) {
        setPhoneOtpVerified(true);
        alertify("Awesome", `OTP successfully verified.`, "success");
        const payload = {
          phone: phoneData.phone_number,
          country_code: phoneData.country_code,
          id: auth.user.id
        };
        updatePhone(payload);
      } else {
        alertify("Oops", `Could not verify OTP. Try again.`, "error");
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  };

  return (
    <div className="mx-auto mt-4" style={{ width: isMobile ? "100%" : "80%" }}>
      <Form layout="vertical" onSubmit={() => { }}>
        {
          getFieldDecorator('user.merchant', { initialValue: props.user.merchant.id })(<Input hidden />)
        }
        {/* <Form.Item className="text-center">
          {getFieldDecorator('user.avatar', {})(
            <Avatar style={{ width: 120, height: 120 }} />
          )}
        </Form.Item> */}
        <div className="d-flex mb-2 mt-4">
          <div>
            <Typography.Title level={4} style={{ textAlign: 'left' }}>Personal Info</Typography.Title>
          </div>
          <div>
            {
              !props.editName &&
              <Button type="link"
                className="ml-2"
                block
                onClick={props.toggleEditName}>
                Edit
               </Button>
            }
            {
              props.editName &&
              <Button type="link"
                block
                className="ml-2"
                onClick={props.toggleEditName}>
                Cancel
              </Button>
            }
          </div>
        </div>

        <div className="d-flex">
          <Form.Item label="First Name" className="w-50">
            {getFieldDecorator('user.first_name', {
              initialValue: props.user.first_name,
              rules: [{
                required: true, message: 'Please input first name.',
              }]
            })(
              <Input prefix={<Icon type="user" />}
                disabled={processing}
                readOnly={!props.editName}
                placeholder="First name" />
            )}
          </Form.Item>
          <Form.Item label="Last Name" className="ml-2 w-50">
            {getFieldDecorator('user.last_name', {
              initialValue: props.user.last_name,
              rules: [{
                required: true, message: 'Please input last name.',
              }]
            })(
              <Input prefix={<Icon type="user" />}
                disabled={processing}
                readOnly={!props.editName}
                placeholder="Last name" />
            )}
          </Form.Item>
          {
            props.editName &&
            <Form.Item className="ml-2 user-btn" style={{ alignSelf: 'flex-end' }}>
              <Button
                loading={processing}
                onClick={onUpdateName}
                disabled={processing}
                type="primary">Update</Button>
            </Form.Item>
          }
        </div>
        <div className="d-flex mb-2 mt-4">
          <div>
            <Typography.Title level={4} style={{ textAlign: 'left', paddingBottom: 8 }}>Email Address</Typography.Title>
          </div>
          <div>
            {
              !props.editEmail &&
              <Button type="link"
                className="ml-2"
                block
                onClick={props.toggleEditEmail}>
                Edit
               </Button>
            }
            {
              props.editEmail &&
              <Button type="link"
                block
                className="ml-2"
                onClick={props.toggleEditEmail}>
                Cancel
              </Button>
            }
          </div>
        </div>
        <div className="d-flex">
          <Form.Item label="" >
            {getFieldDecorator('user.email', {
              initialValue: props.user.email,
              rules: [
                { required: true, message: 'Please input email.' },
                {
                  pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  message: "Email is not a valid format."
                }
              ]
            })(
              <Input prefix={<Icon type="mail" />} disabled={processing}
                readOnly={!props.editEmail}
                placeholder="Email Address" />
            )}
          </Form.Item>
          {
            props.editEmail &&
            <Form.Item className="ml-2 user-btn" style={{ alignSelf: 'flex-end' }}>
              <Button
                loading={processing}
                // onClick={sendEmailVerificationCode}
                onClick={updateEmail}
                disabled={processing}
                type="primary">Update</Button>
            </Form.Item>
          }
        </div>
        <div className="d-flex mb-2 mt-4">
          <div>
            <Typography.Title level={4} style={{ textAlign: 'left', paddingBottom: 8 }}>Mobile Number</Typography.Title>
          </div>
          <div>
            {
              !props.editPhone &&
              <Button type="link"
                className="ml-2"
                block
                onClick={props.toggleEditPhone}>
                Edit
               </Button>
            }
            {
              props.editPhone &&
              <Button type="link"
                block
                className="ml-2"
                onClick={() => {
                  setPhoneData(null);
                  setPhoneOtpVerified(false);
                  setPhoneOtpSent(false);
                  props.toggleEditPhone();
                }}>
                Cancel
              </Button>
            }
          </div>
        </div>
        <div className="d-flex">
          {/* <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}> */}
          <Form.Item label="Country Code" >
            {getFieldDecorator('user.country_code', {
              initialValue: props.user.country_code,
              rules: [
                { required: true, message: 'Please select country code.' },
              ]
            })(
              <Select
                disabled={processing || !props.editPhone}
                readOnly={!props.editPhone}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  // console.log(option);
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

                }
                }
                style={{ width: 120 }}>
                {
                  props.countryCodes &&
                  props.countryCodes.length > 0 &&
                  props.countryCodes.map(p => (
                    <Select.Option key={p.id} value={p.phonecode}>{`${p.iso} (+${p.phonecode})`}</Select.Option>
                  ))
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Mobile" className="ml-2" style={{ alignSelf: 'flex-end' }} >
            {getFieldDecorator('user.phone', {
              initialValue: props.user.phone,
              rules: [
                { required: true, message: 'Please input mobile number.' },
                { len: 10, message: "Please input valid mobile number." }
              ]
            })(
              <NumericInput prefix={<Icon type="mobile" />}
                readOnly={!props.editPhone}
                disabled={processing}
                maxLength={10}
                placeholder="Mobile Number" />
            )}
          </Form.Item>
          {
            props.editPhone &&
            <Form.Item className="ml-2 user-btn" style={{ marginTop: 20 }}>
              <Button
                loading={processing}
                onClick={sendOtp}
                disabled={disableOtpRequest()}
                type="primary">Update</Button>
            </Form.Item>
          }
        </div>
        <div className="d-flex">
          {
            props.editPhone && isPhoneOtpSent &&
            <>
              <Form.Item label="OTP" >
                <NumericInput
                  id="otp"
                  value={otp}
                  onChange={(value) => setOtp(value)}
                  disabled={processing}
                  placeholder="" />
              </Form.Item>

              <Form.Item className="ml-2 user-btn" style={{ marginTop: 20 }}>
                <Button
                  loading={processing}
                  onClick={verifyOtp}
                  disabled={isPhoneOtpVerified}
                  type="primary">Verify & Save</Button>
              </Form.Item>
            </>
          }
        </div>
      </Form>
    </div>
  );
};

UserInfo.defaultProps = {
  onSubmit: () => { },
  user: {},
  avatar: true
};


const ProfileForm = Form.create()(UserInfo);
export default ProfileForm;