import React from 'react';
// import { auth } from '../../../services';
import { Layout, Menu, Dropdown, Avatar, Typography } from 'antd';
import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import './index.scss';
import { isMobile, MobileView } from 'react-device-detect';
import Text from 'antd/lib/typography/Text';
import { auth } from '../../../services';
import { user_fullname } from '../../../app_methods';
// import { get_user_avatar } from '../../../app_methods';

const Navbar = (props) => {
    const { Header } = Layout;
    const loginRoute = props.loginRoute ? props.loginRoute : '/users/login';
    return (
        <Header className="am-navbar lobo-bg-white d-flex justify-content-between align-items-center"
            style={{
                ...styles.header,
                paddingLeft: props.sidebarCollapsed ? 0 : isMobile ? 0 : props.drawerWidth,
                backgroundColor: isMobile ? 'transparent' : 'inherit',
                position: isMobile ? 'relative' : 'fixed'
            }}>
            <div>
                {/* <MobileView> */}
                <div className="d-flex" style={{ alignItems: 'center', padding: '0 16px' }}>
                    {
                        props.showToggleIcon &&
                        <FAI icon={faBars} color="#fff"
                            style={{ fontSize: 24 }}
                            className="c-pointer ml-2 sidebar-trigger menu-icon"
                            onClick={props.toggleSidebar} />
                    }
                    {
                        props.pageTitle &&
                        <div style={{ alignSelf: 'center', fontSize: '1.4rem', color: '#fff', padding: '0 24px' }}>{props.pageTitle}</div>
                    }
                </div>
                {/* </MobileView> */}
            </div>
            <div>
                {
                    props.showUser &&
                    <Dropdown trigger={['click', 'hover']} placement="bottomLeft" overlay={(
                        <Menu>
                            {/* <Menu.Item onClick={props.redirect.bind(this, '/')}>
                            <FAI icon={faUserGraduate} color="grey" /><span className="ml-2">Profile</span>
                        </Menu.Item>
                        <Menu.Item onClick={props.redirect.bind(this, '/')}>
                            <FAI icon={faLock} color="grey" /><span className="ml-2">Change Password</span>
                        </Menu.Item> */}
                            <Menu.Item onClick={props.redirect.bind(this, loginRoute, () => { auth.remove_user(); })}>
                                <FAI icon={faPowerOff} color="grey" /><span className="ml-2">Logout</span>
                            </Menu.Item>
                        </Menu>
                    )}>
                        <span className={`c-pointer user-avatar-name ant-dropdown-link pr-2 pl-2 ${!props.sidebarCollapsed && isMobile ? 'd-none' : ''}`} href="#" style={{ minWidth: 120, display: 'block' }}>
                            <Avatar icon="user" className="mr-2" />
                            <Text className="color-white">{user_fullname(auth.user)}</Text>
                        </span>
                    </Dropdown>
                }

            </div>
        </Header>
    );
};

const styles = {
    header: {
        padding: 0,
        height: "60px",
        lineHeight: "60px",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9,
        transition: 'all .2s',
        WebkitTransition: 'all .2s'
    }
};

export { Navbar };