import React, { useState, useEffect } from 'react';
import { Form, Input, Avatar, Button, Card } from 'antd';
import { auth, BusinessService, UserService, CountryCodeService } from '../../../services';
import { alertify, handleApiExceptions } from '../../../app_methods';
import ProfileForm from '../shared/ProfileForm';
import { isMobile } from 'react-device-detect';
import './index.scss';

const Profile = (props) => {
    const [userInfo, setUserInfo] = useState({ ...auth.user });
    const [countryCodes, setCountryCodes] = useState([]);
    const [editName, setEditName] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [editPhone, setEditPhone] = useState(false);

    useEffect(() => {
        fetchCountryCodes();
        // setTimeout(() => fetchIpLocation(), 1000);
    }, []);

    const fetchCountryCodes = () => {
        CountryCodeService.list().then((response) => {
            if (response.success) {
                setCountryCodes(response.data);
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    const toggleEditName = () => {
        setUserInfo({ ...auth.user });
        setEditName(!editName);
    };
    const toggleEditEmail = () => {
        setUserInfo({ ...auth.user });
        setEditEmail(!editEmail);
    };
    const toggleEditPhone = () => {
        setUserInfo({ ...auth.user });
        setEditPhone(!editPhone);
    };
    // fetchIpLocation = () => {
    //     CountryCodeService.getIpLocation().then((response) => {
    //         if (response) {
    //             const data = {
    //                 geoIpData: response,
    //                 customer: {},
    //                 existingCustomer: {}
    //             };
    //             if (this.state.countryCodes.length > 0) {
    //                 const countryCode = this.state.countryCodes.find(c => c.iso === response.country_code);
    //                 if (countryCode) {
    //                     data.customer.phone_code = countryCode.phonecode;
    //                     data.existingCustomer.phone_code = countryCode.phonecode;
    //                 }
    //             }
    //             this.setState({ ...data });
    //         }
    //     }).catch((error) => {
    //         handleApiExceptions(error);
    //     });
    // };

    return (
        <div style={{ ...styles.root }}>
            <Card style={{ maxWidth: 600, margin: 'auto' }} className="elevation1">
                <ProfileForm
                    user={userInfo}
                    countryCodes={countryCodes}
                    editName={editName}
                    editEmail={editEmail}
                    editPhone={editPhone}
                    toggleEditName={toggleEditName}
                    toggleEditEmail={toggleEditEmail}
                    toggleEditPhone={toggleEditPhone} />
            </Card>

        </div>
    );

};

const styles = {
    root: {
        margin: 'auto',
        textAlign: 'center',
        padding: isMobile ? 0 : 32,
        width: '100%'
    }
};

export default Profile;