import { get, post, patch } from '../HttpService';

export class AppointmentService {
    static list = (queryData = {}, config = {}) => {
        return get(`/v1/admin/appointment/`, queryData, config);
    }
    static create = (queryData = {}, config = {}) => {
        return post(`/v1/admin/appointment/`, queryData, config);
    }
    static update = (queryData = {}, config = {}) => {
        return patch(`/v1/admin/appointment/${queryData.id}/`, queryData, config);
    }
}
