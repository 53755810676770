import React, { useState, useEffect } from 'react';
import { Typography, Table, Avatar, Button, Radio, Icon } from 'antd';
import { isMobile } from 'react-device-detect';
import { resolve_server_image, user_fullname } from '../../../../app_methods';
import { useWindowDimensions, WindowSizes } from '../../../../utils/windowDimesions';

const Staff = (props) => {
    const { winsize } = useWindowDimensions();
    const [activeStaff, setActiveStaff] = useState({});
    useEffect(() => {
        if (props.staff.id) {
            setActiveStaff(props.staff);
        }
    }, []);
    const columns = [{
        title: 'Staff Name',
        key: 'name',
        render: (record) => {
            return (
                <div className="d-flex align-items-center">
                    <Avatar src={record.image ? resolve_server_image(record.image) : require('../../../../assets/images/default.png')} />
                    <Typography.Text className="ml-3">{user_fullname(record)}</Typography.Text>
                </div>
            )
        }
    }, {
        title: 'Action',
        key: 'action',
        render: (record) => {
            return (
                <Radio onClick={() => { setActiveStaff(record) }} checked={activeStaff.id == record.id} />
                // <Button onClick={props.selectStaff.bind(this, record)} type={props.staff.id == record.id ? "primary" : "default"} >Select Staff</Button>
            )
        }
    }];

    const renderMobileStaffView = () => {
        return (
            <div className="mobile-staff-list" style={styles.mobileStaffList}>
                {props.staffList.map((record) => {
                    return (
                        <div key={record.id}
                        // className={'service-row selected'}
                        className={record.id === activeStaff.id ? `service-row selected service-row-${record.id}` :
                        `service-row service-row-${record.id}`}
                        onClick={() => setActiveStaff(record)}
                        style={styles.staffCard}>
                            <div className="d-flex align-items-center">
                                <Avatar src={record.image ? resolve_server_image(record.image) : require('../../../../assets/images/default.png')} />
                                <Typography.Text className="ml-3">{user_fullname(record)}</Typography.Text>
                            </div>
                            {record.id === activeStaff.id &&
                                <div style={styles.checkBtn}>
                                    {/* <Button 
                                        type="primary"
                                        >
                                        
                                    </Button> */}
                                    <Icon type="check" />
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
        
    }

    return (
        <>
            <div className="booking-service-picker">
            <Typography.Text className="section-title-text">Choose Staff</Typography.Text>
            </div>
            {winsize >= WindowSizes.MD &&
            <div style={styles.desktopStaffList}>
                <Table className="mt-2" 
                    rowClassName={(record) =>  record.id === activeStaff.id ? 
                        `service-row selected service-row-${record.id}` :
                        `service-row service-row-${record.id}`
                    } 
                    scroll={{ x: isMobile ? true : false }}
                    columns={columns}
                    dataSource={props.staffList}
                    onRowClick={(record) => setActiveStaff(record)}
                    pagination={false} bordered={false} />
            </div>
            }
            {winsize < WindowSizes.MD &&
                renderMobileStaffView()
            }
            {!props.editView &&
                <>
                <div className="mt-4 float-left">
                    <Button
                        type="secondary"
                        onClick={() => props.goBack()}><Icon type="left" type="arrow-left" />Previous</Button>
                </div>
                
                <div className="mt-4 float-right">
                    <Button disabled={!activeStaff.id}
                        type="primary"
                        onClick={props.selectStaff.bind(this, activeStaff)}>Proceed<Icon type="right" type="arrow-right" /></Button>
                </div>
                </>
            }
            {props.editView &&
                <div className="mt-4 float-right">
                    <Button
                        type="primary"
                        onClick={() => {
                            props.goBackToConfirm(1, activeStaff)}
                        }><Icon type="left" type="rollback" /> Go Back</Button>
                </div>
            }
            <div className="clearfix"></div>
        </>
    )
}

const styles = {
    staffCard: {
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
        justifyContent: 'space-between'
    },
    checkBtn: {
        justifySelf: 'flex-end'
    },
    mobileStaffList: {
        height: "86%",
        minHeight: "86%",
        overflow: "auto"
    },
    desktopStaffList: {
        height: "66%",
        minHeight: "66%",
        overflow: "auto"
    }
}

export default Staff;