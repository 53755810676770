import React from 'react';
import { Menu, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionHoc } from '../../HOC/';
import './index.scss';
const PermissableMenu = PermissionHoc(Menu.Item, '', { is_component: true, fallbackComponent: null });
const SidebarMenuItem = ({ key, onClick, icon, label, permissions, ...rest }) => {
    return (
        <PermissableMenu {...rest} key={key} className="text-center" required_permission={permissions || []} onClick={onClick.bind(this)}>
            <Tooltip placement="right" title={label}>
                <div className="am-menu-item">
                    <FontAwesomeIcon icon={icon} style={{ fontSize: 24, minWidth: 56 }} color="grey" />
                </div>
            </Tooltip>
        </PermissableMenu>
    );
};

export default SidebarMenuItem;