import React, { useEffect, useState } from 'react';
import { Button, Card, Typography, Tag, Collapse, Form, Row, Col, Select } from 'antd';
import moment from 'moment/moment';
import { UserService, auth, AppointmentService, BusinessService, SessionService, QueueService } from '../../../services';
import { alertify, humanizeDate, titleCase, user_fullname, handleApiExceptions } from '../../../app_methods';
import { EaseModal } from '../../../libs/EaseModal';
import { defaultTableQuery } from '../../Common/models';
import { isMobile } from 'react-device-detect';
import { TableToolbar } from '../../Common';


export const SessionListMobile = (props) => {
  console.log('SessionListMobile', props);
  const { sessions, tableQuery, processing, handleFilterChange, filters } = props;

  return (
    <div style={{ overflow: 'auto' }}>
      {
        sessions.map(s =>
          <Card className="elevation1" style={{ marginBottom: 10, borderRadius: 8 }}>
            <div style={cardRow}>
              <div style={{ fontWeight: 600 }}>{titleCase(s.checkin_type)}</div>
              <div>{s.checkin_type === 'appointment' ? humanizeDate(s.scheduled_at) : humanizeDate(s.checkin_time)}</div>
            </div>
            <div style={{ ...cardRow, paddingBottom: 0 }}>
              <div>{s.is_cancelled ? 'Cancelled' : (s.status_name || "---")}</div>
              <Tag
                color={s.is_paid ? "green" : "red"}
                style={{ width: 80 }}
                className="text-center">{s.is_paid ? "Paid" : "Not Paid"}</Tag>
            </div>
          </Card>
        )
      }
    </div>
  );
};

const cardRow = { display: 'flex', justifyContent: 'space-between', width: '100%', paddingBottom: 16 };
const borderTop = { borderTop: '2px solid #cdcdcd' };