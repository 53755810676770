import React, { Component } from 'react';
import './index.scss';
import _isEmpty from 'lodash/isEmpty';
import { Tabs, Empty } from 'antd';
import { CustomerForm } from '../../Common/Forms';
import CustomerAppointments from './CustomerAppointments';
// import { Typography, Button, Popover, Input, List, Avatar } from 'antd';
// import { StaffForm } from '../../../Common/Forms';

class CustomerWizard extends Component {
    state = {};

    render() {
        if (_isEmpty(this.props.activeCustomer)) {
            return (
                <Empty description="No customer found." />
            );
        }
        const formComponents = [
            {
                label: "Profile",
                component: (
                    <CustomerForm
                        afterSubmit={this.props.fetchCustomerList}
                        customer={{ ...this.props.activeCustomer, is_edit: true }} />)
            },
            {
                label: "Appointments",
                component: (
                    <CustomerAppointments
                        customer={this.props.activeCustomer}
                        appointments={this.props.appointments}
                        fetchAppointments={this.props.fetchAppointments} />)
            },
        ];

        return (
            <div>
                <Tabs defaultActiveKey="0" onChange={() => { }} animated={false}>
                    {
                        formComponents.map((tab, index) => {
                            return <Tabs.TabPane tab={tab.label} key={index}>{tab.component}</Tabs.TabPane>;
                        })
                    }
                </Tabs>
            </div>
        );
    }
}

export default CustomerWizard;