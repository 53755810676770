import React, { useState } from 'react';
import moment from 'moment/moment';
import { Typography, Table, Avatar, Button, DatePicker, TimePicker, Icon, Form, Input, Select, Divider } from 'antd';
import { NumericInput } from '../../../Common/NumericInput';
import { useWindowDimensions, WindowSizes } from '../../../../utils/windowDimesions';

const CustomerInfo = (props) => {
    const { winsize } = useWindowDimensions();
    const { getFieldDecorator } = props.form;

    const formCardStyles = winsize < WindowSizes.MD ? styles.mobileCustFormDiv : styles.desktopCustFormDiv;
    const formStyles = winsize < WindowSizes.MD ? styles.mobileForm : styles.desktopForm;

    return (
        <>
            <div className="booking-service-customer">
            <Typography.Text className="section-title-text">Customer Information</Typography.Text>
            </div>
            <Form layout="vertical" onSubmit={props.setCustomer.bind(this, props.form)} style={formStyles}>
            <div className="mx-auto mt-4 p-relative customer-form-wrapper" style={formCardStyles}>
                
                    {/* {getFieldDecorator('customer.merchant', { initialValue: auth.user.merchant.id })(
                        <Input hidden />
                    )}
                    <Form.Item className="text-center">
                        {getFieldDecorator('customer.avatar', {})(
                            <Avatar style={{ width: 120, height: 120 }} />
                        )}
                    </Form.Item> */}
                    <div className="d-flex">
                        <Form.Item label="First Name" className="w-50">
                            {getFieldDecorator('customer.first_name', {
                                initialValue: props.customer.first_name,
                                rules: [{
                                    required: true, message: 'Please input first name.',
                                }]
                            })(
                                <Input prefix={<Icon type="user" />} disabled={props.processing} placeholder="First name" />
                            )}
                        </Form.Item>
                        <Form.Item label="Last Name" className="ml-1 w-50">
                            {getFieldDecorator('customer.last_name', {
                                initialValue: props.customer.last_name,
                                rules: [{
                                    required: true, message: 'Please input last name.',
                                }]
                            })(
                                <Input prefix={<Icon type="user" />} disabled={props.processing} placeholder="Last name" />
                            )}
                        </Form.Item>
                    </div>
                    <Form.Item label="Email" >
                        {getFieldDecorator('customer.email', {
                            initialValue: props.customer.email,
                            rules: [
                                { required: true, message: 'Please input email.' },
                                {
                                    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                                    message: "Email is not a valid format."
                                }
                            ]
                        })(
                            <Input prefix={<Icon type="mail" />} disabled={props.processing} placeholder="Email Address" />
                        )}
                    </Form.Item>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <Form.Item label="Country Code" >
                            {getFieldDecorator('customer.country_code', {
                                initialValue: props.customer.country_code,
                                rules: [
                                    { required: true, message: 'Please select country code.' },
                                ]
                            })(
                                <Select
                                    disabled={props.processing}
                                    style={{ width: 120 }}>
                                    {
                                        props.countryCodes &&
                                        props.countryCodes.length > 0 &&
                                        props.countryCodes.map(p => (
                                            <Select.Option key={p.id} value={p.phonecode}>{`${p.iso} (+${p.phonecode})`}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Mobile Number" className="ml-1">
                            {getFieldDecorator('customer.phone', {
                                initialValue: props.customer.phone,
                                rules: [
                                    { required: true, message: 'Please input mobile number.' },
                                    { pattern: /^[0-9]\w{9}\b$/, message: "Please input valid mobile number." }
                                ]
                            })(
                                <NumericInput prefix={<Icon type="mobile" />}
                                    disabled={props.processing}
                                    maxLength={10}
                                    placeholder="Mobile Number" />
                            )}
                        </Form.Item>
                    </div>
                    <Form.Item label="Additional Information" >
                        {getFieldDecorator('customer.note', {
                            initialValue: props.customer.note,
                        })(
                            <Input.TextArea disabled={props.processing} placeholder="Please enter additional information if any." />
                        )}
                    </Form.Item>

                    
                
                </div>
                {!props.editView &&
                    <>
                    <div className="mt-4 float-left">
                        <Button
                            type="secondary"
                            onClick={() => props.goBack()}><Icon type="left" type="arrow-left" />Previous</Button>
                    </div>
                    <div className="mt-4 float-right">
                        <Form.Item >
                            <Button
                                loading={props.processing}
                                htmlType="submit"
                                type="primary">Proceed<Icon type="arrow-right" /></Button>
                        </Form.Item>
                    </div>  
                    </>      
                }
                {props.editView &&
                    <div className="mt-4 float-right">
                        <Form.Item >
                            <Button
                                loading={props.processing}
                                htmlType="submit"
                                type="primary"><Icon type="left" type="rollback" /> Go Back</Button>
                        </Form.Item>
                        {/* <Button
                            type="secondary"
                            onClick={() => props.goBackToConfirm()}><Icon type="left" type="rollback" /> Go Back</Button> */}
                    </div>
                }
        
                <div className="clearfix"></div>
                
            </Form>
        </>
    );
};

const styles = {
    mobileForm: {
        height: "95%"
    },
    desktopForm: {
        height: "95%"
    },
    mobileCustFormDiv: {
        height: "86%",
        minHeight: "86%",
        overflow: "auto",
    },
    desktopCustFormDiv: {
        height: "66%",
        minHeight: "66%",
        overflow: "auto",
        marginTop: "1.5rem",
        width: "50%"
    }
}

const FormWrapper = Form.create()(CustomerInfo);
export default FormWrapper;