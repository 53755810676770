import React, { Component } from 'react';
import './index.scss';
import { Typography, Button, Table } from 'antd';
import { humanizeDate, user_fullname } from '../../../app_methods';
import { isMobile } from 'react-device-detect';
import { EaseModal } from '../../../libs/EaseModal';
import { SessionForm } from '../../Common';

class CustomerAppointments extends Component {
    columns = [
        {
            title: '#',
            key: "index",
            render: (value, record, index) => `#${index + 1}`
        },
        {
            title: 'Schedule',
            key: "schedule",
            render: (record) => <div>{humanizeDate(record.scheduled_at)}</div>
        }, {
            title: 'Service',
            key: 'day',
            render: (record) => (
                <div className="d-flex flex-column">
                    {record.services_requested.map((sr, i) => {
                        return (
                            <div key={sr.id}>
                                <div>{sr.service.name}</div>
                                <Typography.Text type="secondary">{sr.service.description || "---"}</Typography.Text>
                            </div>
                        );
                    })}

                </div>
            )
        },
        {
            title: 'Provider',
            key: "provider",
            render: (record) => (
                <div className="d-flex flex-column">
                    <div>{user_fullname(record.services_requested[0].staff_obj)}</div>
                    <Typography.Text type="secondary">{record.services_requested[0].staff_obj.email || "---"}</Typography.Text>
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: "status_name"
        },
        {
            title: 'Action',
            key: "action",
            render: (record) => <Button icon="edit" onClick={() => this.toggleAppointmentModal(record, true)}>Edit</Button>
        }];

    state = {
        activeAppointment: {},
        appointmentModal: false
    };

    toggleAppointmentModal = (appointment, is_edit) => {
        this.setState({
            appointmentModal: !this.state.appointmentModal,
            activeAppointment: { ...appointment, is_edit },
        });
    };

    render() {
        const { props } = this;
        return (
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <Typography.Title level={4}>{props.customer.first_name} {props.customer.last_name}'s Appointments</Typography.Title>
                    <Button icon="plus" onClick={() => this.toggleAppointmentModal({}, false)}>Set Appointment</Button>
                </div>
                <div className="mt-3">
                    <Table
                        rowKey="id"
                        columns={this.columns}
                        dataSource={this.props.appointments}
                        scroll={{ x: isMobile ? true : false }} />
                </div>
                <EaseModal
                    title="Appointment"
                    hideFooter
                    visible={this.state.appointmentModal}
                    onCancel={() => this.toggleAppointmentModal({}, false)}
                >
                    <SessionForm
                        sessionType={'appointment'}
                        disableCustomer
                        defaultCustomer={props.customer.id}
                        session={this.state.activeAppointment}
                        afterSubmit={() => {
                            this.props.fetchAppointments(props.customer.id);
                            this.toggleAppointmentModal();
                        }} />
                </EaseModal>
            </div>
        );
    }
}

CustomerAppointments.defaultProps = {
    customer: {}
};

export default CustomerAppointments;