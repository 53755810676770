import React, { useEffect, useState } from 'react';
import { Button, Table, Typography, Tag, Collapse, Form, Row, Col, Select } from 'antd';
import moment from 'moment/moment';
import { UserService, auth, AppointmentService, BusinessService, SessionService, QueueService } from '../../../services';
import { alertify, humanizeDate, titleCase, user_fullname, handleApiExceptions } from '../../../app_methods';
import { EaseModal } from '../../../libs/EaseModal';
import { defaultTableQuery } from '../../Common/models';
import { isMobile } from 'react-device-detect';
import { TableToolbar } from '../../Common';


export const SessionListWeb = (props) => {
  const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 10 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 14 }, }, };
  const columns = [
    {
      title: 'No.',
      key: "index",
      render: (text, record, index) => {
        return (<Typography.Text >{index + 1}</Typography.Text>);
      }
    },
    {
      title: 'Service',
      key: 'service',
      render: (record) => <div>{record.service.name}</div>
    },
    {
      title: 'Description',
      key: 'desc',
      render: (record) => (
        <Typography.Text type="secondary">{record.service.description || "---"}</Typography.Text>
      )
    },
    {
      title: 'Staff',
      key: 'staff',
      render: (record) => (
        <Typography.Text type="secondary">{user_fullname(record.staff_obj)}</Typography.Text>
      )
    },
    {
      title: 'Amount',
      key: "amount",
      render: (record) => <Typography.Text >{record.service.sell_price}</Typography.Text>
    }
  ];
  const tableStyle = { border: '1px solid #e8e8e8', borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomWidth: 0, backgroundColor: '#fff' };
  const expandedRowRender = (record) => {
    return <div key={record.id}>
      {
        record.services_requested &&
        record.services_requested.length > 0 &&
        <>
          <div style={{ fontWeight: 600, padding: '8px 0', display: 'flex', justifyContent: 'space-between' }}>
            <div>Services Requested</div>
            {
              record.scheduled_at &&
              <div style={{ fontWeight: 600 }}>
                Scheduled At: <span style={{ padding: '0 8px 0 16px' }}>{humanizeDate(record.scheduled_at)}</span>
              </div>
            }
            {
              record.checkin_type === 'walkin' &&
              record.checkin_time &&
              <div style={{ fontWeight: 600 }}>
                Checked In At: <span style={{ padding: '0 8px 0 16px' }}>{humanizeDate(record.checkin_time)}</span>
              </div>
            }
          </div>
          <Table
            columns={columns}
            pagination={false}
            dataSource={record.services_requested}
            style={tableStyle}
            scroll={{ x: isMobile ? true : false }} />
        </>
      }
      {record.services_provided &&
        record.services_provided.length > 0 &&
        <>
          <div style={{ fontWeight: 600, padding: '16px 0 8px 0' }}>Services Provided</div>
          <Table
            columns={columns}
            pagination={false}
            dataSource={record.services_provided}
            style={tableStyle}
            scroll={{ x: isMobile ? true : false }} />
        </>
      }
    </div>;
  };

  const { tableQuery, processing, handleFilterChange, filters } = props;
  return (
    <>
      <Collapse defaultActiveKey={['0']} style={{ marginBottom: 10 }}>
        <Collapse.Panel header="Filters" key="1">
          <Row style={{ maxWidth: '1280px' }} key="r1">
            <Col span={6} key={'c1'}>
              <Form
                {...formItemLayout}
                className="w-100">
                {/* <Row> */}
                {/* <div className="item-col"> */}
                <Form.Item label="Checkin Type">
                  <Select
                    allowClear
                    size="default"
                    placeholder="All"
                    style={{ maxWidth: '12rem' }}
                    value={filters.checkin_type}
                    disabled={false}
                    readonly={false}
                    onChange={(e) => handleFilterChange('checkin_type', e)}>
                    <Select.Option key={'appointment'} value={'appointment'}>Appointment</Select.Option>
                    <Select.Option key={'walkin'} value={'walkin'}>Walkin</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <Col span={8} key={'c2'}>
              <Form
                {...formItemLayout}
                className="w-100">
                <Form.Item label="Paid">
                  <Select
                    allowClear
                    size="default"
                    placeholder="All"
                    style={{ maxWidth: '12rem' }}
                    value={filters.is_paid}
                    onChange={(e) => handleFilterChange('is_paid', e)}>
                    <Select.Option key={1} value={'true'}>Paid</Select.Option>
                    <Select.Option key={2} value={'false'}>Not Paid</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row style={{ maxWidth: '1280px' }} key="r2">
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={props.applyFilters}>
                Apply
            </Button>
              <Button style={{ marginLeft: 8 }} onClick={props.handleResetFilters}>
                Clear
            </Button>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <TableToolbar onRefresh={props.fetchSessions} onSearch={(value) => props.setSearchTerm(value)} />
      <Table
        expandedRowRender={expandedRowRender}
        loading={props.fetchingData}
        style={{ border: '1px solid #e8e8e8', borderRadius: 4, backgroundColor: '#fff' }}
        pagination={{ style: { marginRight: 16 } }}
        columns={[
          {
            title: 'Checkin Type',
            key: "checkin_type",
            render: (record) =>
              <Typography.Text >{titleCase(record.checkin_type)}</Typography.Text>
          },
          {
            title: 'Status',
            key: "status_name",
            render: (record) => <Typography.Text>{record.is_cancelled ? 'Cancelled' : (record.status_name || "---")}</Typography.Text>
          },
          {
            title: 'Payment',
            key: "payment",
            render: (record) =>
              <Tag
                color={record.is_paid ? "green" : "red"}
                style={{ width: 80 }}
                className="text-center">{record.is_paid ? "Paid" : "Not Paid"}</Tag>
          },
          {
            title: 'Action',
            key: "action",
            render: (record) => {
              if (record.checkin_type === 'appointment' && !record.is_cancelled) {
                return <Button
                  key={`btn-${record.id}`}
                  type="danger"
                  size="small"
                  loading={processing}
                  onClick={() => props.cancelSession(record)}>Cancel</Button>;
              }
              return '---';
            }
          }
        ]}
        pagination={{
          style: { marginRight: 16 },
          onChange: (page, size) => props.setTableQuery({ ...tableQuery, page }),
          defaultCurrent: 1,
          current: tableQuery.page,
          total: tableQuery.total,
          pageSize: tableQuery.page_size,
        }}
        dataSource={props.sessions}
        scroll={{ x: isMobile ? true : false }} />
    </>
  );
};