import React, { useState, useEffect } from 'react';
import { Typography, Table, Avatar, Button, Radio, Icon, Form } from 'antd';
import { isMobile } from 'react-device-detect';
import { resolve_server_image } from '../../../../app_methods';
import { useWindowDimensions, WindowSizes } from '../../../../utils/windowDimesions';

const Service = (props) => {
    const { winsize } = useWindowDimensions();
    console.log("winsize", winsize);

    const [activeService, setActiveService] = useState({});
    useEffect(() => {
        if (props.service.id) {
            setActiveService(props.service);
        }
    }, [props.service]);
    const columns = [{
        title: 'Service',
        key: 'name',
        width: "30%",
        render: (record) => {
            return (
                <div className="d-flex">
                    <div>
                        <Avatar src={record.service_image ? resolve_server_image(record.service_image) : require('../../../../assets/images/default.png')} />
                    </div>
                    <div className="d-flex flex-column align-items-start ml-2">
                        <Typography.Text>{record.name}</Typography.Text>
                        <Typography.Text type="secondary">{record.description}</Typography.Text>
                    </div>
                </div>
            )
        }
    }, {
        title: 'Amount',
        dataIndex: 'sell_price',
        key: 'sell_price',
    }, {
        title: 'Duration',
        key: 'service_minutes',
        render: (record) => <span>{record.service_minutes} minutes</span>
    }, {
        title: 'Action',
        key: 'action',
        width: "20%",
        render: (record) => {
            return (
                <Radio onClick={() => { setActiveService(record) }} checked={activeService.id === record.id} />
                // <Button onClick={props.selectService.bind(this, record)} type={props.service.id == record.id ? "primary" : "default"}>Select Service</Button>
            )
        }
    }];

    const renderMobileServiceView = () => {
        return (
            <div className="mobile-services-list">
                {props.services.map((record) => {
                    return (
                        <div className={(record) =>  
                            record.id === activeService.id ? `service-row selected service-row-${record.id}` :
                            `service-row service-row-${record.id}`
                        } onClick={(record) => setActiveService(record)}>
                            <div>
                                <Avatar src={record.service_image ? resolve_server_image(record.service_image) : require('../../../../assets/images/default.png')} />
                            </div>
                            <div className="d-flex flex-column align-items-start ml-2">
                                <Typography.Text>{record.name}</Typography.Text>
                                <Typography.Text type="secondary">{record.description}</Typography.Text>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
        
    }

    return (
        <>
            <div className="booking-service-picker test">
                <Typography.Title level={4}>Choose Service</Typography.Title>
            </div>
            {winsize >= WindowSizes.LG &&
                <Table rowClassName={(record) =>  
                    record.id === activeService.id ? `service-row selected service-row-${record.id}` :
                    `service-row service-row-${record.id}`
                    } 
                    className="mt-2"
                    scroll={{ x: isMobile ? true : false }}
                    columns={columns}
                    dataSource={props.services}
                    onRowClick={(record) => setActiveService(record)}
                    pagination={false}
                    bordered={false} />
            }
            {winsize < WindowSizes.LG &&
                this.renderMobileServiceView()
            }
            
            <div className="mt-4 float-right">
                <Button 
                    disabled={!activeService.id}
                    type="primary"
                    onClick={props.selectService.bind(this, activeService)}
                    >Proceed
                    <Icon type="right" type="arrow-right" />
                </Button>
            </div>
            <div className="clearfix"></div>
        </>
    )
}

export default Service;