import React, { Component } from 'react';
import { Form, Steps, Typography, Tag } from 'antd';
import moment from 'moment/moment';
import _isEmpty from 'lodash/isEmpty';
import { BusinessService, BreakHourService, WorkingHourService, AppointmentService, auth, OTPService, HolidayService, CountryCodeService, UserService } from '../../../../services';
import { handleApiExceptions, alertify, amAlert, user_fullname } from '../../../../app_methods';
import Service from './Service';
import Staff from './Staff';
import Scheduler from './Scheduler';
import CustomerInfo from './CustomerInfo';
import Confirmation from './Confirmation';
import { isMobile } from 'react-device-detect';

class CustomerBookingForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            staffList: [],
            workHours: [],
            breakHours: [],
            currentStep: 0,
            selectedService: {},
            selectedStaff: {},
            selectedDate: this.initDate(),
            selectedTime: undefined,
            selectedSchedule: "",
            customer: {},
            staffAppointmentForSelectedDate: [],
            holidays: [],
        };
    }

    initDate = () => {
        const { business_hours } = auth.user.merchant_settings;
        if (business_hours) {
            if (moment(business_hours.end, "HH:mm").isBefore(moment())) {
                return moment().add(1, 'day').startOf('day').format("YYYY-MM-DD");
            }
        }
        return moment().format("YYYY-MM-DD");
    };

    componentDidMount() {
        this.fetchServices();
        this.fetchHolidays();
        this.fetchAppointments();
        if (auth.user) {
            this.setState({ customer: auth.user });
        }
    }

    componentDidUpdate() {
        console.log('customer', this.state.customer);
    }

    fetchAppointments = () => {
        AppointmentService.list({
            scheduled_at__gte: moment(this.state.selectedDate).startOf('day').format("YYYY-MM-DD HH:mm"),
            scheduled_at__lte: moment(this.state.selectedDate).endOf('day').format("YYYY-MM-DD HH:mm"),
            staff: this.state.selectedStaff.id
        }).then((response) => {
            if (response.success) {
                this.setState({ staffAppointmentForSelectedDate: response.data.results });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchServices = () => {
        BusinessService.list({ merchant: this.props.merchant.id }).then((response) => {
            if (response.success) {
                const services = response.data.map(s => {
                    s.key = String(s.id);
                    return s;
                });
                this.setState({ services });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchServiceUsers = (service_id) => {
        BusinessService.getUsers({ id: service_id }).then((response) => {
            if (response.success) {
                this.setState({ staffList: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchStaffBreakHours = (id) => {
        BreakHourService.list({ staff: id }).then((response) => {
            if (response.success) {
                this.setState({ breakHours: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchStaffWorkingHours = (id) => {
        WorkingHourService.list({ staff: id }).then((response) => {
            if (response.success) {
                this.setState({ workHours: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    fetchHolidays = () => {
        HolidayService.list({ merchant: this.props.merchant.id }).then((response) => {
            if (response.success) {
                this.setState({ holidays: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    setCustomer = (form, e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) { return; }
            this.setState({
                customer: { ...values.customer },
                currentStep: this.state.currentStep + 1
            }, () => {
                this.sendOtp(this.state.customer.phone);
            });
        });

    };


    bookAppointment = (form) => {
        const note = this.state.customer.note || "CREATED BY CUSTOMER";
        delete this.state.customer.note;
        const statuses = auth.user.merchant.appointment_status;
        console.log('statuses', statuses);
        const status = statuses.filter(s => s.key === 'pending')[0];
        const payload = {
            merchant: this.props.merchant.id,
            services_requested: [
                {
                    staff: this.state.selectedStaff.id,
                    service_id: this.state.selectedService.id,
                    note: note
                }
            ],
            // service: this.state.selectedService.id,
            staff: this.state.selectedStaff.id,
            customer_info: this.state.customer,
            scheduled_at: this.state.selectedSchedule,
            amount: this.state.selectedService.sell_price,
            service_minutes: this.state.selectedService.service_minutes,
            note: note,
            created_by: 1,
            status: status.id
        };

        AppointmentService.create(payload).then((response) => {
            if (response.success) {
                amAlert('Awesome!!!', "Your appointment is booked successfully. You will receive an email & SMS regarding your appointment confirmation.", "success", () => { window.location.reload(); });
            }
            else {
                alertify("Oops!!!", response.message, "error");
                this.setState({ processing: false });
            }
        }).catch((err) => {
            if (err && err.response && err.response.status) {
                if (err.response.data && err.response.data.message && err.response.data.message.availability_status) {
                    if (!err.response.data.message.availability_status.available) {
                        amAlert('Oops!!!', "Staff is not available on selected hours.", "warning");
                    }
                }
            } else {
                handleApiExceptions(err);
            }
            this.setState({ processing: false });
        });
    };

    selectService = (service) => {
        this.setState({
            selectedService: service,
            selectedStaff: {},
            currentStep: this.state.currentStep + 1
        }, () => {
            this.fetchServiceUsers(service.id);
        });
    };

    selectStaff = (staff) => {
        this.setState({
            selectedStaff: staff,
            currentStep: this.state.currentStep + 1
        }, () => {
            this.fetchStaffWorkingHours(staff.id);
            this.fetchStaffBreakHours(staff.id);
        });
    };

    selectDate = (date) => {
        this.setState({
            selectedTime: undefined,
            selectedDate: date ? date.format("YYYY-MM-DD") : undefined
        }, () => date ? this.fetchAppointments() : null);
    };

    selectTime = (time, a, b) => {
        this.setState({
            selectedTime: time.format("HH:mm")
        });
    };

    selectSchedule = () => {
        if (!this.state.selectedDate || !this.state.selectedTime) {
            alertify("Oops!!!", "Select date & time of appointment to proceed.", "warning");
            return;
        }
        const schedule = `${this.state.selectedDate} ${this.state.selectedTime}`;
        this.setState({
            selectedSchedule: schedule,
            currentStep: this.state.currentStep + 1
        });
    };

    goToStep = (step) => {
        if (step < this.state.currentStep) {
            this.setState({
                currentStep: step,
                selectedDate: step < 2 ? undefined : this.state.selectedDate,
                selectedTime: step < 2 ? undefined : this.state.selectedTime,
            });
        }
    };

    renderStepDescription = (description, value) => {
        return (
            <div className="stepper-content">
                <span>{description}</span>
                {
                    (!value) ? null :
                        <div style={{ paddingLeft: 14 }}>
                            <Tag color="green" className="mt-1">{value}</Tag>
                        </div>
                }
            </div>
        );
    };

    render() {
        const formComponents = {
            0: <Service
                service={this.state.selectedService}
                merchant={this.props.merchant}
                services={this.state.services}
                selectService={this.selectService}
            />,
            1: <Staff
                merchant={this.props.merchant}
                staffList={this.state.staffList}
                selectStaff={this.selectStaff}
                staff={this.state.selectedStaff}
            />,
            2: <Scheduler
                merchant={this.props.merchant}
                workHours={this.state.workHours}
                businessHours={auth.user.merchant_settings.business_hours}
                breakHours={this.state.breakHours}
                selectDate={this.selectDate}
                selectTime={this.selectTime}
                selectSchedule={this.selectSchedule}
                selectedDate={this.state.selectedDate}
                selectedTime={this.state.selectedTime}
                selectedService={this.state.selectedService}
                staffAppointmentForSelectedDate={this.state.staffAppointmentForSelectedDate}
                holidays={this.state.holidays}
            />,
            3: <Confirmation
                merchant={this.props.merchant}
                service={this.state.selectedService}
                staff={this.state.selectedStaff}
                schedule={this.state.selectedSchedule}
                customer={this.state.customer}
                bookAppointment={this.bookAppointment}
                verifyOtp={this.verifyOtp}
                sendOtp={this.sendOtp}
            />
        };

        return (
            <div className="w-100">
                <Typography.Text className="mb-3 d-block text-center"
                    type="danger" strong
                    style={{ width: "100%", fontSize: 16, margin: 'auto' }}>Booking Workflow:</Typography.Text>
                <Steps progressDot current={this.state.currentStep} direction={isMobile ? 'vertical' : 'horizontal'}>
                    <Steps.Step className="c-pointer"
                        onClick={() => this.goToStep(0)}
                        title="Service"
                        description={this.renderStepDescription("Select service you want to take.", this.state.selectedService.name)} />
                    <Steps.Step className="c-pointer"
                        onClick={() => this.goToStep(1)}
                        title="Staff"
                        description={this.renderStepDescription("Select service provider from the list.", user_fullname(this.state.selectedStaff))} />
                    <Steps.Step className="c-pointer"
                        onClick={() => this.goToStep(2)}
                        title="Date"
                        description={this.renderStepDescription("Pick appointment date & time.",
                            !this.state.selectedSchedule ? "" : moment(this.state.selectedSchedule).format("LLLL"))} />
                    <Steps.Step className="c-pointer"
                        onClick={() => this.goToStep(3)}
                        title="Review & Confirm"
                        description="Review & confirm your appointment." />
                </Steps>
                <div className="step-component mt-5 mx-auto" style={{ width: "95%" }}>
                    {formComponents[this.state.currentStep]}
                </div>
            </div>
        );
    }
};

CustomerBookingForm.defaultProps = {
    afterSubmit: () => { }
};

const FormWrapper = Form.create()(CustomerBookingForm);
export default FormWrapper;