import React, { useState, useEffect } from 'react';
import { Typography, Table, Avatar, Button, Radio, Icon } from 'antd';
import { isMobile } from 'react-device-detect';
import { resolve_server_image, user_fullname } from '../../../../app_methods';

const Staff = (props) => {
    const [activeStaff, setActiveStaff] = useState({});
    useEffect(() => {
        if (props.staff.id) {
            setActiveStaff(props.staff);
        }
    }, []);
    const columns = [{
        title: 'Staff Name',
        key: 'name',
        render: (record) => {
            return (
                <div className="d-flex align-items-center">
                    <Avatar src={record.image ? resolve_server_image(record.image) : require('../../../../assets/images/default.png')} />
                    <Typography.Text className="ml-3">{user_fullname(record)}</Typography.Text>
                </div>
            )
        }
    }, {
        title: 'Action',
        key: 'action',
        render: (record) => {
            return (
                <Radio onClick={() => { setActiveStaff(record) }} checked={activeStaff.id == record.id} />
                // <Button onClick={props.selectStaff.bind(this, record)} type={props.staff.id == record.id ? "primary" : "default"} >Select Staff</Button>
            )
        }
    }];
    return (
        <>
            <div className="booking-service-picker">
                <Typography.Title level={4}>Choose Staff</Typography.Title>
            </div>

            <Table className="mt-2" 
                rowClassName={(record) =>  record.id === activeStaff.id ? 
                    `service-row selected service-row-${record.id}` :
                    `service-row service-row-${record.id}`
                } 
                scroll={{ x: isMobile ? true : false }}
                columns={columns}
                dataSource={props.staffList}
                onRowClick={(record) => setActiveStaff(record)}
                pagination={false} bordered={false} />
            <div className="mt-4 float-right">
                <Button disabled={!activeStaff.id}
                    type="primary"
                    onClick={props.selectStaff.bind(this, activeStaff)}>Proceed<Icon type="right" type="arrow-right" /></Button>
            </div>
            <div className="clearfix"></div>
        </>
    )
}

export default Staff;