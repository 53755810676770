import { get, post, patch, httpDelete } from '../HttpService';

export class QueueService {
    static list = (queryData = {}, config = {}) => {
        return get(`/v1/admin/daily_queue/`, queryData, config);
    }
    static create = (queryData = {}, config = {}) => {
        return post(`/v1/admin/daily_queue/`, queryData, config);
    }
    static delete = (queryData = {}, config = {}) => {
        return httpDelete(`/v1/admin/daily_queue/${queryData.id}/`, config);
    }
}
