import React, { useState, useEffect } from 'react';
import { Form, DatePicker, Select, Input, Divider, Button, Typography, Checkbox, Popover } from 'antd';
import moment from 'moment/moment';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons/faRupeeSign';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { UserService, BusinessService, auth, AppointmentService, BreakHourService, WorkingHourService, HolidayService, SessionService } from '../../../services';
import { handleApiExceptions, user_fullname, alertify, get_disabled_date, amAlert, get_unavailable_hours, get_unavailable_dates, getCustomerSearchType } from '../../../app_methods';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EaseModal } from '../../../libs/EaseModal';
import { TimePicker, CustomerModalForm } from '../index';

const ServiceProvided = (props) => {
  console.log('ServiceProvided props', props);
  const [selectedService, setSelectedService] = useState();
  const [selectedStaff, setSelectedStaff] = useState();
  const [serviceList, setServiceList] = useState([]);
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    fetchServices();
  }, []);

  const handleServiceChange = (value) => {
    const s = serviceList.find(x => x.id == value) || {};
    setSelectedService(s);
    return value;
  };

  const handleStaffChange = (value) => {
    const s = staffList.find(x => x.id == value) || {};
    setSelectedStaff(s);
    return value;
  };

  const fetchServices = () => {
    const filters = {};
    if (auth.is_staff()) {
      filters['users__id'] = auth.user.id;
    }
    BusinessService.list({ merchant: auth.user.merchant.id, ...filters }).then((response) => {
      if (response.success) {
        setServiceList(response.data);
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  };

  const fetchServiceUsers = (service_id) => {
    BusinessService.getUsers({ id: service_id }).then((response) => {
      if (response.success) {
        setStaffList(response.data);
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  };

  const onSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) {
        console.log(err);
        return;
      }
      const data = {
        service: selectedService,
        staff: selectedStaff.id,
        staff_obj: selectedStaff
      };
      props.onSubmit(data);
    });
  };

  const { getFieldDecorator } = props.form;
  const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 5 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 19 }, }, };

  return (
    <div>
      <Form {...formItemLayout} layout="horizontal" className="w-100">
        <Form.Item label="Service">
          {
            getFieldDecorator('selectedService', {
              initialValue: null,
              getValueFromEvent: handleServiceChange,
              rules: [
                { required: true, message: "Please select a service." }
              ]
            })(
              <Select
                size="default"
                placeholder="Select Service"
                style={{ width: '100%' }}
                onChange={(e) => {
                  fetchServiceUsers(e);
                  setSelectedStaff(undefined);
                }}>
                {
                  serviceList.map((service, index) => (
                    <Select.Option key={index} value={service.id}>{service.name}</Select.Option>
                  ))
                }
              </Select>
            )}
        </Form.Item>
        <Form.Item label="Provider" className="w-100" >
          {
            getFieldDecorator('selectedStaff', {
              initialValue: null,
              rules: [
                { required: true, message: "Please select a provider." }
              ]
            })(
              <Select
                size="default"
                placeholder="Select Provider"
                style={{ width: '100%' }}
                onChange={handleStaffChange}
                notFoundContent={
                  <Typography.Text type="danger">Select Service to fetch service providers.</Typography.Text>
                }>
                {
                  staffList.map((user, index) => (
                    <Select.Option key={index} value={user.id}>{user_fullname(user)}</Select.Option>
                  ))
                }
              </Select>
            )}
        </Form.Item>
        <div style={{ marginTop: 10 }} class="text-center w-100" >
          <Button type="primary"
            onClick={onSubmit}>Add</Button>
        </div>
      </Form >
    </div>
  );
};

ServiceProvided.defaultProps = {
  onSubmit: () => { }
};

const FormWrapper = Form.create()(ServiceProvided);
export default FormWrapper;
// export default ServiceProvided;