import React, { useState } from 'react';
import { Typography, Button, Icon, Popconfirm, Input } from 'antd';
import { humanizeDate, user_fullname } from '../../../../app_methods';
import moment from 'moment/moment';

const Confirmation = (props) => {
    const [otp_input, setOtpInput] = useState("");
    return (
        <>
            <div className="booking-service-confirmation">
                <Typography.Title level={4}>Review & Confirm Appointment</Typography.Title>
            </div>

            <div className="mt-4 p-relative">
                <div className="row">
                    <div className="col-sm border-right confirmation-left">
                        <div className="d-table">
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2" style={{ minWidth: 100 }}>Service:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{props.service.name}</Typography.Text>
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Service Detail:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{props.service.description || "---"}</Typography.Text>
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Service Provider:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{user_fullname(props.staff) || "---"}</Typography.Text>
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Schedule:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{moment(props.schedule).format("LLLL")}</Typography.Text>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="d-table">
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2" style={{ minWidth: 120 }}>Customer Name:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{user_fullname(props.customer)}</Typography.Text>
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Email:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{props.customer.email}</Typography.Text>
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Mobile:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{props.customer.phone}</Typography.Text>
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Additional Notes:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{props.customer.note || "---"}</Typography.Text>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm p-2">
                        <div className="d-flex align-items-center otp-input-wrapper justify-content-center">
                            <Button
                                className="mr-2"
                                type="primary"
                                onClick={() => props.bookAppointment()}>Confirm & Book Appointment</Button>
                        </div>
                    </div>
                </div>
                <div style={{ position: "absolute", bottom: 30, right: 30 }} className="confirmation-btn">
                    <div className="d-flex align-items-center">
                        <Typography.Text type="danger" className="d-block mr-3" style={{ fontSize: 16 }} >Click on the <b>Booking Workflow</b> steps to edit any information.</Typography.Text>
                        {/* <Popconfirm okText="Confirm" onConfirm={props.bookAppointment} title="Re-check all the information before proceeding.">
                            <Button type="primary">Confirm & Book<Icon type="right" type="arrow-right" /></Button>
                        </Popconfirm> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Confirmation;