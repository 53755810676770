import { post } from '../HttpService';

export class OTPService {
    static generate = (queryData = {}, config = {}) => {
        return post(`/v1/admin/services/send_otp/`, queryData, config);
    }
    static verify = (queryData = {}, config = {}) => {
        return post(`/v1/admin/services/verify_otp/`, queryData, config);
    }
}
