import React, { Component } from 'react';
import { EaseDrawer } from '../../../../libs/EaseDrawer';
import { Typography, List, Avatar, Button, Switch, Icon } from 'antd';
import data from '../../../data.json';
import { isMobile } from 'react-device-detect';
import { BusinessService, UserService } from '../../../../services';
import { handleApiExceptions } from '../../../../app_methods';
class ServiceAssignDrawer extends Component {
    state = {
        serviceUsers: [],
        staffList: [],
        processing: false
    };

    fetchServiceUsers() {
        BusinessService.getUsers({ id: this.props.service.id }).then((response) => {
            if (response.success) {
                this.setState({ serviceUsers: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    }

    fetchStaffList() {
        UserService.getStaffList().then((response) => {
            if (response.success) {
                this.setState({ staffList: response.data.results }, () => {
                    this.fetchServiceUsers();
                });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    }

    updateServiceUsers(user_id, selected) {
        this.setState({ processing: true });
        const service_users = this.state.serviceUsers.map(x => x.id);
        selected ? service_users.push(user_id) : service_users.splice(service_users.indexOf(user_id), 1);
        BusinessService.setUsers({ id: this.props.service.id, user_ids: service_users }).then((response) => {
            if (response.success) {
                this.fetchStaffList();
            }
            this.setState({ processing: false });
        }).catch((error) => {
            handleApiExceptions(error);
            this.setState({ processing: false });
        });
    }

    render() {
        const { props } = this;
        return (
            <EaseDrawer
                title="Assign Staff"
                width={isMobile ? "100%" : 500}
                visible={props.serviceAssignDrawer}
                onClose={props.toggleServiceAssignDrawer.bind(this, {})}
                onOpen={() => {
                    this.fetchStaffList();
                }}
                hideFooter
            >
                <Typography.Title level={4} style={{ color: "#455862" }}>Select staff that provides <i>{props.service.name}</i> service</Typography.Title>
                <div id="selectable-staff-list-wrapper" className="mt-3">
                    <List
                        bordered={false}
                        dataSource={this.state.staffList}
                        renderItem={item => (<List.Item className="mb-1 staff-item c-pointer" onClick={() => { }}>
                            <div className="d-flex align-items-center justify-content-between w-100 px-2">
                                <div className="d-flex align-items-center">
                                    <Avatar icon="user" />
                                    <Typography.Text className="ml-3 staff-name">{item.first_name} {item.last_name}</Typography.Text>
                                </div>
                                <Switch loading={this.state.processing} checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} onChange={this.updateServiceUsers.bind(this, item.id)} checked={item.services.includes(this.props.service.id)} />
                            </div>
                        </List.Item>)}
                    />
                </div>
            </EaseDrawer>
        );
    }
}

export default ServiceAssignDrawer;