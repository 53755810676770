import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import moment from 'moment/moment';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import { isMobile } from 'react-device-detect';
import { PermissionHoc } from '../../HOC';
import ActiveSessions from './ActiveSessions';

const SessionList = (props) => {

  const formComponents = [
    {
      label: "Active Sessions",
      component: (<ActiveSessions />)
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey="0" onChange={() => { }} animated={false}>
        {
          formComponents.map((tab, index) => {
            return <Tabs.TabPane tab={tab.label} key={index}>{tab.component}</Tabs.TabPane>;
          })
        }
      </Tabs>
    </div>
  );

};

export default SessionList;