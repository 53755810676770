import React from 'react';
import { isMobile } from 'react-device-detect';
import { Select } from 'antd';

const SettingOptionsMobile = (props) => {

    const handleSettingChange = (id) => {
        const active_option = props.options.find(x => x.id === id);
        props.selectOption(active_option);
    };

    return (
        <div className={`w-100 mb-2 category-list-select-box ${isMobile ? 'd-flex' : 'd-none'}`}>
            <Select placeholder="Select Category" className="w-100" value={(props.activeOption || {}).id} onChange={handleSettingChange}>
                {
                    props.options.map((option) => {
                        return <Select.Option value={option.id} key={option.id}>{option.name}</Select.Option>;
                    })
                }
            </Select>
        </div>
    );
};

SettingOptionsMobile.defaultProps = {
    activeCategory: {}
};

export default SettingOptionsMobile;