import React, { Component } from 'react';
import _isEqual from 'lodash/isEqual';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from '@fullcalendar/interaction';
import './index.scss';
import { CalendarToolbar } from './toolbar';
import { renderEditButton } from './helpers';
import { isMobile } from 'react-device-detect';

class CalendarView extends Component {
    state = {};
    calendar = {};

    componentDidMount() {
        this.renderCalendar();
    }

    componentDidUpdate(prevProps) {

        if (!_isEqual(this.props.calendarEvents, prevProps.calendarEvents)) {
            // console.log('refetching Events prev events', prevProps.calendarEvents);
            // console.log('refetching Events new events', this.props.calendarEvents);
            this.calendar.getEventSources()[0].remove();
            this.calendar.addEventSource(this.getEvents(this.props.calendarEvents, this.props.holidays));
            this.calendar.refetchEvents();
        }
        if (!_isEqual(this.props.businessHours, prevProps.businessHours)) {
            this.calendar.setOption('businessHours', this.props.businessHours);
        }
    }

    getEvents(appointments, holidays) {
        const data = [...appointments];

        holidays.forEach((holiday) => {
            data.push({
                id: Math.random(),
                title: `Holiday - ${holiday.description}`,
                start: `${holiday.holiday_date} 00:00`,
                end: `${holiday.holiday_date} 23:59`,
                canEdit: false,
                backgroundColor: "rgba(190,243,191,0.5)"
            });
        });
        console.log('getEvents', data);
        return data;
    }

    renderCalendar() {
        const {
            selectable, calendarEvents, holidays,
            businessHours, firstDay, defaultView,
            onEventClick, onEventDrop, onDateSelect,
            editable, minTime, maxTime } = this.props;
        // console.log('renderCalendar', this.props.calendarEvents);
        var calendarEl = document.getElementById('am-calendar');
        this.calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, momentPlugin],
            selectable: selectable,
            header: false,
            events: this.getEvents(calendarEvents, holidays),
            editable: editable,
            businessHours: businessHours,
            eventOverlap: false,
            selectMirror: true,
            firstDay: firstDay,
            allDaySlot: false,
            slotDuration: "00:15:00",
            slotLabelInterval: { hours: 1 },
            titleFormat: "dddd, MMMM D, YYYY",
            eventTimeFormat: {
                hour: 'numeric',
                minute: '2-digit',
                meridiem: 'short'
            },
            defaultView: defaultView,
            // dayRender: (a) => { a.el.style.background = "#eee" },
            // eventRender: (info) => renderEditButton(info),
            eventDrop: onEventDrop,
            eventClick: onEventClick,
            select: onDateSelect,
            minTime: minTime,
            maxTime: maxTime,
            views: {
                timeGrid: {
                    // columnHeaderFormat: { weekday: 'short', month: '2-digit', day: '2-digit' }
                },
            },
            eventConstraint: "businessHours",
            selectConstraint: "businessHours"
        });
        this.calendar.render();
        this.setState({ calendar: this.calendar }, () => {
            this.props.onReady(this.calendar);
        });
    }

    calendarPrev() {
        this.calendar.prev();
        this.props.onDateChange(this.calendar.getDate());
    }
    calendarNext() {
        this.calendar.next();
        this.props.onDateChange(this.calendar.getDate());
    }
    calendarToday() {
        this.calendar.today();
        this.props.onDateChange(this.calendar.getDate());
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100vh" }} className="m-auto position-relative">
                <CalendarToolbar
                    calendar={this.state.calendar}
                    defaultView={this.props.defaultView}
                    customButtons={this.props.customButtons}
                    calendarPrev={this.calendarPrev.bind(this)}
                    calendarNext={this.calendarNext.bind(this)}
                    calendarToday={this.calendarToday.bind(this)}
                    onCalendarViewChange={(view) => {
                        this.props.onViewChange(view);
                        this.calendar.changeView(view);
                    }}
                />
                <div id="am-calendar" style={{ paddingTop: isMobile ? 0 : 48.2 }}></div>
            </div>
        );
    }
}

CalendarView.defaultProps = {
    defaultView: "timeGridWeek",
    businessHours: undefined,
    editable: false,
    selectable: true,
    calendarEvents: [],
    customButtons: [],
    firstDay: 1,
    minTime: "00:00",
    maxTime: "23:59",
    onDateSelect: (info) => { console.log(info); },
    onEventClick: (info) => { console.log(info); },
    onEventDrop: (info) => { console.log(info); },
    onViewChange: () => { },
    onDateChange: () => { },
    onReady: () => { }
};

export default CalendarView;