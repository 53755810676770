import React, { Component } from 'react';
import './index.scss';
import { Typography, Button, Popover, Input, List, Avatar, Icon, Popconfirm } from 'antd';
import { BusinessCategoryService, auth } from '../../../../services';
import { PermissionHoc } from '../../../HOC';
const PermissableButton = PermissionHoc(Button, '', { is_component: true, fallbackComponent: null });

class CategoryList extends Component {
    render() {
        return (
            <div>
                <div className="title d-flex align-items-center justify-content-between">
                    <Typography.Title level={4}>Service Categories</Typography.Title>
                    <Popover placement="bottom" visible={this.props.categoryFormVisible} title={(
                        <div className="d-flex align-items-center justify-content-between">
                            <Typography.Text strong>Add Category</Typography.Text>
                            <Icon type="close" onClick={this.props.toggleCategoryForm} />
                        </div>
                    )} content={this.props.renderAddCategory()}>
                        <PermissableButton required_permission={["SETTINGS:SERVICE:ADD"]} icon="plus" onClick={this.props.toggleCategoryForm} />
                    </Popover>
                </div>
                <div className="list">
                    <List
                        bordered={false}
                        dataSource={this.props.categories}
                        renderItem={item => (<List.Item className={`mb-1 category-item c-pointer ${item.id === this.props.activeCategory.id ? 'active' : ''}`} onClick={this.props.selectCategory.bind(this, item)}>
                            <div className="d-flex align-items-center justify-content-between w-100 px-2">
                                <div className="d-flex align-items-center">
                                    <Avatar icon="tag" />
                                    <Typography.Text className="ml-3 category-name">{item.name}</Typography.Text>
                                </div>
                                <Popconfirm className="category-delete-btn" title="Are you sure you want to delete category? All services would get deleted." okText="Delete" onConfirm={this.props.deleteCategory.bind(this, item)}>
                                    <PermissableButton required_permission={["SETTINGS:SERVICE:EDIT"]} icon="delete" />
                                </Popconfirm>
                            </div>
                        </List.Item>)}
                    />
                </div>
            </div>
        )
    }
}

CategoryList.defaultProps = {
    activeCategory: {}
}

export default CategoryList;