import React, { Component } from 'react';
import { Button, Table, Typography, Avatar, Switch, message } from 'antd';
import { isMobile } from 'react-device-detect';
import './index.scss';
import { resolve_server_image, handleApiExceptions } from '../../../app_methods';
import { MerchantService } from '../../../services';
import { ContentCard, MerchantBasicDetails } from '../../Common';
import { EaseDrawer } from '../../../libs/EaseDrawer';

class SuperAdminMerchant extends Component {
    fetchMerchants = this.fetchMerchants.bind(this);
    toggleFormDrawer = this.toggleFormDrawer.bind(this);
    columns = [{
        title: 'Name',
        key: 'name',
        render: (record) => (
            <div className="d-flex align-items-center">
                <Avatar icon="user" src={resolve_server_image(record.image)} />
                <Typography.Text className="ml-2">{record.name}</Typography.Text>
            </div>
        )
    }, {
        title: 'SPOC Name',
        key: 'spoc_name',
        render: (record) => <Typography.Text>{record.spoc_name}</Typography.Text>
    }, {
        title: 'SPOC Contact',
        key: 'spoc_contact',
        render: (record) => (
            <div className="d-flex flex-column">
                <Typography.Text>{record.spoc_email}</Typography.Text>
                <Typography.Text>{record.spoc_number}</Typography.Text>
            </div>
        )
    }, {
        title: 'Address',
        key: 'address',
        render: (record) => record.address && record.address.trim() !== "" ? record.address : "---"
    }, {
        title: 'Active?',
        key: 'active_status',
        render: (record) => <Switch defaultChecked={record.is_active} checked={record.is_active} onChange={(active) => this.updateMerchant(record.id, { is_active: active })} checkedChildren="Yes" unCheckedChildren="No" />
    },
    {
        title: 'Acrions',
        key: 'actions',
        render: (record) => <Button icon="edit" onClick={this.toggleFormDrawer.bind(this, record, true)}>Edit</Button>
    }];
    state = {
        merchants: [],
        formDrawer: false,
        activeMerchant: {}
    }

    componentDidMount() {
        this.fetchMerchants();
    }

    toggleFormDrawer(merchant, is_edit) {
        this.setState({ formDrawer: !this.state.formDrawer, activeMerchant: { ...merchant, is_edit } });
    }

    fetchMerchants() {
        MerchantService.list().then((response) => {
            if (response.success) {
                this.setState({ merchants: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        })
    }

    updateMerchant(id, data) {
        MerchantService.update({ id, ...(data || {}) }).then((response) => {
            if (response.success) {
                message.success("Merchant successfully updated.");
                this.fetchMerchants();
            }
        }).catch((error) => {
            handleApiExceptions(error);
        })
    }

    render() {
        return (
            <div id="superadmin-merchant-wrapper" className="p-3">
                <ContentCard className="p-3">
                    <Button icon="plus" className="float-right mb-2" onClick={this.toggleFormDrawer.bind(this, {}, false)}>Add Merchant</Button>
                    <div className="clearfix"></div>
                    <Table scroll={{ x: isMobile ? true : false }} columns={this.columns} dataSource={this.state.merchants} pagination={false} bordered={false} />
                    <EaseDrawer
                        width={isMobile ? "100%" : 700}
                        title={this.state.activeMerchant.is_edit ? "Edit Merchant" : "Add Merchant"}
                        visible={this.state.formDrawer}
                        hideFooter
                        onClose={this.toggleFormDrawer.bind(this, {}, false)}
                    >
                        <MerchantBasicDetails
                            hideBookingUrl
                            merchant={this.state.activeMerchant}
                            afterSubmit={() => {
                                this.fetchMerchants();
                                this.toggleFormDrawer();
                            }}
                        />
                    </EaseDrawer>
                </ContentCard>
            </div>
        )
    }
}

export default SuperAdminMerchant;