import React, { Component } from 'react';
import './index.scss';
import _isEmpty from 'lodash/isEmpty';
import { isMobile } from 'react-device-detect';
import CustomerList from './CustomerList';
import CustomerListMobile from './CustomerListMobile';
import CustomerWizard from './CustomerWizard';
import data from '../../data.json';
import { Input, Button } from 'antd';
import { NumericInput } from '../../Common/NumericInput';
import { CustomerModalForm } from '../../Common/Forms/CustomerModalForm';
import { PermissionHoc } from '../../HOC';
import { UserService, auth, AppointmentService } from '../../../services';
import { alertify, handleApiExceptions, getCustomerSearchType } from '../../../app_methods';


class AdminCustomer extends Component {

    state = {
        customers: [],
        activeCustomer: [],
        customer: {},
        appointments: [],
        customerPopoverVisible: false,
        searchValue: '',
        searchLoading: false
    };

    searchCustomerTimeout = null;

    componentDidMount() {
        this.fetchCustomerList();
    }

    componentWillUnmount() {
        clearTimeout(this.searchCustomerTimeout);
    }

    fetchCustomerList = (searchTerm = '') => {
        let queryData = {};
        if (searchTerm) {
            queryData.search = searchTerm;
            this.setState({ searchLoading: true });
        }
        UserService.getCustomerList(queryData).then((response) => {
            if (response.success) {
                this.setState({ customers: response.data.results, searchLoading: false });
                if (_isEmpty(this.state.activeCustomer)) {
                    this.setState({ activeCustomer: response.data[0] || {} }, () => {
                        this.fetchAppointments(this.state.activeCustomer.id);
                    });
                }
            }
        }).catch((error) => {
            this.setState({ searchLoading: false });
            handleApiExceptions(error);
        });
    };

    fetchAppointments = (customer_id) => {
        AppointmentService.list({ customer_id: customer_id }).then((response) => {
            this.setState({
                appointments: response.data.results
            });
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    selectCustomer = (customer) => {
        this.setState({ activeCustomer: customer }, () => {
            this.fetchAppointments(this.state.activeCustomer.id);
        });
    };

    renderAddCustomer = () => {
        return (
            <CustomerModalForm
                customer={this.state.customer}
                handleFirstNameChange={this.handleCustomerFieldChange}
                handleLastNameChange={this.handleCustomerFieldChange}
                handleEmailChange={this.handleCustomerFieldChange}
                handleMobileChange={this.handleMobileChange}
                addCustomer={this.addCustomer} />
            // <div className="d-flex flex-column">
            //     <Input name="first_name"
            //         value={this.state.customer.first_name}
            //         onChange={this.handleCustomerFieldChange}
            //         placeholder="First Name"
            //         style={{ width: "200px" }} />
            //     <Input name="last_name"
            //         value={this.state.customer.last_name}
            //         onChange={this.handleCustomerFieldChange}
            //         placeholder="Last Name"
            //         style={{ width: "200px" }}
            //         className="mt-2" />
            //     <Input name="email"
            //         value={this.state.customer.email}
            //         onChange={this.handleCustomerFieldChange}
            //         placeholder="Email Address"
            //         style={{ width: "200px" }}
            //         className="mt-2" />
            //     <NumericInput
            //         name="phone"
            //         value={this.state.customer.phone}
            //         onChange={this.handleMobileChange}
            //         placeholder="Mobile" style={{ width: "200px" }}
            //         maxLength={10}
            //         className="mt-2" />
            //     <Button type="primary" onClick={this.addCustomer} className="mt-2">Add</Button>
            // </div>
        );
    };

    customerPopoverToggle = () => {
        this.setState({ customerPopoverVisible: !this.state.customerPopoverVisible });
    };

    addCustomer = () => {
        const { customer } = this.state;
        if (!customer.first_name || !customer.last_name ||
            !customer.email || !customer.first_name.trim() ||
            !customer.last_name.trim() || !customer.email.trim()) {
            alertify("Oops!!!", "Customer name and email is mandatory.", "error");
            return;
        }
        UserService.create({
            ...this.state.customer, merchant: auth.user.merchant.id, role: 'customer'
        }).then((response) => {
            if (response.success) {
                this.setState({ customer: {}, customerPopoverVisible: false });
                this.fetchCustomerList();
                alertify("Awesome", "Customer successfully created.", "success");
            } else {
                alertify("Oops!!!", "Could not add customer. Try again.", "error");
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    };

    handleCustomerFieldChange = (e) => {
        const customer = this.state.customer;
        customer[e.target.name] = e.target.value;
        this.setState({ customer });
    };

    handleMobileChange = (value) => {
        const customer = this.state.customer;
        customer['phone'] = value;
        this.setState({ customer });
    };

    onCustomerSearch = (searchTerm, e) => {
        if (searchTerm.length > 2) {
            this.searchCustomerTimeout = setTimeout(() => {
                this.fetchCustomerList(searchTerm);
            }, 650);
        }
    };

    onSearchInputChange = (e) => {
        const searchTerm = e.target.value;
        if (!searchTerm) {
            this.searchCustomerTimeout = setTimeout(() => {
                this.fetchCustomerList();
            }, 650);
        } else if (searchTerm.length > 2) {
            this.searchCustomerTimeout = setTimeout(() => {
                this.fetchCustomerList(searchTerm);
            }, 650);
        }
        this.setState({ searchValue: searchTerm });
    };

    getEmptyListDescription = () => {
        if (this.state.searchValue.length > 2) {
            if (this.state.searchValue.includes('@') && this.state.searchValue.includes('.')) {
                return { type: 'email', description: 'Add Customer using Email' };
            } else if (/^\d+$/.test(this.state.searchValue)) {
                return { type: 'phone', description: 'Add Customer using Mobile' };
            } else {
                return { type: 'name', description: 'Add Customer using Name' };
            }
        }
        return false;
    };

    onAddWithEmptySearchResult = () => {
        const searchType = getCustomerSearchType(this.state.searchValue);
        if (searchType) {
            const customer = {};
            switch (searchType.type) {
                case 'email': customer['email'] = this.state.searchValue; break;
                case 'phone': customer['phone'] = this.state.searchValue; break;
                case 'name':
                    const splitBySpace = this.state.searchValue.split(' ');
                    if (splitBySpace.length > 1) {
                        customer['last_name'] = splitBySpace[1];
                    }
                    customer['first_name'] = splitBySpace[0];
                    break;
            }
            this.setState({ customer }, () => {
                this.customerPopoverToggle();
            });
        } else {
            this.customerPopoverToggle();
        }
    };

    render() {
        return (
            <div id="admin-customers-wrapper" className="d-flex w-100">
                <div className={`customer-list p-3 ${isMobile ? 'd-none' : ''}`}>
                    <CustomerList
                        customers={this.state.customers}
                        customerPopoverToggle={this.customerPopoverToggle}
                        customerPopoverVisible={this.state.customerPopoverVisible}
                        activeCustomer={this.state.activeCustomer}
                        selectCustomer={this.selectCustomer}
                        renderAddCustomer={this.renderAddCustomer}
                        searchValue={this.state.searchValue}
                        loading={this.state.searchLoading}
                        onSearchInputChange={this.onSearchInputChange}
                        onSearch={this.onCustomerSearch}
                        getEmptyListDescription={getCustomerSearchType(this.state.searchValue)}
                        onAddWithEmptySearchResult={this.onAddWithEmptySearchResult} />
                </div>
                <div className={`customer-details p-3 ${isMobile ? 'w-100' : ''}`}>
                    <CustomerListMobile
                        customers={this.state.customers}
                        customerPopoverToggle={this.customerPopoverToggle}
                        customerPopoverVisible={this.state.customerPopoverVisible}
                        activeCustomer={this.state.activeCustomer}
                        selectCustomer={this.selectCustomer}
                        renderAddCustomer={this.renderAddCustomer} />
                    <CustomerWizard
                        fetchCustomerList={this.fetchCustomerList}
                        fetchAppointments={this.fetchAppointments}
                        activeCustomer={this.state.activeCustomer}
                        appointments={this.state.appointments} />
                </div>
            </div>
        );
    }
}

export default PermissionHoc(AdminCustomer, ["CUSTOMER_MASTER"]);