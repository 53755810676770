import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { AdminRouter, UserRouter, CustomerRouter, SuperAdminRouter } from './components';
import ThemeProvider from './ThemeProvider';
import './assets/bootstrap-v4/bootstrap.min.css';
import 'antd/dist/antd.css';
import './apply-theme.scss';
import './ant-overwrite.scss';
import './app.scss';
import { LocalStorage, auth, MerchantService } from './services';
import { handleApiExceptions } from './app_methods';
import { Typography, Button, Icon } from 'antd';
const storage = new LocalStorage();

// import { AppRouterHoc } from './components/HOC';
const customHistory = createBrowserHistory();

class App extends Component {
    uiPreference = auth.uiPreference;

    state = {
        theme: auth.settings().theme || 'default',
        isOnline: navigator.onLine,
        checkingInternet: false
    };

    componentDidMount() {
        window.addEventListener('offline', this.updateIsOnline.bind(this, false));
        window.addEventListener('online', this.updateIsOnline.bind(this, true));
    }

    updateIsOnline(isOnline) {
        this.setState({ isOnline });
    }

    checkInternet() {
        this.setState({ checkingInternet: true });
        setTimeout(() => {
            this.setState({ isOnline: navigator.onLine, checkingInternet: false });
        }, 2000);
    }

    AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
        return (
            <Route {...rest} render={props => {
                return (
                    <Layout
                        history={customHistory}
                        isPublic={rest.isPublic}
                        redirect={this.redirect.bind(this)}
                        changeTheme={this.changeTheme.bind(this)}>
                        <Component {...props} {...rest} />
                    </Layout>
                );
            }} />
        );
    };

    //   AppRouteHoc = AppRouterHoc(this.AppRoute);
    AppRouteHoc = this.AppRoute;

    redirect(to, onRouteChange = () => { }, e) {
        customHistory.push(to);
        typeof onRouteChange === "function" ? onRouteChange() : e = onRouteChange;
    }

    changeTheme(theme) {
        const settings = { ...auth.settings(), theme: theme };
        MerchantService.update_settings({ id: auth.user.merchant.id, settings })
            .then((response) => {
                this.setState({ theme }, () => {
                    auth.set_settings(settings);
                });
            }).catch((error) => {
                handleApiExceptions(error);
            });
    }

    render() {
        return (
            <ThemeProvider theme={this.state.theme}>
                <Router history={customHistory}>
                    <div style={{ position: 'relative', height: "100%" }}>
                        <SuperAdminRouter AppRoute={this.AppRouteHoc} />
                        <AdminRouter AppRoute={this.AppRouteHoc} />
                        <CustomerRouter AppRoute={this.AppRouteHoc} />
                        <UserRouter AppRoute={this.AppRouteHoc} />
                    </div>
                </Router>
                {/* {
                    this.state.isOnline ? null : (
                        <div className="no-interner-overlay">
                            <div className="text-center d-flex flex-column" style={{ marginTop: 200 }}>
                                <div style={{ width: 250, height: 250, margin: "auto", filter: "brightness(0) invert(1)" }}>
                                    <img src={require('./assets/images/no-internet.png')} alt="NO INTERNET" style={{ width: "100%" }} />
                                </div>
                                <Typography.Text style={{ fontSize: 25, color: "#fff" }}>Seems like you are not connected to Internet</Typography.Text>
                                <Button className="mt-5 mx-auto" ghost style={{ width: 200, fontSize: 18 }} onClick={this.checkInternet.bind(this)}>
                                    <Icon className="mr-1" spin={this.state.checkingInternet} type="redo" />
                                    <span>Retry</span>
                                </Button>
                            </div>
                        </div>
                    )
                } */}
            </ThemeProvider>
        );
    }
}

export default App;
