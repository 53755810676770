import React, { Component } from 'react';
import { Form, Icon, Input, Button, Card } from 'antd';
import { UserService, auth, BusinessService } from '../../../services';
import { alertify } from '../../../app_methods';
import './index.scss';

class LoginForm extends Component {
    state = {
        processing: false
    };

    login = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) return;
            this.setState({ processing: true });
            UserService.login({
                email: values.email,
                password: values.password,
            }).then((response) => {
                if (response.success) {
                    const _user = response.data;
                    auth.set_user(_user);
                    this.redirect(_user.role.role_type);
                } else {
                    alertify('Oops!!!', 'Invalid email or password. Try again.', 'error');
                    this.setState({ processing: false });
                }
            }).catch((error) => {
                alertify('Oops!!!', 'Invalid email or password. Try again.', 'error');
                this.setState({ processing: false });
            });
        });
    };

    redirect = (role) => {
        switch (role) {
            case 'admin':
            case 'staff':
                this.props.history.push("/");
                break;
            // case 'customer':
            //     this.props.history.push("/customer/sessions");
            //     break;
            default:
                alertify("Oops!!!", "No UI for role " + role, 'error');
                break;
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div id="login-wrapper">
                <Card className="m-auto shadow login-card">
                    <div className="">
                        <h1 className="text-center w-100 lobo-text Rancho">Appointment<span className="ml-3 color-primary">Ninja</span></h1>
                    </div>
                    <Form onSubmit={this.login} className="login-form mt-5">
                        <Form.Item>
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please input your Email!' }],
                            })(
                                <Input disabled={this.state.processing} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please input your Password!' }],
                            })(
                                <Input disabled={this.state.processing} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <div className="d-flex justify-content-center align-items-center w-100">
                                {/* <a className="login-form-forgot" onClick={() => this.props.history.push("/users/recover")}>Forgot password?</a> */}
                                <div></div>
                                <Button loading={this.state.processing} type="primary" htmlType="submit" className="login-form-button">
                                    Log in
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        );
    }
}
const Login = Form.create()(LoginForm);
export default Login;