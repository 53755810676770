import React, { Component } from 'react';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons/faAddressCard';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons/faUserCircle';
import { faHandshake } from '@fortawesome/free-regular-svg-icons/faHandshake';
import './index.scss';
import AmTab from '../../Common/AmTab';
import StaffSetting from './Staff';
import ServiceSettings from './Service';
import AccountSettings from './Account';
import { auth } from '../../../services';
import { PermissionHoc } from '../../HOC';

class AdminSettings extends Component {
    user_permissions = auth.permissions();
    getTabs() {
        return [
            {
                icon: faAddressCard,
                label: "Account",
                content: <AccountSettings changeTheme={this.props.changeTheme} />,
                visible: this.user_permissions["SETTINGS:ACCOUNT"]
            },
            {
                icon: faUserCircle,
                label: "Staff",
                content: <StaffSetting />,
                visible: this.user_permissions["SETTINGS:STAFF"]
            },
            {
                icon: faHandshake,
                label: "Services",
                content: <ServiceSettings />,
                visible: this.user_permissions["SETTINGS:SERVICE"]
            },
        ];
    }

    render() {
        return (
            <div id="admin-settings-wrapper" className="m-3">
                <AmTab tabs={this.getTabs()} defaultTabKey={this.getTabs().findIndex(x => x.visible)} />
            </div>
        );
    }
}

export default PermissionHoc(AdminSettings, ["SETTINGS"]);