import React from 'react';
import { Switch } from 'react-router-dom';
import { AdminLayout } from '../../layouts';
import { AdminDashboard, AdminCalendar, AdminSettings, AdminCustomer, AppointmentQueue, Appointments, SessionList } from './index';

const AdminRouter = (props) => {
    const AppRoute = props.AppRoute;
    return (
        <Switch>
            <AppRoute exact path="/" layout={AdminLayout} component={AdminCalendar} pathkey="admin_dashboard" />
            <AppRoute exact path="/admin/dashboard" layout={AdminLayout} component={AdminDashboard} pathkey="admin_dashboard" />
            <AppRoute exact path="/admin/calendar" layout={AdminLayout} component={AdminCalendar} pathkey="admin_calendar" />
            <AppRoute exact path="/admin/appointments/queue" layout={AdminLayout} component={AppointmentQueue} pathkey="admin_appointment_queue" />
            <AppRoute exact path="/admin/appointments/:date_range" layout={AdminLayout} component={Appointments} pathkey="admin_appointments" />
            <AppRoute exact path="/admin/customer" layout={AdminLayout} component={AdminCustomer} pathkey="admin_customer" />
            <AppRoute exact path="/admin/sessions" layout={AdminLayout} component={SessionList} pathkey="admin_sessions" />
            <AppRoute exact path="/admin/settings" layout={AdminLayout} component={AdminSettings} pathkey="admin_settings" />
        </Switch>
    );
};

export default AdminRouter;
