import React, { Component } from 'react';
import { Drawer, Button, Icon } from 'antd';
import './index.css';

class EaseDrawer extends Component {
    state = {
        spinner: false
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.visible && nextProps.visible) {
            nextProps.onOpen();
        }
    }

    spinner(spinnerState) {
        this.setState({ spinner: spinnerState });
    }

    renderFooter(props) {
        if (props.hideFooter) { return; }
        return (
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 9
                }}
            >
                <Button icon={props.okIcon} onClick={props.onOk} type="primary">
                    {props.okText}
                </Button>
            </div>
        )
    }

    renderTitle(props) {
        return (
            <div className="d-flex align-items-center">
                {!props.hideBackArrow ? <span onClick={props.onClose} style={{ fontSize: 20, marginRight: 20, cursor: 'pointer' }}><Icon type="arrow-left" /></span> : ''}
                <span>{props.title}</span>
            </div>
        )
    }

    getMaskValue(props) {
        let value = true;
        if (props.hasOwnProperty('mask')) {
            value = props.mask;
        } else {
            if (props.width === "100%") {
                value = false;
            }
        }
        return value;
    }

    render() {
        const { props } = this;
        return (
            <Drawer
                visible={props.visible}
                destroyOnClose={props.destroyOnClose}
                title={props.hideHeader ? false : this.renderTitle(props)}
                closable={props.closable}
                width={props.width}
                maskClosable={props.maskClosable}
                mask={this.getMaskValue(props)}
                onClose={props.onClose}
                className={props.className}
                style={{ overflow: 'auto', height: "100%", ...props.style }}
            >
                <div>
                    {this.props.children}
                    {this.renderFooter(props)}
                </div>
            </Drawer>
        );
    }
}

EaseDrawer.defaultProps = {
    visible: false,
    closable: true,
    hideFooter: false,
    hideHeader: false,
    maskClosable: true,
    destroyOnClose: true,
    hideBackArrow: false,
    title: "Drawer Title",
    okText: "Ok",
    width: 420,
    okIcon: "",
    style: {},
    className: "",
    onOpen: () => { },
    onOk: () => { },
    onClose: () => { }
}

export { EaseDrawer };