import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss';

class AmTab extends Component {
    state = {
        activeTab: this.props.defaultTabKey
    }
    renderTabTitle(icon, label) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <FontAwesomeIcon icon={icon} size="2x" color="#ddd" />
                <div className="mt-1 am-menu-item-title">{label}</div>
            </div>
        )
    }

    renderTabItem(tab, index) {
        if(!tab.visible){ return null; }
        return (
            <div className={`menu-item d-flex justify-content-center align-items-center shadow-sm c-pointer rounded ${index === this.state.activeTab ? 'active' : ''}`} key={index} onClick={this.setActiveTab.bind(this, index)}>
                {this.renderTabTitle(tab.icon, tab.label)}
            </div>
        )
    }

    renderContent() {
        if (this.state.activeTab >= 0 && this.props.tabs[this.state.activeTab]) {
            return this.props.tabs[this.state.activeTab].content
        } else {
            return null;
        }
    }

    setActiveTab(tab) {
        this.setState({ activeTab: tab });
    }

    render() {
        return (
            <div id="am-tab-wrapper">
                <div className="menu-wrapper d-flex flex-wrap">
                    {this.props.tabs.map((tab, index) => { return this.renderTabItem(tab, index) })}
                </div>
                <div className="menu-content border shadow-sm rounded">
                    {this.renderContent()}
                </div>
            </div>
        )
    }
}

AmTab.defaultProps = {
    tabs: [],
    defaultTabKey: 0
}

export default AmTab;