import React from 'react';
import defaultTheme from './default';
import redTheme from './red';
import purpleTheme from './pastelPurple';
import greenTheme from './green';
import orangeTheme from './orange';
import blueTheme from './blue';
import greyTheme from './grey';

const themeMapping = {
    default: defaultTheme,
    red: redTheme,
    purple: purpleTheme,
    green: greenTheme,
    orange: orangeTheme,
    blue: blueTheme,
    grey: greyTheme,
};

const ThemeProvider = (props) => {
    let root = document.documentElement;
    const theme = themeMapping[props.theme] || defaultTheme;
    Object.keys(theme).forEach(property => {
        root.style.setProperty(property, theme[property]);
    });
    return props.children;
};

ThemeProvider.defaultProps = {
    theme: "default"
};

export default ThemeProvider;
