import React, { Component } from 'react';
import './index.scss';

class AdminDashboard extends Component {
    render() {
        return (
            <div>
                Admin Dashboard
            </div>
        )
    }
}

export default AdminDashboard;