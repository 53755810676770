import { get, post, patch, put } from '../HttpService';

export class ReceiptService {
    static list = (queryData = {}, config = {}) => {
        return get(`/v1/admin/receipt/`, queryData, config);
    };
    static create = (queryData = {}, config = {}) => {
        return post(`/v1/admin/receipt/`, queryData, config);
    };
    static update = (queryData = {}, config = {}) => {
        return patch(`/v1/admin/receipt/${queryData.id}/`, queryData, config);
    };
}
