import React, { Component } from 'react';
import { auth } from '../../services';

const PermissionHoc = (WrappedComponent, required_permission, options = {}) => {
  class HOC extends Component {
    is_authorized() {
      // return true
      const user_permissions = auth.permissions() || [];
      required_permission = (options.is_component) ? this.props.required_permission : required_permission;
      return !required_permission || required_permission.every(x => Object.keys(user_permissions).includes(x));
    }

    renderFallback() {
      return (options.hasOwnProperty('fallbackComponent')) ? options.fallbackComponent : (<h3 className="mt-5 text-center">You're Not Authorized......</h3>);
    }

    render() {
      if (this.is_authorized()) {
        return (
          <WrappedComponent {...this.props} />
        );
      } else {
        return this.renderFallback();
      }
    }
  }
  return HOC;
};

export { PermissionHoc };
