import React from 'react';
import { Menu, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionHoc } from '../../HOC';
import './index.scss';
// import { useLocation } from 'react-router-dom';

const PermissableMenu = PermissionHoc(Menu.Item, '', { is_component: true, fallbackComponent: null });
const SidebarFullMenuItem = ({ key, onClick, icon, label, permissions, routeTo, showToolTip, ...rest }) => {
    // let location = useLocation();
    let location = window.location;

    const isActiveRoute = () => location ? location.pathname.includes(routeTo) : false;

    const classes = isActiveRoute() ? "am-menu-item-full selected" : "am-menu-item-full";

    const menuItem = (
        <div className={classes}>
            <FontAwesomeIcon icon={icon}
                style={{ fontSize: 24, minWidth: 56, marginRight: 12 }}
                color="grey" />
            <span style={{ fontSize: '1rem' }}>{label}</span>
        </div>
    );

    return (
        <PermissableMenu {...rest} key={key}
            className={"text-center"} required_permission={permissions || []} onClick={onClick.bind(this)}>
            {
                showToolTip &&
                <Tooltip placement="right" title={label} >
                    {menuItem}
                </Tooltip>
            }
            {
                !showToolTip && menuItem
            }
        </PermissableMenu>
    );
};

export default SidebarFullMenuItem;