import React from 'react';
import { Switch } from 'react-router-dom'
import { Login } from '.';
import { BlankLayout } from '../../layouts';

const UserRouter = (props) => {
  const AppRoute = props.AppRoute;
  return (
    <Switch>
      <AppRoute exact path="/users/login" layout={BlankLayout} component={Login} isPublic />
    </Switch>
  )
}

export default UserRouter;
