import React, { Component } from 'react';
import { Form, Input, Avatar, Tag, Table, Button, Switch, Icon, Typography } from 'antd';
import moment from 'moment/moment';
import { isMobile } from 'react-device-detect';
import { UserService, BreakHourService, auth } from '../../../../services';
import { TimePicker } from '../../index';
import { handleApiExceptions, user_fullname } from '../../../../app_methods';


class StaffBreakHours extends Component {
    fetchStaffBreakHours = this.fetchStaffBreakHours.bind(this);
    handleActiveChange = this.handleActiveChange.bind(this);
    // disabledHours = this.disabledHours.bind(this)

    state = {
        breakHours: [],
        processing: false,
        settings: auth.settings() || {}
    };

    columns = [{
        title: 'Day',
        key: 'day',
        render: (record) => <span>{record.display_day}</span>
    }, {
        title: 'On/Off',
        key: 'on_off',
        render: (record) => <Switch loading={this.state.processing} checkedChildren="On" unCheckedChildren="Off" defaultChecked={record.currently_active} checked={record.currently_active} onChange={this.handleActiveChange.bind(this, record)} />
    }, {
        title: 'Work Hours',
        key: 'work_hours',
        render: (record) => {
            return (
                <div className="d-flex align-items-center">
                    <TimePicker
                        style={{ width: 100 }}
                        className="mr-2"
                        minTime={(this.state.settings.business_hours || {}).start}
                        maxTime={(this.state.settings.business_hours || {}).end}
                        defaultValue={moment(record.break_start_time, 'HH:mm')}
                        value={moment(record.break_start_time, 'HH:mm')}
                        disabled={!record.currently_active || this.state.processing}
                        onChange={this.handleTimeChange.bind(this, record, "break_start_time")}
                    />
                    <span>to</span>
                    <TimePicker
                        style={{ width: 100 }}
                        className="ml-2"
                        minTime={(this.state.settings.business_hours || {}).start}
                        maxTime={(this.state.settings.business_hours || {}).end}
                        defaultValue={moment(record.break_end_time, 'HH:mm')}
                        value={moment(record.break_end_time, 'HH:mm')}
                        disabled={!record.currently_active || this.state.processing}
                        onChange={this.handleTimeChange.bind(this, record, "break_end_time")}
                    />
                </div>
            );
        }
    }];

    componentDidMount() {
        this.fetchStaffBreakHours(this.props.staffDetails.id);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.staffDetails.id !== nextProps.staffDetails.id) {
            this.fetchStaffBreakHours(nextProps.staffDetails.id);
        }
    }

    getDisabledHours(break_start_time) {
        const hours = [];
        for (let i = 0; i < moment(break_start_time, "HH:mm").hour(); i++) {
            hours.push(i);
        }
        return hours;
    }

    getDisabledMinutes(break_start_time, selected_hour) {
        const minutes = [];
        const m = moment;
        if (selected_hour === moment(break_start_time, "HH:mm").hour()) {
            for (let i = 0; i <= moment(break_start_time, "mm:mm").minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    fetchStaffBreakHours(id) {
        BreakHourService.list({ staff: id }).then((response) => {
            if (response.success) {
                this.setState({ breakHours: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    }

    handleActiveChange(record, active) {
        this.updateBreakHours({ ...record, currently_active: active });
    }

    handleTimeChange(record, key, value) {
        this.updateBreakHours({ ...record, [key]: value.format("HH:mm") });
    }

    updateBreakHours(work_hours) {
        this.setState({ processing: true });
        BreakHourService.update(work_hours).then((response) => {
            if (response.success) {
                this.fetchStaffBreakHours(this.props.staffDetails.id);
            }
            this.setState({ processing: false });
        }).catch((error) => {
            handleApiExceptions(error);
            this.setState({ processing: false });
        });
    }

    render() {
        return (
            <div id="staff-services" className="mx-auto" style={{ width: "100%" }}>
                <Typography.Title level={4} className="mb-3 mt-3" style={{ color: "#455862" }}>{user_fullname(this.props.staffDetails)}'s Break Hours</Typography.Title>
                <Table scroll={{ x: isMobile ? true : false }} columns={this.columns} dataSource={this.state.breakHours} pagination={false} bordered={false} />
            </div>
        );
    }
}

export default StaffBreakHours;









// import React, { Component } from 'react';
// import { Form, Input, Avatar, Tag, Table, Button, Switch, Icon, Typography, TimePicker } from 'antd';
// import { isMobile } from 'react-device-detect';

// const columns = [{
//     title: 'Day',
//     dataIndex: 'day',
//     key: 'day',
// }, {
//     title: 'On/Off',
//     key: 'on_off',
//     render: () => <Switch checkedChildren="On" unCheckedChildren="Off" />
// }, {
//     title: 'Work Hours',
//     key: 'work_hours',
//     render: (record) => {
//         return (
//             <div className="d-flex align-items-center">
//                 <TimePicker className="mr-2" /> <span>to</span> <TimePicker className="ml-2" /> <Button icon="delete" type="danger" className="ml-3" />
//             </div>
//         )
//     }
// }];

// const data = [{
//     key: '1',
//     day: 'Monday',
//     value: "monday"
// }, {
//     key: '2',
//     day: 'Tuesday',
//     value: "tuesday"
// }, {
//     key: '3',
//     day: 'Wednesday',
//     value: "wednesday"
// }, {
//     key: '4',
//     day: 'Thursday',
//     value: "Thursday"
// }, {
//     key: '5',
//     day: 'Friday',
//     value: "Friday"
// }, {
//     key: '6',
//     day: 'Saturday',
//     value: "Saturday"
// }, {
//     key: '7',
//     day: 'Sunday',
//     value: "Sunday"
// }];

// class StaffBreak extends Component {
//     render() {
//         return (
//             <div id="staff-services" className="mx-auto" style={{ width: "100%" }}>
//                 <Typography.Title level={4} className="mb-3 mt-3" style={{ color: "#455862" }}>Karan Bhutwala's Breaks</Typography.Title>
//                 <Table scroll={{x: isMobile? true : false}} columns={columns} dataSource={data} pagination={false} bordered={false} />
//             </div>
//         )
//     }
// }

// export default StaffBreak;
