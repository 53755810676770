
import React, { useEffect, useState } from 'react';
import { Button, Table, Typography, Tag, Collapse, Form, Row, Col, Checkbox, Input, Icon, Select, Spin, Pagination } from 'antd';
import moment from 'moment/moment';
import { UserService, auth, AppointmentService, BusinessService, SessionService, QueueService } from '../../../../services';
import { humanizeDate, titleCase, user_fullname, handleApiExceptions } from '../../../../app_methods';
import { EaseModal } from '../../../../libs/EaseModal';
import { defaultTableQuery } from '../../../Common/models';
import { isMobile } from 'react-device-detect';
import { SessionForm, TableToolbar } from '../../../Common';

const initialFilters = {
  checkin_time__isnull: false,
  checkout_time__isnull: true,
  is_paid: 'false'
};

const ActiveSessions = (props) => {
  const [fetchingData, setFetchingData] = useState(false);
  const [sessionModalData, setSessionModalData] = useState({ session: undefined, is_edit: false, isVisible: false });
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState(initialFilters);
  const [appliedFilters, setAppliedFilters] = useState(initialFilters);
  const [sessions, setSessions] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [allStaff, setAllStaff] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customerSearchTerm, setCustomerSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableQuery, setTableQuery] = useState(defaultTableQuery);

  useEffect(() => {
    fetchActiveSessions();
    fetchServices();
    fetchStaffList();
    // fetchCustomerList();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(fetchActiveSessions, 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    const timeoutId = setTimeout(fetchCustomerList, 400);
    return () => clearTimeout(timeoutId);
  }, [customerSearchTerm]);

  // useEffect(() => {
  //   fetchActiveSessions();
  //   // console.log('filters', appliedFilters);  
  // }, [appliedFilters]);

  useEffect(() => {
    fetchActiveSessions();
  }, [appliedFilters || tableQuery.page]);

  const fetchStaffList = () => {
    UserService.getStaffList({ merchant: auth.user.merchant.id }).then((response) => {
      if (response.success) {
        setAllStaff(response.data.results);
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  };

  const fetchCustomerList = () => {
    setLoading(true);
    const query = { merchant: auth.user.merchant.id };
    if (customerSearchTerm.length > 2) {
      query.search = customerSearchTerm;
    }
    UserService.getCustomerList(query).then((response) => {
      if (response.success) {
        setAllCustomers(response.data.results);
      }
      setLoading(false);
    }).catch((error) => {
      handleApiExceptions(error);
      setLoading(false);
    });
  };

  const fetchServices = () => {
    BusinessService.list({ merchant: auth.user.merchant.id }).then((response) => {
      if (response.success) {
        setAllServices(response.data);
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  };

  const fetchActiveSessions = () => {
    setFetchingData(true);
    const filters = { ...appliedFilters };
    Object.keys(appliedFilters).forEach(k => {
      if (Array.isArray(appliedFilters[k])) {
        filters[k] = appliedFilters[k] + '';
      }
    });
    let query = {
      page: tableQuery.page,
      page_size: tableQuery.page_size,
      ...filters
    };
    if (searchTerm) {
      query.search = searchTerm;
    }

    SessionService.list(query).then((response) => {
      if (response.success) {
        const { data } = response;
        setSessions(data.results);
        setTableQuery({ ...tableQuery, total: data.count });
      }
      setFetchingData(false);
    }).catch((error) => {
      setFetchingData(false);
      handleApiExceptions(error);
    });
  };

  const closeSessionModal = () => {
    setSessionModalData({ ...sessionModalData, isVisible: false });

  };

  const openSessionModal = (session) => {
    // const allowEdit = session.checkin_time ? false : true;
    setSessionModalData({ session: { ...session, is_edit: true }, isVisible: true });
  };

  const handleFilterChange = (filterName, value) => {
    // console.log('handleFilterChange', value);
    let f = { ...filters };
    f[filterName] = value;
    setFilters(f);
  };

  const applyFilters = () => {
    setAppliedFilters(filters);
    setTableQuery({ ...tableQuery, page: 1 });
  };
  const columns = [
    {
      title: 'No.',
      key: "index",
      render: (text, record, index) => {
        return (<Typography.Text >{index + 1}</Typography.Text>);
      }
    },
    {
      title: 'Service',
      key: 'service',
      render: (record) => <div>{record.service.name}</div>
    },
    {
      title: 'Description',
      key: 'desc',
      render: (record) => (
        <Typography.Text type="secondary">{record.service.description || "---"}</Typography.Text>
      )
    },
    {
      title: 'Staff',
      key: 'staff',
      render: (record) => (
        <Typography.Text type="secondary">{user_fullname(record.staff_obj)}</Typography.Text>
      )
    },
    {
      title: 'Amount',
      key: "amount",
      render: (record) => <Typography.Text >{record.service.sell_price}</Typography.Text>
    }
  ];

  const expandedRowRender = (record) => {
    return <div key={record.id}>
      {
        record.services_requested &&
        record.services_requested.length > 0 &&
        <>
          <div style={{ fontWeight: 600, padding: '8px 0' }}>Services Requested</div>
          <Table
            columns={columns}
            pagination={false}
            dataSource={record.services_requested}
            style={{ border: '1px solid #e8e8e8', borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomWidth: 0 }}
            scroll={{ x: isMobile ? true : false }} />
        </>
      }
      {record.services_provided &&
        record.services_provided.length > 0 &&
        <>
          <div style={{ fontWeight: 600, padding: '16px 0 8px 0' }}>Services Provided</div>
          <Table
            columns={columns}
            pagination={false}
            dataSource={record.services_provided}
            style={{ border: '1px solid #e8e8e8', borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomWidth: 0 }}
            scroll={{ x: isMobile ? true : false }} />
        </>
      }
    </div>;
  };

  const handleResetFilters = () => {
    setFilters(initialFilters);
    setAppliedFilters(initialFilters);
  };

  const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 8 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 16 }, }, };

  return (
    <div className="w-100" style={{ padding: 16 }}>
      <Collapse defaultActiveKey={['1']} style={{ marginBottom: 10 }}>
        <Collapse.Panel header="Filters" key="1">
          <Row style={{ maxWidth: '1280px' }} key="r1">
            <Col span={6} key={'c1'}>
              <Form
                {...formItemLayout}
                className="w-100">
                {/* <Row> */}
                {/* <div className="item-col"> */}
                <Form.Item label="Checkin Type">
                  <Select
                    allowClear
                    size="default"
                    placeholder="All"
                    style={{ maxWidth: '12rem' }}
                    value={filters.checkin_type}
                    disabled={false}
                    readonly={false}
                    onChange={(e) => handleFilterChange('checkin_type', e)}>
                    <Select.Option key={'appointment'} value={'appointment'}>Appointment</Select.Option>
                    <Select.Option key={'walkin'} value={'walkin'}>Walkin</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Customer has">
                  <div style={{ display: 'flex', width: '225px' }}>
                    <Checkbox
                      checked={!filters.checkin_time__isnull}
                      disabled={false}
                      onChange={(e) => handleFilterChange('checkin_time__isnull', !e.target.checked)}
                    >
                      {"Checked In"}
                    </Checkbox>
                    {/* </Form.Item>
                <Form.Item> */}
                    <Checkbox
                      checked={!filters.checkout_time__isnull}
                      disabled={false}
                      onChange={(e) => handleFilterChange('checkout_time__isnull', !e.target.checked)}
                    >
                      {"Checked Out"}
                    </Checkbox>
                  </div>
                </Form.Item>
              </Form>
            </Col>
            <Col span={8} key={'c2'}>
              <Form
                {...formItemLayout}
                className="w-100">
                <Form.Item label="Services" >
                  <Select
                    allowClear
                    mode="multiple"
                    size="default"
                    placeholder="All"
                    filterOption={(val, option) => {
                      return option.props.children.includes(val);
                    }}
                    defaultValue={filters.session_requests__service_id__in || []}
                    value={filters.session_requests__service_id__in}
                    onChange={(e) => handleFilterChange('session_requests__service_id__in', e)}>
                    {allServices.map(s =>
                      (<Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>)
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Paid">
                  <Select
                    allowClear
                    size="default"
                    placeholder="All"
                    style={{ maxWidth: '12rem' }}
                    value={filters.is_paid}
                    onChange={(e) => handleFilterChange('is_paid', e)}>
                    <Select.Option key={1} value={'true'}>Paid</Select.Option>
                    <Select.Option key={2} value={'false'}>Not Paid</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <Col span={10} key={'c3'}>
              <Form
                {...formItemLayout}
                className="w-100">
                <Form.Item label="Staff" className="w-100">
                  <Select
                    allowClear
                    mode="multiple"
                    size="default"
                    placeholder="All"
                    defaultValue={filters.session_requests__staff_id__in || []}
                    value={filters.session_requests__staff_id__in}
                    filterOption={(val, option) => {
                      return option.props.children.includes(val);
                    }}
                    onChange={(e) => handleFilterChange('session_requests__staff_id__in', e)}
                  >
                    {allStaff.map(s =>
                      (<Select.Option key={s.id} value={s.id}>{user_fullname(s)}</Select.Option>)
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Customers" >
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    size="default"
                    placeholder="All"
                    value={filters.customer_id__in}
                    filterOption={false}
                    onChange={(e) => handleFilterChange('customer_id__in', e)}
                    onSearch={(e) => setCustomerSearchTerm(e)}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                  >
                    {allCustomers.map(s =>
                      (<Select.Option key={s.id} value={s.id}>{user_fullname(s)}</Select.Option>)
                    )}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row style={{ maxWidth: '1280px' }} key="r2">
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={applyFilters}>
                Apply
            </Button>
              <Button style={{ marginLeft: 8 }} onClick={handleResetFilters}>
                Clear
            </Button>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <TableToolbar onRefresh={fetchActiveSessions} onSearch={(value) => setSearchTerm(value)} />
      <Table
        expandedRowRender={expandedRowRender}
        loading={fetchingData}
        style={{ border: '1px solid #e8e8e8', borderRadius: 4 }}
        pagination={{ style: { marginRight: 16 } }}
        columns={[
          {
            title: 'Checkin Type',
            key: "checkin_type",
            render: (record) =>
              <Typography.Text >{titleCase(record.checkin_type)}</Typography.Text>
          },
          {
            title: 'Checkin Time',
            key: "checkin",
            render: (record) => <div>{humanizeDate(record.checkin_time)}</div>
          },
          {
            title: 'Checkout Time',
            key: "checkout",
            render: (record) => <div>{record.checkout_time ? humanizeDate(record.checkout_time) : '---'}</div>
          },
          // {
          //   title: 'Provider',
          //   key: "provider",
          //   render: (record) => (
          //     <div className="d-flex flex-column">
          //       <div>{user_fullname(record.services_requested[0].staff_obj)}</div>
          //       <Typography.Text type="secondary">{record.services_requested[0].staff_obj.email || "---"}</Typography.Text>
          //     </div>
          //   )
          // },
          {
            title: 'Customer',
            key: "customer",
            render: (record) => {
              const customer_obj = record.customer_obj || {};
              return (
                <div className="d-flex flex-column">
                  <div>{user_fullname(customer_obj || {})}</div>
                  {/* <Typography.Text type="secondary">{customer_obj.email || "---"}</Typography.Text> */}
                  <Typography.Text type="secondary">{customer_obj.phone || "---"}</Typography.Text>
                </div>
              );
            }
          },
          // {
          //   title: 'Amount',
          //   key: "amount",
          //   render: (record) => <Typography.Text>Rs. {record.amount}/-</Typography.Text>
          // },
          {
            title: 'Status Name',
            key: "status_name",
            render: (record) => <Typography.Text>{record.status_name || "---"}</Typography.Text>
          },
          {
            title: 'Payment',
            key: "payment",
            render: (record) =>
              <Tag
                color={record.is_paid ? "green" : "red"}
                style={{ width: 80 }}
                className="text-center">{record.is_paid ? "Paid" : "Not Paid"}</Tag>
          },
          // {
          //   title: 'Action',
          //   key: "action",
          //   render: (record) =>
          //     <Button
          //       loading={fetchingData}
          //       onClick={() => openSessionModal(record, true)}>Modify</Button>
          // },
          // {
          //   title: 'Bill',
          //   key: "bill",
          //   render: (record) =>
          //     <Button
          //       loading={fetchingData}
          //       onClick={() => toggleBillModal(record)}>Bill</Button>
          // }
        ]}
        pagination={{
          style: { marginRight: 16 },
          onChange: (page, size) => setTableQuery({ ...tableQuery, page }),
          defaultCurrent: 1,
          current: tableQuery.page,
          total: tableQuery.total,
          pageSize: tableQuery.page_size,
        }}
        dataSource={sessions}
        scroll={{ x: isMobile ? true : false }} />
      <EaseModal
        title={sessionModalData.session && sessionModalData.session.checking_type === 'appointment' ? "Appointment" : "Walkin"}
        hideFooter
        visible={sessionModalData.isVisible}
        onCancel={closeSessionModal}
      >
        <SessionForm
          sessionType={sessionModalData.session ? sessionModalData.session.checking_type : undefined}
          disableCustomer={true}
          // defaultCustomer={this.state.activeSession.contact_obj}
          session={sessionModalData.session}
          afterSubmit={fetchActiveSessions}
        />
      </EaseModal>
    </div >
  );
};

export default ActiveSessions;