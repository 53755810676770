import React, { Component } from 'react';
import { Form, Input, Avatar, Button } from 'antd';
import { auth, BusinessService, UserService } from '../../../services';
import { alertify, handleApiExceptions } from '../../../app_methods';

class CustomerForm extends Component {
    state = {
        processing: false
    };

    isFormInitialized = false;

    componentDidUpdate() {
        if (!this.isFormInitialized) {
            this.props.form.setFieldsValue({ customer: this.props.customer });
            this.isFormInitialized = true;
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.customer.id !== nextProps.customer.id)
            this.props.form.setFieldsValue({ customer: nextProps.customer });
    }

    updateCustomer(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) { return; }
            this.setState({ processing: true });
            UserService.update({ ...values.customer, id: this.props.customer.id }).then((response) => {
                if (response.success) {
                    alertify("Awesome!!!", "Customer updated successfully", "success");
                    this.setState({ processing: false });
                    this.props.afterSubmit(response.data);
                }
                else {
                    alertify("Oops!!!", response.message, "error");
                    this.setState({ processing: false });
                }
            }).catch((err) => {
                handleApiExceptions(err);
                this.setState({ processing: false });
            });
        });
    }

    createCustomer(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) { return; }
            if (this.props.customer.is_edit) {
                this.updateCustomer(e);
                return;
            }
            this.setState({ processing: true });
            UserService.create({ ...values.customer, role: "customer" }).then((response) => {
                if (response.success) {
                    alertify("Awesome!!!", "Customer updated successfully", "success");
                    this.setState({ processing: false });
                    this.props.afterSubmit(response.data);
                }
                else {
                    alertify("Oops!!!", response.message, "error");
                    this.setState({ processing: false });
                }
            }).catch((err) => {
                handleApiExceptions(err);
                this.setState({ processing: false });
            });
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="mx-auto mt-4" style={{ width: "80%" }}>
                <Form layout="vertical" onSubmit={this.createCustomer.bind(this)}>
                    {getFieldDecorator('customer.merchant', { initialValue: auth.user.merchant.id })(
                        <Input hidden />
                    )}
                    {/* <Form.Item className="text-center">
                        {getFieldDecorator('customer.avatar', {})(
                            <Avatar style={{ width: 120, height: 120 }} />
                        )}
                    </Form.Item> */}
                    <div className="d-flex">
                        <Form.Item label="First Name" className="w-50">
                            {getFieldDecorator('customer.first_name', {
                                rules: [{
                                    required: true, message: 'Please input first name.',
                                }]
                            })(
                                <Input disabled={this.state.processing} placeholder="First name" />
                            )}
                        </Form.Item>
                        <Form.Item label="Last Name" className="ml-1 w-50">
                            {getFieldDecorator('customer.last_name', {
                                rules: [{
                                    required: true, message: 'Please input last name.',
                                }]
                            })(
                                <Input disabled={this.state.processing} placeholder="Last name" />
                            )}
                        </Form.Item>
                    </div>
                    <Form.Item label="Email" >
                        {getFieldDecorator('customer.email', {
                            rules: [
                                { required: true, message: 'Please input email.' },
                                { pattern: /\S+@\S+\.\S+/, message: "Email is not a valid format." }
                            ]
                        })(
                            <Input type="email" disabled={this.state.processing} placeholder="Email Address" />
                        )}
                    </Form.Item>
                    <Form.Item label="Mobile Number" >
                        {getFieldDecorator('customer.phone', {
                            rules: [
                                { required: true, message: 'Please input mobile number.' },
                                { pattern: /^[0-9]\w{9}\b$/, message: "Please input valid mobile number." }
                            ]
                        })(
                            <Input type="number" disabled={this.state.processing} placeholder="Mobile Number" />
                        )}
                    </Form.Item>

                    <Form.Item className="float-right">
                        <Button
                            loading={this.state.processing}
                            htmlType="submit"
                            type="primary">{this.props.customer.is_edit ? "Update Customer" : "Add Customer"}</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

CustomerForm.defaultProps = {
    afterSubmit: () => { },
    avatar: true
};


const FormWrapper = Form.create()(CustomerForm);
export default FormWrapper;
