import { LocalStorage } from './LocalStorage';
import _isEmpty from 'lodash/isEmpty';
let
  auth = {};
const storage = new LocalStorage();

class Authentication {
  constructor() {
    this.user = storage.get('current_user') || {};
  }

  is_loggedin() {
    return _isEmpty(this.user) === false;
  }

  is_admin() {
    return this.user.role.role_type === 'admin';
  }

  is_staff() {
    return this.user.role.role_type === 'staff';
  }

  settings() {
    return this.user.merchant_settings || {};
  }

  permissions() {
    if (!this.is_loggedin()) { return []; }
    // const permissions = {};
    // (this.user.user_permissions || []).forEach(permission => {
    //   permissions[permission] = this.is_staff() ? "SELF" : "ALL";
    // });
    // return permissions;
    if (this.is_staff()) {
      return {
        "APPOINTMENT_CALENDAR": "SELF",
        "DAILY_QUEUE": "SELF",
        "DAILY_QUEUE:WALKINS": "SELF",
        "DAILY_QUEUE:APPOINTMENTS": "SELF",
        "DAILY_QUEUE:QUEUE": "SELF",
        "SETTINGS": "ALL",
        "SETTINGS:STAFF": "SELF"
      };
    } else {
      return {
        "APPOINTMENT_CALENDAR": "ALL",
        "APPOINTMENT_CALENDAR:ADD": "ALL",
        "APPOINTMENT_CALENDAR:EDIT": "ALL",
        "DAILY_QUEUE": "ALL",
        "DAILY_QUEUE:WALKINS": "ALL",
        "DAILY_QUEUE:WALKINS:ADD": "ALL",
        "DAILY_QUEUE:APPOINTMENTS": "ALL",
        "DAILY_QUEUE:APPOINTMENTS:ADD": "ALL",
        "DAILY_QUEUE:APPOINTMENTS:EDIT": "ALL",
        "DAILY_QUEUE:QUEUE": "ALL",
        "DAILY_QUEUE:QUEUE:EDIT": "ALL",
        "CUSTOMER_MASTER": "ALL",
        "CUSTOMER_MASTER:ADD": "ALL",
        "CUSTOMER_MASTER:EDIT": "ALL",
        "CUSTOMER_MASTER:APPOINTMENTS": "ALL",
        "CUSTOMER_MASTER:APPOINTMENTS:ADD": "ALL",
        "CUSTOMER_MASTER:APPOINTMENTS:EDIT": "ALL",
        "SETTINGS": "ALL",
        "SETTINGS:ACCOUNT": "ALL",
        "SETTINGS:STAFF": "ALL",
        "SETTINGS:STAFF:ADD": "ALL",
        "SETTINGS:SERVICE": "ALL",
        "SETTINGS:SERVICE:ADD": "ALL",
        "SETTINGS:SERVICE:EDIT": "ALL"
      };
    }
  }

  set_settings(settings) {
    this.user.merchant_settings = settings;
    this.set_user(this.user);
  }

  set_user(user) {
    storage.set('current_user', user);
    this.user = user;
  }

  remove_user() {
    storage.remove('auth_headers');
    storage.remove('current_user');
    this.user = {};
  }
}

const initAuthentication = () => {
  auth = new Authentication();
}

initAuthentication();

export { auth };
