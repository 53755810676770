import React, { Component } from 'react';
import { Layout } from 'antd';
import './index.scss';
const { Content } = Layout;

class BlankLayout extends Component {
  render() {
    return (
      <div className="layout-container">
        <Layout className="blank-layout-content-wrapper">
          <Content>
            {React.cloneElement(this.props.children)}
          </Content>
        </Layout>
      </div>
    )
  }
}

export default BlankLayout;
