import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import './index.css';

class EaseModal extends Component {
    state = {
        spinner: false
    };

    componentWillReceiveProps(nextProps) {
        if (!this.props.visible && nextProps.visible) {
            nextProps.onOpen();
        }
    }

    spinner(spinnerState) {
        this.setState({ spinner: spinnerState });
    }

    render() {
        const { props } = this;
        return (
            <Modal
                className={`${props.className}`}
                visible={props.visible}
                title={props.title}
                closable={props.closable}
                centered={props.centered}
                maskClosable={props.maskClosable}
                width={props.width}
                onCancel={props.onCancel}
                destroyOnClose={props.destroyOnClose}
                footer={props.hideFooter ? null : [
                    ((!props.hideCancel) ? <Button key="cancel" onClick={props.onCancel}>{props.cancelText}</Button> : null),
                    ((!props.hideOk) ? <Button key="ok" type="primary" onClick={props.onOk}>{props.okText}</Button> : null),
                ]}
            >
                <div style={{ position: 'relative' }}>
                    {
                        this.props.children
                    }
                </div>
            </Modal>
        );
    }
}

EaseModal.defaultProps = {
    visible: false,
    centered: true,
    closable: true,
    hideCancel: false,
    hideOk: false,
    hideFooter: false,
    maskClosable: true,
    destroyOnClose: true,
    className: "",
    title: "Modal Title",
    okText: "Ok",
    cancelText: 'Cancel',
    width: 520,
    onCancel: () => { },
    onOk: () => { },
    onOpen: () => { }
};

export { EaseModal };