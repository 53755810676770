import React from 'react';
import { Layout, Menu, Dropdown, Avatar } from 'antd';
import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { isMobile, MobileView } from 'react-device-detect';
import Text from 'antd/lib/typography/Text';
import { auth } from '../../services';

const SuperAdminNavbar = (props) => {
    const { Header } = Layout;
    return (
        <Header className="am-navbar lobo-bg-white d-flex justify-content-between align-items-center" style={{ ...styles.header, paddingLeft: props.sidebarCollapsed ? 0 : 80 }}>
            <div>
                <MobileView>
                    <div className="d-flex">
                        <FAI icon={faBars} color="#fff" size="2x" className="c-pointer ml-2 sidebar-trigger" onClick={props.toggleSidebar.bind(this)} />
                    </div>
                </MobileView>
            </div>
            <div>
                <Dropdown trigger={['click', 'hover']} placement="bottomLeft" overlay={(
                    <Menu>
                        {/* <Menu.Item onClick={props.redirect.bind(this, '/')}>
                            <FAI icon={faUserGraduate} color="grey" /><span className="ml-2">Profile</span>
                        </Menu.Item>
                        <Menu.Item onClick={props.redirect.bind(this, '/')}>
                            <FAI icon={faLock} color="grey" /><span className="ml-2">Change Password</span>
                        </Menu.Item> */}
                        <Menu.Item onClick={props.redirect.bind(this, '/users/login', () => { auth.remove_user() })}>
                            <FAI icon={faPowerOff} color="grey" /><span className="ml-2">Logout</span>
                        </Menu.Item>
                    </Menu>
                )}>
                    <span className={`c-pointer user-avatar-name ant-dropdown-link pr-2 pl-2 ${!props.sidebarCollapsed && isMobile ? 'd-none' : ''}`} href="#" style={{ minWidth: 120, display: 'block' }}>
                        <Avatar icon="user" className="mr-2" />
                        <Text className="color-white">Karan Bhutwala</Text>
                    </span>
                </Dropdown>
            </div>
        </Header>
    )
}

const styles = {
    header: {
        padding: 0,
        height: "60px",
        lineHeight: "60px",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9,
        transition: 'all .2s',
        WebkitTransition: 'all .2s'
    }
}

export { SuperAdminNavbar };