import React from 'react';
import Card from 'antd/lib/card';
import './index.scss';

const ContentCard = (props) => {
    return (
        <Card className={`eb-content-card shadow-sm ${props.className}`} bordered={props.bordered}>
            {props.children}
        </Card>
    )
}

ContentCard.defaultProps = {
    bordered: true,
    className: ""
}

export default ContentCard;