import { get, post, patch, put } from '../HttpService';

export class SessionService {
    static list = (queryData = {}, config = {}) => {
        return get(`/v1/admin/session/`, queryData, config);
    };
    static create = (queryData = {}, config = {}) => {
        return post(`/v1/admin/session/`, queryData, config);
    };
    static update = (queryData = {}, config = {}) => {
        return patch(`/v1/admin/session/${queryData.id}/?checkin_type=${queryData.checkin_type}`, queryData, config);
    };
    static checkout = (queryData = {}, config = {}) => {
        return put(`/v1/admin/session/check_out/?session_id=${queryData.id}`, queryData, config);
    };
    static cancelSession = (queryData = {}, config = {}) => {
        return patch(`/v1/admin/session/cancel_session/?session_id=${queryData.id}`, queryData, config);
    };
    static updateServicesProvided = (queryData = {}, config = {}) => {
        return post(`/v1/admin/session/update_services_provided/`, queryData, config);
    };
}
