import React, { Component } from 'react';
import { Form, DatePicker, Select, Input, Divider, Button, Typography, Checkbox, Popover } from 'antd';
import moment from 'moment/moment';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons/faRupeeSign';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { UserService, BusinessService, auth, AppointmentService, BreakHourService, WorkingHourService, HolidayService, SessionService } from '../../../services';
import { handleApiExceptions, user_fullname, alertify, get_disabled_date, amAlert, get_unavailable_hours, get_unavailable_dates, getCustomerSearchType } from '../../../app_methods';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EaseModal } from '../../../libs/EaseModal';
import { TimePicker, CustomerModalForm } from '../index';
const formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 5 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 19 }, }, };

class SessionForm extends Component {
  state = {
    customers: [],
    selectedCustomer: '',
    services: [],
    staffList: [],
    breakHours: [],
    workHours: [],
    staffAppointmentForSelectedDate: [],
    disabledHours: [],
    processing: false,
    holidays: [],
    searchingCustomers: false,
    customer: {},
    customerPopoverVisible: false,
    customerSearchTerm: '',
  };
  isFormInitialized = false;
  searchCustomerTimeout = null;

  componentDidMount() {
    // this.setSessionForm();
    console.log('session form, componentdidMount');
    this.initRemoteData();
  }

  componentWillUnmount() {
    clearTimeout(this.searchCustomerTimeout);
  }

  initRemoteData() {
    this.fetchCustomers();
    this.fetchServices();
    this.fetchHolidays();
    if (this.props.session.is_edit && this.props.session.services_requested) {
      const { staff, service } = this.props.session.services_requested[0];
      this.fetchStaffBreakHours(staff);
      this.fetchStaffWorkingHours(staff);
      this.fetchStaffAppointments(staff, moment(this.props.session.schedule_date));
      this.fetchServiceUsers(service.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.isFormInitialized) {
      this.setSessionForm();
      this.isFormInitialized = true;
    }
    if (!_isEqual(prevProps.session, this.props.session)) {
      this.initRemoteData();
    }
  }

  setSessionForm() {
    const session = this.props.session;
    console.log('set session form ', session);
    console.log('holidays', this.state.holidays);
    console.log('user', auth.user);
    const { merchant_settings } = auth.user;
    session.schedule_date = moment(session.scheduled_at);
    session.schedule_time = moment(session.scheduled_at);
    // if new appointment, and current time > business hours
    // then set the next day's start time
    if (!session.id && this.props.sessionType === 'appointment') {
      if (merchant_settings && merchant_settings.business_hours) {
        const end = merchant_settings.business_hours.end;
        const start = merchant_settings.business_hours.start;
        const timeFormat = "HH:mm";
        if (moment(end, timeFormat).isSameOrBefore(moment())) {
          const hours = Number(start.split(':')[0]);
          const minutes = Number(start.split(':')[1]);
          const new_date = moment().add(1, 'days').hours(hours).minutes(minutes).startOf('minute');
          session.schedule_date = new_date;
          session.schedule_time = new_date;
        }
      }
    }
    console.log('init date time', session);
    this.props.form.setFieldsValue({ session: session });
  }

  fetchHolidays() {
    HolidayService.list({ merchant: auth.user.merchant.id }).then((response) => {
      if (response.success) {
        this.setState({ holidays: response.data });
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  }

  fetchCustomers(searchTerm = '', selectedCustomerId = null) {
    let queryData = { merchant: auth.user.merchant.id };
    if (searchTerm) {
      queryData.search = searchTerm;
    }
    UserService.getCustomerList(queryData).then((response) => {
      if (response.success) {
        const selectedCustomer = selectedCustomerId ? selectedCustomerId : this.state.selectedCustomer;
        this.setState({ customers: response.data.results, searchingCustomers: false, selectedCustomer });
      }
    }).catch((error) => {
      this.setState({ searchingCustomers: false });
      handleApiExceptions(error);
    });
  }

  fetchServices() {
    const filters = {};
    if (auth.is_staff()) {
      filters['users__id'] = auth.user.id;
    }
    BusinessService.list({ merchant: auth.user.merchant.id, ...filters }).then((response) => {
      if (response.success) {
        this.setState({ services: response.data }, () => {
          if (this.props.session.is_edit && this.props.session.services_requested) {
            this.handleServiceChange(this.props.session.services_requested[0].service.id);
          }
        });
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  }

  fetchServiceUsers(service_id) {
    BusinessService.getUsers({ id: service_id }).then((response) => {
      if (response.success) {
        this.setState({ staffList: response.data });
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  }

  fetchStaffBreakHours(id) {
    BreakHourService.list({ staff: id }).then((response) => {
      if (response.success) {
        this.setState({ breakHours: response.data });
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  }

  fetchStaffWorkingHours(id) {
    WorkingHourService.list({ staff: id }).then((response) => {
      if (response.success) {
        this.setState({ workHours: response.data });
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  }

  fetchStaffAppointments(staff, schedule_date) {
    staff = staff || (auth.is_staff() ? auth.user.id : this.props.form.getFieldValue('session.services_requested[0].staff'));
    schedule_date = schedule_date || this.props.form.getFieldValue('session.schedule_date') || moment();

    AppointmentService.list({
      scheduled_at__gte: schedule_date.startOf('day').format("YYYY-MM-DD HH:mm"),
      scheduled_at__lte: schedule_date.endOf('day').format("YYYY-MM-DD HH:mm"),
      session_requests__staff: staff
    }).then((response) => {
      if (response.success) {
        this.setState({ staffAppointmentForSelectedDate: response.data.results });
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  }

  handleServiceChange(value) {
    const selected_service = this.state.services.find(x => x.id == value) || {};
    const selected_duration_minutes = moment(this.props.session.end).diff(moment(this.props.session.start), 'minutes');
    const service_minutes = (selected_duration_minutes > selected_service.service_minutes) ? selected_duration_minutes : selected_service.service_minutes;

    this.props.form.setFieldsValue({
      session: {
        service_minutes: service_minutes,
      }
    });

    return value;
  }

  handleStaffChange(value) {
    this.fetchStaffBreakHours(value);
    this.fetchStaffWorkingHours(value);
    this.fetchStaffAppointments(value, undefined);
    return value;
  }

  handleAppointmentDateChange(value) {
    this.fetchStaffAppointments(undefined, value);
    return value;
  }

  isDateTimeFieldsDisabled = () => {
    if (this.props.sessionType === 'appointment') {
      return auth.is_staff() && this.props.session.is_edit;
    } else if (this.props.sessionType === 'walkin') {
      if (this.props.session.schedule_for_later) {
        return auth.is_staff() && this.props.session.is_edit;
      }
    }
    return false;
  };

  handleSearch = (searchTerm) => {
    if (searchTerm.length > 2) {
      this.setState({ searchingCustomers: true, customerSearchTerm: searchTerm });
      this.searchCustomerTimeout = setTimeout(() => {
        this.fetchCustomers(searchTerm);
      }, 650);
      return;
    }
    this.setState({ customerSearchTerm: searchTerm });
  };

  customerPopoverToggle = () => {
    this.setState({ customerPopoverVisible: !this.state.customerPopoverVisible });
  };

  addCustomer = () => {
    const { customer } = this.state;
    if (!customer.first_name || !customer.last_name ||
      !customer.email || !customer.first_name.trim() ||
      !customer.last_name.trim() || !customer.email.trim() || !customer.phone) {
      alertify("Oops!!!", "Customer name and email and phone is mandatory.", "error");
      return;
    } else if (customer.phone.length < 10) {
      alertify("Oops!!!", "Invalid Phone Number. Please re-enter.", "error");
      return;
    }
    UserService.create({
      ...this.state.customer, merchant: auth.user.merchant.id, role: 'customer'
    }).then((response) => {
      if (response.success) {
        this.fetchCustomers(response.data.email, response.data.id);
        this.setState({
          customer: {},
          selectedCustomer: response.data.id,
          customerPopoverVisible: false
        });
        alertify("Awesome", "Customer successfully created.", "success");
      } else {
        alertify("Oops!!!", "Could not add customer. Try again.", "error");
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  };

  handleCustomerFieldChange = (e) => {
    const customer = this.state.customer;
    customer[e.target.name] = e.target.value;
    this.setState({ customer });
  };

  handleMobileChange = (value) => {
    const customer = this.state.customer;
    customer['phone'] = value;
    this.setState({ customer });
  };

  renderNotFoundCustomerContent = () => {
    if (!this.state.searchingCustomers) {
      if (this.state.customers && this.state.customers.length === 0 &&
        this.state.customerSearchTerm &&
        this.state.customerSearchTerm.length > 2) {
        const searchType = getCustomerSearchType(this.state.customerSearchTerm);
        return (
          <div class="d-flex align-items-center justify-content-between">
            <span>{searchType ? searchType.description : ''}</span>
            <Button icon="plus" onClick={() => {
              if (searchType) {
                const customer = {};
                switch (searchType.type) {
                  case 'email': customer['email'] = this.state.customerSearchTerm; break;
                  case 'phone': customer['phone'] = this.state.customerSearchTerm; break;
                  case 'name':
                    const splitBySpace = this.state.customerSearchTerm.split(' ');
                    if (splitBySpace.length > 1) {
                      customer['last_name'] = splitBySpace[1];
                    }
                    customer['first_name'] = splitBySpace[0];
                    break;
                }
                this.setState({ customer }, () => {
                  this.customerPopoverToggle();
                });
              } else {
                this.customerPopoverToggle();
              }
            }} />
          </div>
        );
      }
    }
    return null;

  };

  createSession(e) {
    e.preventDefault();
    if (this.props.session.is_edit) {
      this.updateSession();
      return;
    }
    this.props.form.validateFields((err, values) => {
      if (err) { return; }
      this.setState({ processing: true });
      const appointment = values.session;
      appointment.scheduled_at = `${appointment.schedule_date.format("YYYY-MM-DD")} ${appointment.schedule_time.format("HH:mm")}`;
      const serviceId = appointment.services_requested[0].service.id;
      delete appointment.services_requested[0].service;
      appointment.services_requested[0].service_id = serviceId;
      const payload = {
        ...appointment,
        merchant: auth.user.merchant.id,
        created_by: auth.user.id
      };
      if (this.props.sessionType === 'appointment') {
        payload.checkin_type = 'appointment';
        this.createAppointment(payload);
      } else {
        payload.checkin_type = 'walkin';
        this.createWalkin(payload);
      }
    });
  }

  updateSession() {
    console.log('update session called');
    this.props.form.validateFields((err, values) => {
      if (err) { return; }
      this.setState({ processing: true });
      const session = values.session;
      console.log('update session', session);
      const serviceId = session.services_requested[0].service.id;
      delete session.services_requested[0].service;
      session.services_requested[0].service_id = serviceId;
      session.scheduled_at = `${session.schedule_date.format("YYYY-MM-DD")} ${session.schedule_time.format("HH:mm")}`;
      const payload = {
        id: this.props.session.id,
        ...session,
        merchant: auth.user.merchant.id,
        created_by: auth.user.id
      };
      console.log(payload);
      if (this.props.sessionType === 'appointment') {
        payload.checkin_type = 'appointment';
        this.updateAppointment(payload);
      } else {
        payload.checkin_type = 'walkin';
        this.updateWalkin(payload);
      }
    });
  }

  createWalkin(payload) {
    SessionService.create(payload).then((response) => {
      if (response.success) {
        alertify("Awesome!!!", "Walkin created successfully", "success");
        this.setState({ processing: false });
        this.props.afterSubmit(response.data);
      }
      else {
        alertify("Oops!!!", response.message, "error");
        this.setState({ processing: false });
      }
    }).catch((err) => {
      if (err && err.response && err.response.status) {
        if (err.response.data &&
          err.response.data.message &&
          err.response.data.message.availability_status) {
          if (!err.response.data.message.availability_status.available ||
            err.response.data.message.availability_status.available == "False") {
            amAlert('Oops!!!', "Staff is not available on selected hours.", "warning");
          }
        }
      } else {
        handleApiExceptions(err);
      }
      this.setState({ processing: false });
    });
  }

  createAppointment(payload) {
    AppointmentService.create(payload).then((response) => {
      if (response.success) {
        alertify("Awesome!!!", "Appointment created successfully", "success");
        this.setState({ processing: false });
        this.props.afterSubmit(response.data);
      }
      else {
        alertify("Oops!!!", response.message, "error");
        this.setState({ processing: false });
      }
    }).catch((err) => {
      if (err && err.response && err.response.status) {
        if (err.response.data &&
          err.response.data.message &&
          err.response.data.message.availability_status) {
          if (!err.response.data.message.availability_status.available ||
            err.response.data.message.availability_status.available == "False") {
            amAlert('Oops!!!', "Staff is not available on selected hours.", "warning");
          }
        }
      } else {
        handleApiExceptions(err);
      }
      this.setState({ processing: false });
    });
  }

  updateWalkin(payload) {
    SessionService.update(payload).then((response) => {
      if (response.success) {
        alertify("Awesome!!!", "Walkin updated successfully", "success");
        this.setState({ processing: false });
        this.props.afterSubmit(response.data);
      }
      else {
        alertify("Oops!!!", response.message, "error");
        this.setState({ processing: false });
      }
    }).catch((err) => {
      if (err && err.response && err.response.status) {
        if (err.response.data && err.response.data.message &&
          err.response.data.message.availability_status) {
          if (!err.response.data.message.availability_status.available ||
            err.response.data.message.availability_status.available == "False") {
            amAlert('Oops!!!', "Staff is not available on selected hours.", "warning");
          }
        }
      } else {
        handleApiExceptions(err);
      }
      this.setState({ processing: false });
    });
  }

  updateAppointment(payload) {
    AppointmentService.update(payload).then((response) => {
      if (response.success) {
        alertify("Awesome!!!", "Appointment updated successfully", "success");
        this.setState({ processing: false });
        this.props.afterSubmit(response.data);
      }
      else {
        alertify("Oops!!!", response.message, "error");
        this.setState({ processing: false });
      }
    }).catch((err) => {
      if (err && err.response && err.response.status) {
        if (err.response.data && err.response.data.message &&
          err.response.data.message.availability_status) {
          if (!err.response.data.message.availability_status.available ||
            err.response.data.message.availability_status.available == "False") {
            amAlert('Oops!!!', "Staff is not available on selected hours.", "warning");
          }
        }
      } else {
        handleApiExceptions(err);
      }
      this.setState({ processing: false });
    });
  }

  getSubmitButtonText = () => {
    let text = this.props.session.is_edit ? 'Update ' : 'Create ';
    if (this.props.sessionType === 'appointment') {
      return text + 'Appointment';
    }
    return text + 'Walkin';
  };

  render() {
    console.log('selectedCustomer', this.state.selectedCustomer);
    console.log('customers', this.state.customers);
    const { form } = this.props;
    console.log(form);
    const { getFieldDecorator } = form;
    const appointment_statuses = auth.user.merchant.appointment_status || [];
    const settings = auth.settings();
    const schedule_date = form.getFieldValue('session.schedule_date') || moment();
    const selected_service = this.state.services.find(x => x.id === form.getFieldValue('session.services_requested[0].service.id'));
    const disabled_hours =
      get_unavailable_hours(schedule_date.format("YYYY-MM-DD"),
        this.state.workHours,
        this.state.breakHours,
        this.state.staffAppointmentForSelectedDate,
        selected_service);
    const disableld_dates = get_unavailable_dates(this.state.holidays);
    // console.log('selected_service', selected_service);
    // console.log('disableld_dates', disableld_dates);
    // console.log('disabled_hours', disabled_hours);
    return (
      <div>
        <Form {...formItemLayout} layout="horizontal" className="w-100" onSubmit={this.createSession.bind(this)}>
          <Form.Item label="Service">
            {
              getFieldDecorator('session.services_requested[0].service.id', {
                initialValue: selected_service ? selected_service.id : null,
                getValueFromEvent: this.handleServiceChange.bind(this),
                rules: [
                  { required: true, message: "Please select a service." }
                ]
              })(
                <Select disabled={auth.is_staff() && this.props.session.is_edit}
                  size="default" placeholder="Select Service"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    this.fetchServiceUsers(e);
                    form.setFieldsValue({ 'session.services_requested[0].staff': undefined });
                  }}>
                  {
                    this.state.services.map((service, index) => (
                      <Select.Option key={index} value={service.id}>{service.name}</Select.Option>
                    ))
                  }
                </Select>
              )}
          </Form.Item>
          <Form.Item label="Duration">
            {
              getFieldDecorator('session.service_minutes', {})(
                <Input disabled={auth.is_staff() && this.props.session.is_edit}
                  style={{ width: "8rem", }}
                  suffix={<strong>minutes</strong>} />
              )}
            <span style={{ textAlign: "right", width: "100%", display: "block", lineHeight: "20px", minHeight: 20 }}>
              {selected_service ? `Buffer Time: ${selected_service.buffer_minutes || 0} Minutes` : ''}
            </span>
          </Form.Item>
          {
            (auth.is_staff()) ? (
              <Form.Item label="Provider" className="w-100" >
                {
                  getFieldDecorator('session.services_requested[0].staff', {
                    initialValue: auth.user.id
                  })(
                    <Input hidden />
                  )}
                <Input disabled value={user_fullname(auth.user)} />
              </Form.Item>
            ) : (
                <Form.Item label="Provider" className="w-100" >
                  {
                    getFieldDecorator('session.services_requested[0].staff', {
                      initialValue: null,
                      rules: [
                        { required: true, message: "Please select a provider." }
                      ]
                    })(
                      <Select
                        size="default"
                        placeholder="Select Provider"
                        style={{ width: '100%' }}
                        onChange={this.handleStaffChange.bind(this)}
                        notFoundContent={
                          <Typography.Text type="danger">Select Service to fetch service providers.</Typography.Text>
                        }>
                        {
                          this.state.staffList.map((user, index) => (
                            <Select.Option key={index} value={user.id}>{user_fullname(user)}</Select.Option>
                          ))
                        }
                      </Select>
                    )}
                </Form.Item>
              )
          }
          <Form.Item label="Day/Time">
            <div className="d-flex">
              {getFieldDecorator('session.schedule_date', {
                initialValue: '',
                rules: [
                  { required: true, message: "Select date to create appointment." }
                ]
              })(
                <DatePicker
                  disabledDate={(current) => {
                    return (disableld_dates || []).includes(current.format("YYYY-MM-DD"));
                  }}
                  disabled={this.isDateTimeFieldsDisabled()}
                  style={{ width: "60%" }}
                  onChange={this.handleAppointmentDateChange.bind(this)}
                />
              )}
              {
                getFieldDecorator('session.schedule_time', {
                  initialValue: '',
                  rules: [
                    { required: true, message: "Select time to create appointment." }
                  ]
                })(
                  <TimePicker
                    disabledRanges={disabled_hours.unavailables || []}
                    minTime={disabled_hours.minTime || (settings.business_hours || {}).start}
                    maxTime={disabled_hours.maxTime || (settings.business_hours || {}).end}
                    disabled={this.isDateTimeFieldsDisabled()}
                    style={{ width: "40%" }}
                    className="ml-2"
                  />
                  // <TimePicker disabled={auth.is_staff() && this.props.session.is_edit} style={{ width: "40%" }} className="ml-2" format="HH:mm" />
                )}
            </div>
          </Form.Item>
          <Divider />
          <Form.Item label="Customer" >
            {
              getFieldDecorator('session.customer', {
                initialValue: this.props.defaultCustomer ? this.props.defaultCustomer : null,
                rules: [
                  { required: true, message: "Please select a customer." }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  allowClear
                  filterOption={false}
                  value={this.state.selectedCustomer}
                  onChange={(value) => {
                    if (!value) {
                      this.searchCustomerTimeout = setTimeout(() => {
                        this.fetchCustomers();
                      }, 650);
                    }
                    this.setState({ selectedCustomer: value });
                  }}
                  onSearch={this.handleSearch}
                  loading={this.state.searchingCustomers}
                  disabled={this.props.disableCustomer || (auth.is_staff() && this.props.session.is_edit)}
                  size="default"
                  placeholder="Select Customer"
                  notFoundContent={this.renderNotFoundCustomerContent()}
                  style={{ width: '100%' }} >
                  {
                    this.state.customers.map((customer, index) => (
                      <Select.Option key={index} value={customer.id}>{user_fullname(customer)}</Select.Option>
                    ))
                  }
                </Select>
              )}
          </Form.Item>
          <Form.Item label="Notes" >
            {getFieldDecorator('session.services_requested[0].note', {})(<Input disabled={auth.is_staff() && this.props.session.is_edit} />)}
          </Form.Item>
          {
            this.props.sessionType === 'appointment' &&
            <Form.Item label="Status" >
              {
                getFieldDecorator('session.status', {
                  initialValue: (appointment_statuses[1] ? appointment_statuses[1].id : null),
                })(
                  <Select size="default" placeholder="Select Status" style={{ width: '100%' }} >
                    {
                      (auth.user.merchant.appointment_status || []).map((status, index) => (
                        <Select.Option key={index} value={status.id}>{status.key_label}</Select.Option>
                      ))
                    }
                  </Select>
                )}
            </Form.Item>
          }
          <Form.Item label="Payment" >
            {getFieldDecorator('session.is_paid', {
              initialValue: this.props.session.is_paid,
              valuePropName: 'checked'
            })(
              <Checkbox>
                <Typography.Text>Paid</Typography.Text>
              </Checkbox>
            )}
          </Form.Item>
          {
            this.props.session.is_edit ? (
              <Form.Item label="Cancelation">
                {getFieldDecorator('session.is_cancelled', {
                  initialValue: this.props.session.is_cancelled,
                  valuePropName: 'checked'
                })(
                  <Checkbox>
                    <Typography.Text type="danger">
                      {this.props.sessionType === 'appointment' ? 'Cancel Appointment' : 'Cancel Walkin'}
                    </Typography.Text>
                  </Checkbox>
                )}
              </Form.Item>
            ) : null
          }
          <Form.Item label="Staff Notes" >
            {getFieldDecorator('session.services_requested[0].note_by_staff', {})(<Input.TextArea />)}
          </Form.Item>
          <Form.Item className="float-right">
            <Button type="primary" htmlType="submit">
              {
                this.getSubmitButtonText()
              }
            </Button>
          </Form.Item>
          <div className="clearfix"></div>
        </Form >
        <EaseModal
          title={"Add Customer"}
          hideFooter
          visible={this.state.customerPopoverVisible}
          onCancel={() => {
            this.fetchCustomers();
            this.customerPopoverToggle();
          }}
        >
          <CustomerModalForm
            fullWidth={true}
            customer={this.state.customer}
            handleFirstNameChange={this.handleCustomerFieldChange}
            handleLastNameChange={this.handleCustomerFieldChange}
            handleEmailChange={this.handleCustomerFieldChange}
            handleMobileChange={this.handleMobileChange}
            addCustomer={this.addCustomer} />
        </EaseModal>
      </div>
    );
  }
}

SessionForm.defaultProps = {
  afterSubmit: () => { },
  disableCustomer: false,
  defaultCustomer: undefined,
  submitText: undefined
};

const FormWrapper = Form.create()(SessionForm);
export default FormWrapper;