import { get, post, patch, put, httpDelete } from '../HttpService';

export class BusinessService {
    static list = (queryData = {}, config = {}) => {
        return get(`/v1/admin/service/`, queryData, config);
    }

    static create = (queryData = {}, config = {}) => {
        return post(`/v1/admin/service/`, queryData, config);
    }

    static update = (queryData = {}, config = {}) => {
        return patch(`/v1/admin/service/${queryData.id}/`, queryData, config);
    }

    static getUsers = (queryData = {}, config = {}) => {
        return get(`/v1/admin/service/${queryData.id}/users/`, {}, config);
    }

    static delete = (queryData = {}, config = {}) => {
        return httpDelete(`/v1/admin/service/${queryData.id}/`, {}, config);
    }

    static setUsers = (queryData = {}, config = {}) => {
        return put(`/v1/admin/service/${queryData.id}/update_users/`, queryData, config);
    }

    static update_icon = (queryData = {}, config = {}) => {
        return put(`/v1/admin/service/${queryData.id}/update_image/`, queryData.formdata, config);
    }
}