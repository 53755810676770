import React, { Component } from 'react';
import { Card, Avatar, Typography, Icon } from 'antd';
import './index.scss';
import { BookingForm } from '../../Common/Forms';
import { MerchantService } from '../../../services';
import { handleApiExceptions, resolve_server_image } from '../../../app_methods';
import { WindowSizes, useWindowDimensions } from '../../../utils/windowDimesions';

class Booking extends Component {
    state = {
        merchant: {},
        cardStyles: styles.desktopCard
    };

    componentDidMount() {
        this.merchantUid = this.props.match.params.merchant_uid;
        this.fetchMerchant(this.merchantUid);
        const { winsize, height } = useWindowDimensions();
        this.winsize = winsize;
        this.setState({cardStyles: this.winsize < WindowSizes.MD ? styles.mobileCard : styles.desktopCard});
        console.log(this.state.cardStyles, this.winsize)
        const params = new URLSearchParams(this.props.location.search);
        this.state.hideNavbar = params.get('framed') || false;
    }

    fetchMerchant(uid) {
        MerchantService.list({ uid }).then((response) => {
            if (response.success) {
                this.setState({ merchant: response.data.length ? response.data[0] : {} });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    }

    render() {
        if (!this.state.merchant.id) { return <div>Loading...</div>; }
        return (
            <div style={{ height: '100%', overflow: 'auto' }}>
                <div style={{ width: 1200, marginTop: 24, maxWidth: "90%" }} className="mx-auto booking-wrapper">
                    <div className="merchant-info d-flex align-items-center justify-content-between px-3 mb-2 header">
                        {!this.state.hideNavbar && 
                        <div className="d-flex align-items-center">
                            {/* <Avatar size="large" src={this.state.merchant.image ? resolve_server_image(this.state.merchant.image) : require('../../../assets/images/default.png')} /> */}
                            <span class="ant-avatar ant-avatar-lg ant-avatar-circle ant-avatar-image" style={styles.logoStyles}>
                                <img src={this.state.merchant.image ? resolve_server_image(this.state.merchant.image) : require('../../../assets/images/default.png')} />

                            </span>
                            <Typography.Text strong className="ml-3" style={{ fontSize: 28 }}>{this.state.merchant.name}</Typography.Text>
                        </div>
                        }
                        {/* <div className="d-flex flex-column">
                            <Typography.Text><Icon type="mobile" className="mr-2" />{this.state.merchant.spoc_number || "---"}</Typography.Text>
                            <Typography.Text><Icon type="mail" className="mr-2" />{this.state.merchant.spoc_email || "---"}</Typography.Text>
                        </div> */}
                    </div>
                    <div style={this.state.cardStyles} className="mx-auto bookingFormCard">
                        <BookingForm merchant={this.state.merchant} />
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    mobileCard: {
        // borderRadius: 15,
        height: "calc(100vh - 70px)"
    },
    desktopCard: { 
        // borderRadius: 15,
        height: "calc(100vh - 195px)",
        // minHeight: 600 
    },
    logoStyles: {
        width: "28px",
        height: "28px"
    }
}
export default Booking;