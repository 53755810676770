import React, { Component } from 'react';
import './index.scss';
import { Typography, Button, Popover, Input, List, Avatar, Empty } from 'antd';

class CustomerList extends Component {
    render() {
        console.log(this.props.customers);
        return (
            <div>
                <div className="title d-flex align-items-center justify-content-between">
                    <Typography.Title level={4}>Customers</Typography.Title>
                    {
                        (!this.props.searchValu || this.props.searchValue.length > 2) &&
                        this.props.customers &&
                        this.props.customers.length > 0 &&
                        <Popover placement="bottom" visible={this.props.customerPopoverVisible}
                            title={
                                <div className="d-flex align-items-center justify-content-between">
                                    <Typography.Text strong>Add Customer</Typography.Text>
                                    <span className="c-pointer" onClick={this.props.customerPopoverToggle}>x</span>
                                </div>
                            }
                            content={this.props.renderAddCustomer()}>
                            <Button icon="plus" onClick={this.props.customerPopoverToggle} />
                        </Popover>
                    }
                </div>
                <div style={{ paddingTop: 16 }}>
                    <Input.Search
                        placeholder="Name, Email, Mobile"
                        loading={this.props.searchLoading}
                        onChange={this.props.onSearchInputChange}
                        onSearch={this.props.onSearch}
                        value={this.props.searchValue}
                        allowClear />
                </div>
                <div className="list">
                    {
                        this.props.customers &&
                        this.props.customers.length > 0 &&
                        <List
                            bordered={false}
                            dataSource={this.props.customers}

                            renderItem={item => (
                                <List.Item className={`mb-1 customer-item c-pointer ${item.id === this.props.activeCustomer.id ? 'active' : ''}`}
                                    onClick={this.props.selectCustomer.bind(this, item)}>
                                    <div className="d-flex align-items-center justify-content-between w-100 px-2">
                                        <div className="d-flex align-items-center">
                                            <Avatar icon="user" />
                                            <Typography.Text className="ml-3 customer-name">{item.first_name} {item.last_name}</Typography.Text>
                                        </div>
                                        {/* <Button icon="delete" className="customer-delete-btn" /> */}
                                    </div>
                                </List.Item>)}
                        />
                    }
                    {
                        this.props.searchValue.length < 2 &&
                        this.props.customers && this.props.customers.length === 0 &&
                        <Empty />
                    }
                    {
                        this.props.searchValue.length > 2 &&
                        this.props.customers && this.props.customers.length === 0 &&
                        <Empty description={
                            this.props.getEmptyListDescription ?
                                this.props.getEmptyListDescription.description : false}>
                            <Popover placement="bottom" visible={this.props.customerPopoverVisible}
                                title={
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Typography.Text strong>Add Customer</Typography.Text>
                                        <span className="c-pointer" onClick={this.props.customerPopoverToggle}>x</span>
                                    </div>
                                }
                                content={this.props.renderAddCustomer()}>
                                <Button icon="plus" onClick={this.props.onAddWithEmptySearchResult} />
                            </Popover>
                        </Empty>
                    }
                </div>
            </div>
        );
    }
}

CustomerList.defaultProps = {
    activeCustomer: {}
};

export default CustomerList;