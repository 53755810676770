import React, { Component } from 'react';
import './index.scss';
import { PermissionHoc } from '../../../HOC';
import { Typography, Button, Popover, List, Avatar, Icon } from 'antd';
const PermissableButton = PermissionHoc(Button, '', { is_component: true, fallbackComponent: null });

class StaffList extends Component {
    render() {
        return (
            <div>
                <div className="title d-flex align-items-center justify-content-between">
                    <Typography.Title level={4}>Staff Members</Typography.Title>
                    {
                        this.props.allowAdd ? (
                            <Popover placement="bottom" visible={this.props.addStaffVisible} title={(
                                <div className="d-flex align-items-center justify-content-between">
                                    <Typography.Text strong>Add Staff</Typography.Text>
                                    <Icon type="close" onClick={this.props.toggleAddStaff} />
                                </div>
                            )} content={this.props.renderAddStaff()}>
                                <PermissableButton required_permission={["SETTINGS:STAFF:ADD"]} icon="plus" onClick={this.props.toggleAddStaff} />
                            </Popover>
                        ) : null
                    }
                </div>
                <div className="list">
                    <List
                        bordered={false}
                        dataSource={this.props.staffList}
                        renderItem={(item, index) => (
                            <>
                                {
                                    this.props.showAllVisible && index === 0 ? (
                                        <List.Item className={`mb-1 staff-item c-pointer ${!this.props.activeStaff.id ? 'active' : ''}`} onClick={this.props.onStaffSelect.bind(this, {})}>
                                            <div className="d-flex align-items-center justify-content-between w-100 px-2">
                                                <div className="d-flex align-items-center">
                                                    <Avatar icon="user" />
                                                    <Typography.Text className="ml-3 staff-name">All Staff</Typography.Text>
                                                </div>
                                                {/* <Button icon="delete" className="staff-delete-btn" /> */}
                                            </div>
                                        </List.Item>
                                    ) : null
                                }
                                <List.Item className={`mb-1 staff-item c-pointer ${item.id === this.props.activeStaff.id ? 'active' : ''}`} onClick={this.props.onStaffSelect.bind(this, item)}>
                                    <div className="d-flex align-items-center justify-content-between w-100 px-2">
                                        <div className="d-flex align-items-center">
                                            <Avatar icon="user" />
                                            <Typography.Text className="ml-3 staff-name">{item.first_name} {item.last_name}</Typography.Text>
                                        </div>
                                        {/* <Button icon="delete" className="staff-delete-btn" /> */}
                                    </div>
                                </List.Item>
                            </>
                        )}
                    />
                </div>
            </div>
        )
    }
}

StaffList.defaultProps = {
    allowAdd: true,
    showAllVisible: false
}

export default StaffList;