import { get, post, httpDelete } from '../HttpService';

export class HolidayService {
    static list = (queryData = {}, config = {}) => {
        return get(`/v1/admin/merchant_holiday/`, queryData, config);
    }
    static create = (queryData = {}, config = {}) => {
        return post(`/v1/admin/merchant_holiday/`, queryData, config);
    }
    static delete = (queryData = {}, config = {}) => {
        return httpDelete(`/v1/admin/merchant_holiday/${queryData.id}/`, queryData, config);
    }
}
