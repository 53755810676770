import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

export class WindowSizes {
    static XS = 0;
    static SM = 1;
    static MD = 2;
    static LG = 3;
    static XL = 4;
    static XXL = 5;
}


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  let winsize = WindowSizes.MD;
  if (width < 576) {
    winsize = WindowSizes.XS;
  } else if (width >= 576 && width < 768) {
    winsize = WindowSizes.SM;
  } else if (width >= 768 && width < 992) {
    winsize = WindowSizes.MD;
  } else if (width >= 992 && width < 1200) {
    winsize = WindowSizes.LG;
  } else if (width >= 1200 && width < 1600) {
    winsize = WindowSizes.XL;
  } else {
    winsize = WindowSizes.XXL;
  }
  return {
    width,
    height,
    winsize
  };
}
let windowDimensions = getWindowDimensions();


function handleResize() {
  windowDimensions = getWindowDimensions();
}
window.addEventListener("resize", handleResize);
    // return () => window.removeEventListener("resize", handleResize);


export function useWindowDimensions() {
  

  return windowDimensions;
}

