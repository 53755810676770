import { get, post, patch, put } from '../HttpService';

export class UserService {
    static login = (queryData = {}, config = {}) => {
        return post(`/v1/admin/auth/login/`, queryData, config);
    };

    static otpLogin = (queryData = {}, config = {}) => {
        return post(`/v1/admin/users/otp_login/`, queryData, config);
    };

    static update = (queryData = {}, config = {}) => {
        return patch(`/v1/admin/users/${queryData.id}/`, queryData, config);
    };

    static create = (queryData = {}, config = {}) => {
        return post(`/v1/admin/users/`, queryData, config);
    };

    static getStaffList = (queryData = {}, config = {}) => {
        return get(`/v1/admin/users/`, { role__role_type: 'staff', ...queryData }, config);
    };

    static getCustomerList = (queryData = {}, config = {}) => {
        return get(`/v1/admin/users/`, { ...queryData, role__role_type: 'customer' }, config);
    };
    static getWorkingHours = (queryData = {}, config = {}) => {
        return get(`/v1/admin/working_hours/`, queryData, config);
    };
    static update_avatar = (queryData = {}, config = {}) => {
        return put(`/v1/admin/users/${queryData.id}/update_image/`, queryData.formdata, config);
    };

}