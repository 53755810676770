import React, { Component } from 'react';
import { Table, Switch, Typography, DatePicker, Button, Input, Popover, Icon, Popconfirm } from 'antd';
import { isMobile } from 'react-device-detect';
import moment from 'moment/moment';
import { humanizeDate, alertify, handleApiExceptions } from '../../../../app_methods';
import { HolidayService, auth } from '../../../../services';


class Holiday extends Component {
    state = {
        formVisible: false,
        processing: false,
        holiday: {
            holiday_date: moment().format("YYYY-MM-DD"),
            description: ""
        },
        holidays: []
    };

    columns = [{
        title: 'Date',
        key: 'date',
        render: (record) => humanizeDate(record.holiday_date, false)
    }, {
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
    }, {
        title: 'Action',
        render: (record) => {
            return (
                <div className="d-flex align-items-center">
                    <Popconfirm title="Are you sure you want to delete this holiday?" okText="Detete" onConfirm={this.deleteHoliday.bind(this, record.id)}>
                        <Button icon="delete" type="danger" />
                    </Popconfirm>
                </div>
            );
        }
    }];

    componentDidMount() {
        this.fetchHolidays();
    }

    formToggle() {
        this.setState({ formVisible: !this.state.formVisible });
    }

    handleFieldChange(key, value) {
        const holiday = this.state.holiday;
        holiday[key] = key === "holiday_date" ? value.format("YYYY-MM-DD") : value.target.value;
        this.setState({ holiday });
    }

    addHoliday() {
        this.setState({ processing: true });
        HolidayService.create({
            merchant: auth.user.merchant.id,
            ...this.state.holiday
        }).then((response) => {
            if (response.success) {
                alertify("Awesome!!!", "Holiday created.", "success");
                this.setState({ processing: false }, () => {
                    this.fetchHolidays();
                    this.formToggle();
                });
            }
        }).catch((error) => {
            this.setState({ processing: false });
            handleApiExceptions(error);
        });
    }

    fetchHolidays() {
        this.setState({ processing: true });
        HolidayService.list({ merchant: auth.user.merchant.id }).then((response) => {
            if (response.success) {
                this.setState({ holidays: response.data, processing: false });
            }
        }).catch((error) => {
            this.setState({ processing: false });
            handleApiExceptions(error);
        });
    }

    deleteHoliday(id) {
        this.setState({ processing: true });
        HolidayService.delete({ id }).then((response) => {
            if (response.success) {
                this.setState({ processing: false }, () => {
                    this.fetchHolidays();
                });
            }
        }).catch((error) => {
            this.setState({ processing: false });
            handleApiExceptions(error);
        });
    }

    renderForm() {
        return (
            <div className="d-flex flex-column">
                <DatePicker name="holiday_date" disabled={this.state.processing} onChange={this.handleFieldChange.bind(this, "holiday_date")} value={moment(this.state.holiday.holiday_date)} placeholder="Holiday Date" style={{ width: "200px" }} />
                <Input name="description" disabled={this.state.processing} onChange={this.handleFieldChange.bind(this, "description")} value={this.state.holiday.description} placeholder="Description" style={{ width: "200px" }} className="mt-2" />
                <Button type="primary" loading={this.state.processing} onClick={this.addHoliday.bind(this)} className="mt-2">{this.state.processing ? "Adding Holiday..." : "Add"}</Button>
            </div>
        );
    }

    render() {
        return (
            <div id="staff-services" className="mx-auto" style={{ width: "100%" }}>
                <div className="d-flex align-items-center justify-content-between">
                    <Typography.Title level={4} className="mb-3 mt-3" style={{ color: "#455862" }}>Business Holidays</Typography.Title>
                    <Popover placement="bottom" visible={this.state.formVisible} title={(
                        <div className="d-flex align-items-center justify-content-between">
                            <Typography.Text strong>Add Holiday</Typography.Text>
                            <Icon type="close" onClick={this.formToggle.bind(this)} />
                        </div>
                    )} content={this.renderForm()}>
                        <Button icon="plus" onClick={this.formToggle.bind(this)}>Add Holiday</Button>
                    </Popover>
                </div>
                <Table scroll={{ x: isMobile ? true : false }} columns={this.columns} dataSource={this.state.holidays} pagination={false} bordered={false} />
            </div>
        );
    }
}

export default Holiday;
