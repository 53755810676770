
import React, { useEffect, useState } from 'react';
import { Button, Table, Typography, Tag, Collapse, Form, Row, Col, Checkbox, Input, Icon, Select, Spin, Pagination } from 'antd';
import moment from 'moment/moment';
import { auth, BusinessService, SessionService } from '../../../services';
import { alertify, humanizeDate, user_fullname, handleApiExceptions } from '../../../app_methods';
import { EaseModal } from '../../../libs/EaseModal';
import { defaultTableQuery } from '../../Common/models';
import { isMobile } from 'react-device-detect';
import { SessionListWeb } from './web';
import { SessionListMobile } from './mobile';

const initialFilters = {};

const SessionList = (props) => {
  const [fetchingData, setFetchingData] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState(initialFilters);
  const [appliedFilters, setAppliedFilters] = useState(initialFilters);
  const [sessions, setSessions] = useState([]);
  const [tableQuery, setTableQuery] = useState(defaultTableQuery);
  const [processing, setProcessing] = useState(false);
  const [cancelSessionModalData, setCancelSessionModalData] = useState({ visible: false, session: null });
  useEffect(() => {
    fetchSessions();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(fetchSessions, 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  // useEffect(() => {
  //   fetchSessions();
  //   // console.log('filters', appliedFilters);  
  // }, [appliedFilters]);
  const cancelSession = () => {
    setProcessing(true);
    const { session } = { ...cancelSessionModalData };
    SessionService.cancelSession(session).then((response) => {
      setProcessing(false);
      if (response.success) {
        alertify("Awesome!!!", "Your appointment has been cancelled.", "success");
        fetchSessions();
      } else {
        alertify("Oops!!!", "Could not cancel appointment. Try again", "error");
      }
    }).catch((error) => {
      setProcessing(false);
      handleApiExceptions(error);
    });
    setCancelSessionModalData({ visible: false, session: null });
  };

  useEffect(() => {
    fetchSessions();
  }, [appliedFilters || tableQuery.page]);

  const fetchSessions = () => {
    setFetchingData(true);
    const filters = { ...appliedFilters };
    Object.keys(appliedFilters).forEach(k => {
      if (Array.isArray(appliedFilters[k])) {
        filters[k] = appliedFilters[k] + '';
      }
    });
    let query = {
      page: tableQuery.page,
      page_size: tableQuery.page_size,
      ...filters
    };
    if (searchTerm) {
      query.search = searchTerm;
    }

    SessionService.list(query).then((response) => {
      if (response.success) {
        const { data } = response;
        setSessions(data.results);
        setTableQuery({ ...tableQuery, total: data.count });
      }
      setFetchingData(false);
    }).catch((error) => {
      setFetchingData(false);
      handleApiExceptions(error);
    });
  };

  const handleFilterChange = (filterName, value) => {
    let f = { ...filters };
    f[filterName] = value;
    setFilters(f);
  };

  const applyFilters = () => {
    setAppliedFilters(filters);
    setTableQuery({ ...tableQuery, page: 1 });
  };

  const handleResetFilters = () => {
    setFilters(initialFilters);
    setAppliedFilters(initialFilters);
  };

  const toggleConfirmModal = (session) => {
    setCancelSessionModalData({
      visible: !cancelSessionModalData.visible,
      session
    });
  };

  return (
    <div className="w-100" style={{ padding: 16, overflow: 'auto', height: '100%' }}>
      {
        isMobile ?
          <SessionListMobile
            filters={filters}
            fetchingData={fetchingData}
            appliedFilters={appliedFilters}
            handleFilterChange={handleFilterChange}
            handleResetFilters={handleResetFilters}
            sessions={sessions}
            setSearchTerm={setSearchTerm}
            processing={processing}
            tableQuery={tableQuery}
            setTableQuery={setTableQuery}
            fetchSessions={fetchSessions}
            cancelSession={toggleConfirmModal}
            applyFilters={applyFilters} />
          :
          <SessionListWeb
            filters={filters}
            fetchingData={fetchingData}
            appliedFilters={appliedFilters}
            handleFilterChange={handleFilterChange}
            handleResetFilters={handleResetFilters}
            sessions={sessions}
            setSearchTerm={setSearchTerm}
            processing={processing}
            tableQuery={tableQuery}
            setTableQuery={setTableQuery}
            fetchSessions={fetchSessions}
            cancelSession={toggleConfirmModal}
            applyFilters={applyFilters} />
      }
      <EaseModal title="Confirm Cancellation"
        onOk={cancelSession}
        okText={'Cancel Appointment'}
        cancelText={'No'}
        onCancel={toggleConfirmModal}
        visible={cancelSessionModalData.visible}>
        <div>Are you sure you want to cancel this appointment?</div>
      </EaseModal>
    </div >
  );
};

export default SessionList;