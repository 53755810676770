import { get } from '../HttpService';
import axios from 'axios';

export class CountryCodeService {
  static list = (queryData = {}, config = {}) => {
    return get(`/v1/admin/country_code/`, queryData, config);
  };
  static getIpLocation = () => {
    return axios.get('https://geolocation-db.com/json/')
      .then((response) => {
        return Promise.resolve(response.data);
      }).catch((error) => {
        return Promise.reject(error);
      });;
  };
}