import React, { Component } from 'react';
import { Form, Input, Avatar, Tag, Table, Button, Switch, Icon, Typography, Empty, Select } from 'antd';
import moment from 'moment/moment';
import { TimePicker } from '../../index';
import { isMobile } from 'react-device-detect';
import { MerchantService, auth, CountryCodeService } from '../../../../services';
import { handleApiExceptions, alertify } from '../../../../app_methods';

class Preferences extends Component {
    state = {
        settings: {
            theme: auth.settings().theme || "default",
            week_start_from: auth.settings().week_start_from || 1,
            calendar_default_view: auth.settings().calendar_default_view || "timeGridWeek",
            business_hours: auth.settings().business_hours || { start: "9:00", end: "17:00" },
            phone_code: auth.settings().phone_code || null,
        },
        all_phone_codes: []
    };

    componentDidMount() {
        CountryCodeService.list().then(response => {
            if (response.success) {
                console.log('fetchServices', response.data);
                this.setState({ all_phone_codes: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    }

    // fetchReviews() {
    //     MerchantService.getReviews({ merchant: auth.user.merchant.id }).then((response) => {
    //         if (response.success) {
    //             this.setState({ reviews: response.data });
    //         }
    //     }).catch((error) => {
    //         handleApiExceptions(error);
    //     })business_hours.
    // }

    changeTheme(theme) {
        const settings = this.state.settings;
        settings['theme'] = theme;
        this.setState({ settings }, () => this.props.changeTheme(theme));
    }

    setSettings(key, value) {
        const settings = this.state.settings;
        settings[key] = value;
        this.setState({ settings }, () => {
            MerchantService.update_settings({ id: auth.user.merchant.id, settings: this.state.settings }).then((response) => {
                auth.set_settings(this.state.settings);
                alertify("Awesome!!!", "Settings saved.");
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.message && error.response.data.message.business_hours) {
                    alertify("Oops!!!", error.response.data.message.business_hours, "error");
                    return;
                }
                handleApiExceptions(error);
            });
        });
    }

    setBusinessHours(type, value) {
        const settings = this.state.settings;
        settings.business_hours = settings.business_hours || {};
        settings.business_hours[type] = moment(value).format("HH:mm");
        this.setState({ settings });
    }

    updateBusinessHours() {
        const business_hours = this.state.settings.business_hours || {};
        const start = business_hours.start;
        const end = business_hours.end;
        if (moment(start).isAfter(end) || moment(start).isSame(end)) {
            alertify("Oops!!!", "Business hour end must be after business hour start.", "warning");
            return;
        }
        this.setSettings("business_hours", { start, end });
    }

    render() {
        return (
            <div>
                <Typography.Title level={4} className="mb-3 mt-3" style={{ color: "#455862" }}>Preferences:</Typography.Title>
                <div className="d-table w-100">
                    <div className="d-table-row">
                        <div className="d-table-cell border-bottom pb-3">
                            <Typography.Text strong>Choose Theme:</Typography.Text>
                        </div>
                        <div className="d-table-cell border-bottom">
                            <Button type={this.state.settings.theme === "default" ? 'primary' : 'default'}
                                onClick={this.changeTheme.bind(this, 'default')}>Sky Blue</Button>
                            <Button className="ml-2"
                                type={this.state.settings.theme === "red" ? 'primary' : 'default'}
                                onClick={this.changeTheme.bind(this, 'red')}>Hot Pink</Button>
                            <Button className="ml-2"
                                type={this.state.settings.theme === "purple" ? 'primary' : 'default'}
                                onClick={this.changeTheme.bind(this, 'purple')}>Iris</Button>
                            <Button className="ml-2"
                                type={this.state.settings.theme === "green" ? 'primary' : 'default'}
                                onClick={this.changeTheme.bind(this, 'green')}>Cool Green</Button>
                            <Button className="ml-2"
                                type={this.state.settings.theme === "orange" ? 'primary' : 'default'}
                                onClick={this.changeTheme.bind(this, 'orange')}>Coral</Button>
                            <Button className="ml-2"
                                type={this.state.settings.theme === "blue" ? 'primary' : 'default'}
                                onClick={this.changeTheme.bind(this, 'blue')}>Blue</Button>
                            <Button className="ml-2"
                                type={this.state.settings.theme === "grey" ? 'primary' : 'default'}
                                onClick={this.changeTheme.bind(this, 'grey')}>Winter</Button>
                        </div>
                    </div>
                    <div className="d-table-row">
                        <div className="pt-3 d-table-cell border-bottom pb-3">
                            <Typography.Text strong>Week Start From:</Typography.Text>
                        </div>
                        <div className="d-table-cell border-bottom">
                            <Select defaultValue={this.state.settings.week_start_from}
                                onChange={this.setSettings.bind(this, 'week_start_from')}
                                style={{ width: 200 }}>
                                <Select.Option value={0}>Sunday</Select.Option>
                                <Select.Option value={1}>Monday</Select.Option>
                                <Select.Option value={2}>Tuesday</Select.Option>
                                <Select.Option value={3}>Wednesday</Select.Option>
                                <Select.Option value={4}>Thursday</Select.Option>
                                <Select.Option value={5}>Friday</Select.Option>
                                <Select.Option value={6}>Saturday</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="d-table-row">
                        <div className="pt-3 d-table-cell border-bottom pb-3">
                            <Typography.Text strong>Calendar Default View:</Typography.Text>
                        </div>
                        <div className="d-table-cell border-bottom">
                            <Select defaultValue={this.state.settings.calendar_default_view}
                                onChange={this.setSettings.bind(this, 'calendar_default_view')}
                                style={{ width: 200 }}>
                                <Select.Option key={1} value="timeGridDay">Daily</Select.Option>
                                <Select.Option key={2} value="timeGridWeek">Weekly</Select.Option>
                                <Select.Option key={3} value="dayGridMonth">Monthly</Select.Option>
                                <Select.Option key={4} value="listDay">Summary</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className="d-table-row">
                        <div className="pt-3 d-table-cell border-bottom pb-3">
                            <Typography.Text strong>Business Hours:</Typography.Text>
                        </div>
                        <div className="d-table-cell border-bottom">
                            <TimePicker
                                style={{ width: 100 }}
                                value={moment(this.state.settings.business_hours.start, "HH:mm")}
                                onChange={this.setBusinessHours.bind(this, 'start')}
                            />
                            <span className="mx-3">To</span>
                            <TimePicker
                                style={{ width: 100 }}
                                value={moment(this.state.settings.business_hours.end, "HH:mm")}
                                onChange={this.setBusinessHours.bind(this, 'end')}
                            />
                            <Button className="ml-3" type="primary" onClick={this.updateBusinessHours.bind(this)}>Update</Button>
                        </div>
                    </div>
                    <div className="d-table-row">
                        <div className="pt-3 d-table-cell border-bottom pb-3">
                            <Typography.Text strong>Phone Code</Typography.Text>
                        </div>
                        <div className="d-table-cell border-bottom">
                            <Select defaultValue={this.state.settings.phone_code}
                                onChange={this.setSettings.bind(this, 'phone_code')}
                                style={{ width: 150 }}>
                                {
                                    this.state.all_phone_codes &&
                                    this.state.all_phone_codes.length > 0 &&
                                    this.state.all_phone_codes.map(p => (
                                        <Select.Option value={p.phonecode}>{`${p.iso} (+${p.phonecode})`}</Select.Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Preferences;
