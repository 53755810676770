import React, { Component } from 'react';
import { Tabs, Empty } from 'antd';
import BasicDetails from './BasicDetails';
import StaffServices from './Services';
import StaffWorkingHours from './WorkingHours';
import StaffBreakHours from './Breaks';
import './index.scss';
class StaffForm extends Component {
    state = {
        currentStep: 0
    }

    componentDidMount() {
    }

    onStepChange(num) {
        this.setState({ currentStep: num });
    }

    render() {
        const formComponents = [
            { label: "Basic Details", component: <BasicDetails afterSubmit={this.props.fetchStaffList} staffDetails={this.props.staffDetails} /> },
            { label: "Services", component: <StaffServices staffDetails={this.props.staffDetails} /> },
            { label: "Working Hours", component: <StaffWorkingHours staffDetails={this.props.staffDetails} /> },
            { label: "Breaks", component: <StaffBreakHours staffDetails={this.props.staffDetails} /> },
        ];

        return (
            <div id="staff-form-wrapper">
                {!this.props.staffDetails.id ? (
                    <Empty description="Select staff to see staff settings." className="mt-5" />
                ) : (
                    <Tabs defaultActiveKey="0" onChange={() => { }} animated={false}>
                        {
                            formComponents.map((tab, index) => {
                                return <Tabs.TabPane tab={tab.label} key={index}>{tab.component}</Tabs.TabPane>
                            })
                        }
                    </Tabs>
                )}
            </div>
        )
    }
}

export default StaffForm;