import React from 'react';
import { EaseDrawer } from '../../../../libs/EaseDrawer';
import { ServiceForm } from '../../../Common/Forms';
import { isMobile } from 'react-device-detect';

const FormDrawer = (props) => {
    return (
        <EaseDrawer
            width={isMobile? "100%" : 500}
            title={props.service.is_edit ? "Edit Service" : "Add Service"}
            visible={props.formDrawer}
            hideFooter
            onClose={props.toggleFormDrawer.bind(this, {}, false)}
        >
            <ServiceForm activeCategory={props.activeCategory} service={props.service} afterSubmit={props.afterSubmit} />
        </EaseDrawer>
    )
}

export default FormDrawer;