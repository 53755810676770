import React, { Component } from 'react';
import { Input } from 'antd';

export class NumericInput extends Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (value) {
      if (value.charAt(value.length - 1) === '.' || value === '-') {
        valueTemp = value.slice(0, -1);
      }
      onChange(valueTemp.replace(/0*(\d+)/, '$1'));
      if (onBlur) {
        onBlur();
      }
    }
  };

  render() {
    const { value } = this.props;
    // const title = value ? (
    //   <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    // ) : (
    //   'Input a number'
    // );
    return (
      // <Tooltip
      //   trigger={['focus']}
      //   title={title}
      //   placement="topLeft"
      //   overlayClassName="numeric-input"
      // >
      <Input
        {...this.props}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
      // </Tooltip>
    );
  }
}