import { get, post, patch, httpDelete } from '../HttpService';

export class BusinessCategoryService {
    static list = (queryData = {}, config = {}) => {
        return get(`/v1/admin/service_category/`, queryData, config);
    }

    static create = (queryData = {}, config = {}) => {
        return post(`/v1/admin/service_category/`, queryData, config);
    }

    static delete = (queryData = {}, config = {}) => {
        return httpDelete(`/v1/admin/service_category/${queryData.id}/`, {}, config);
    }

    // static update = (queryData = {}, config = {}) => {
    //     return patch(`/v1/admin/service/${queryData.id}/`, queryData, config);
    // }
}