import { get, post, patch, put } from '../HttpService';

export class MerchantService {
    static show = (queryData = {}, config = {}) => {
        return get(`/v1/admin/merchant/${queryData.id}/`, {}, config);
    }
    static list = (queryData = {}, config = {}) => {
        return get(`/v1/admin/merchant/`, queryData, config);
    }
    static create = (queryData = {}, config = {}) => {
        return post(`/v1/admin/merchant/`, queryData, config);
    }
    static update = (queryData = {}, config = {}) => {
        return patch(`/v1/admin/merchant/${queryData.id}/`, queryData, config);
    }
    static update_settings = (queryData = {}, config = {}) => {
        return patch(`/v1/admin/merchant/${queryData.id}/`, queryData, config);
    }
    static update_icon = (queryData = {}, config = {}) => {
        return put(`/v1/admin/merchant/${queryData.id}/update_image/`, queryData.formdata, config);
    }
    static getReviews = (queryData = {}, config = {}) => {
        return get(`/v1/admin/merchant_review/`, {}, config);
    }
}
