import React, { useState } from 'react';
import { Typography, Button, Icon, Popconfirm, Input } from 'antd';
import { humanizeDate, user_fullname } from '../../../../app_methods';
import moment from 'moment/moment';
import { useWindowDimensions, WindowSizes } from '../../../../utils/windowDimesions';

const Confirmation = (props) => {
    const { winsize } = useWindowDimensions();
    const [otp_input, setOtpInput] = useState("");

    const confirmRowStyles = winsize < WindowSizes.MD ? styles.mobileRowDiv : {};
    const rowClassName = winsize < WindowSizes.MD ? "d-table-row" : "d-table-row";
    const wrapperDivClassName = winsize < WindowSizes.MD ? "d-table" : "d-table";
    const tableCellStyles = winsize < WindowSizes.MD ? "d-table" : "d-table";

    const renderEditButton = (step) => {
        return (winsize < WindowSizes.MD &&
            <div className="d-table-cell pb-2" style={styles.mobileTableCellStyles}>
                <Button type="secondary"
                    onClick={() => props.editStep(step)}>
                    <Icon type="edit" />
                </Button>
            </div>
        )
    }

    const renderVerifyButton = () => {
        return (<Button type="primary" className="mr-2" onClick={() => props.verifyOtp(props.customer.phone, otp_input, 'book_appointment')}>Verify OTP & Book Appointment</Button>)
    }
    const renderResendOtpButton = () => {
        return (<Button onClick={() => props.sendOtp(props.customer.phone, props.customer.country_code)}>Resend OTP</Button>)
    }

    return (
        <>
            <div className="booking-service-confirmation">
            <Typography.Text className="section-title-text">Review & Confirm Appointment</Typography.Text>
            </div>

            <div className="mt-4 p-relative">
                <div className="row">
                    <div className="col-sm border-right confirmation-left">
                        <div className={"d-table"} style={styles.tableDivStyles}>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2" style={{ minWidth: 100 }}>Service:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{props.service.name || "---"}</Typography.Text>
                                {renderEditButton(0)}
                                
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Service Detail:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{props.service.description || "---"}</Typography.Text>
                                {/* {renderEditButton()} */}
                                
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Service Provider:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{user_fullname(props.staff) || "---"}</Typography.Text>
                                {renderEditButton(1)}
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Schedule:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{moment(props.schedule).format("LLLL")}</Typography.Text>
                                {renderEditButton(2)}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="d-table" style={styles.tableDivStyles}>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2" style={{ minWidth: 120 }}>Customer Name:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{user_fullname(props.customer)}</Typography.Text>
                                {renderEditButton(3)}
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Email:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{props.customer.email}</Typography.Text>
                                {renderEditButton(3)}
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Mobile:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{`(+${props.customer.country_code})${props.customer.phone}`}</Typography.Text>
                                {renderEditButton(3)}
                            </div>
                            <div className="d-table-row">
                                <Typography.Text strong className="d-table-cell pb-2">Additional Notes:</Typography.Text>
                                <Typography.Text className="px-3 d-table-cell">{props.customer.note || "---"}</Typography.Text>
                                {renderEditButton(3)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm p-2">
                        <Typography.Text className="d-block" strong>Mobile Number verification:</Typography.Text>
                        <div className="d-flex align-items-center otp-input-wrapper">
                            <Input style={{ width: 200 }} className="mr-2" onChange={(e) => setOtpInput(e.target.value)} />
                            
                            {winsize >= WindowSizes.MD &&
                                <>
                                    {renderVerifyButton()}
                                    {renderResendOtpButton()}
                                </>
                            }
                            {winsize < WindowSizes.MD &&
                                renderResendOtpButton()
                            }
                        </div>
                    </div>
                </div>
                {winsize < WindowSizes.MD &&
                    <div className="row mt-3">
                        <div className="col-sm p-2 mobile-verify-btn-div">
                            {renderVerifyButton()}
                        </div>
                    </div>
                }
                {winsize >= WindowSizes.MD &&
                    <div style={{ position: "absolute", bottom: 30, right: 30 }} className="confirmation-btn">
                        <div className="d-flex align-items-center">
                            <Typography.Text type="danger" className="d-block mr-3" style={{ fontSize: 16 }} >Click on the <b>Booking Workflow</b> steps to edit any information.</Typography.Text>
                            {/* <Popconfirm okText="Confirm" onConfirm={props.bookAppointment} title="Re-check all the information before proceeding.">
                                <Button type="primary">Confirm & Book<Icon type="right" type="arrow-right" /></Button>
                            </Popconfirm> */}
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

const styles = {
    mobileRowDiv: {
        display: 'flex',
        alignItems: 'center',
        // padding: '1rem',
        justifyContent: 'space-between',
        width: '100%'
    },
    checkBtn: {
        justifySelf: 'flex-end'
    },
    mobileStaffList: {
        height: "86%",
        minHeight: "86%",
        overflow: "auto"
    },
    tableDivStyles: {
        width: "100%"
    },
    mobileTableCellStyles: {
        textAlign: 'right'
    }
}

export default Confirmation;