import { Modal, message } from 'antd';
import moment from 'moment/moment';
import { auth } from './services';

export const alertify = (title, content, type = "success") => {
    // Modal[type]({
    //     title: title,
    //     content: content,
    //     centered: true
    // });
    message[type](content);
};

export const amAlert = (title, content, type = "success", onOk = () => { }) => {
    Modal[type]({
        title: title,
        content: content,
        centered: true,
        onOk: onOk
    });
};

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const goTo = (history, url) => {
    history.push(url);
};

export const humanizeDate = (date, time = true) => {
    const dateTimeFormat = 'h:mm A DD-MMM-YY';
    const dateTimeFormat2 = 'h:mm A';
    const dateFormat = 'DD-MMM-YY';
    if (time) {
        const now = moment().format(dateFormat);
        const tomorrow = moment().add(1, 'day').format(dateFormat);
        const queryDate = moment(date).format(dateFormat);
        if (now === queryDate) {
            // return moment(date).format(dateTimeFormat2);
            return moment(date).format(dateTimeFormat2) + ' Today';
        } else if (tomorrow === queryDate) {
            return moment(date).format(dateTimeFormat2) + ' Tomorrow';
        }
        return moment(date).format(dateTimeFormat);
    }
    return moment(date).format(dateFormat);
    // return moment(date).format(`LL${time ? "L" : ""}`);
    // return moment(date).format(`LL${time ? "L" : ""}`);
};

export const titleCase = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const user_fullname = (user) => {
    user = user || {};
    if (!user.first_name && !user.last_name) { return ""; };
    return `${user.first_name} ${user.last_name}`;
};

export const getCookie = (name) => {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
};

export const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const default_avatar = () => {
    return require("./assets/images/avatar.jpg");
};

export const get_user_avatar = (user) => {
    return (user && user.avatar) || default_avatar();
};

export const titlize = (str) => {
    return str.toLowerCase().split(' ').map((word) => {
        return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
};

export const resolve_server_image = (path) => {
    return `${process.env.REACT_APP_ASSETS_SERVICE_URL}${path}`;
};

export const get_authentication_token = () => {
    return auth.user.token ? `Token ${auth.user.token}` : "";
};

export const appointment_date_format = (date) => {
    return moment(date).format("YYYY-MM-DD hh:mm");
};

export const daterange_for_filter = (start, end) => {
    return [
        start.startOf('day').format("YYYY-MM-DD HH:mm"),
        end.endOf('day').format("YYYY-MM-DD HH:mm")
    ];
};

export const findHoursForSelectedDay = (x, selected_day) => {
    return x.currently_active && x.display_day.toLowerCase() === selected_day;
};

// export const get_unavailable_hours = (date, workHours, breakHours, appointments) => {
//     const result = {}
//     if (date) {
//         const m = moment;
//         const selected_day = moment(date).format("dddd").toLowerCase();
//         const work_hours_for_selected_day = (workHours || []).find(x => findHoursForSelectedDay(x, selected_day));
//         const break_hours_for_selected_day = (breakHours || {}).find(x => findHoursForSelectedDay(x, selected_day));

//         if(work_hours_for_selected_day){
//             result.minTime = moment(work_hours_for_selected_day.day_start_time, "HH:mm:ss").format("HH:mm");
//             result.maxTime = moment(work_hours_for_selected_day.day_end_time, "HH:mm:ss").format("HH:mm");
//         }

//         result.unavailables = [];
//         if (break_hours_for_selected_day){
//             result.unavailables.push({
//                 start: moment(break_hours_for_selected_day.break_start_time, "HH:mm:ss").format("HH:mm"),
//                 end: moment(break_hours_for_selected_day.break_end_time, "HH:mm:ss").format("HH:mm")
//             });
//         }
//         appointments.forEach((appo) => {
//             result.unavailables.push({
//                 start: moment(appo.start).format("HH:mm"),
//                 end: moment(appo.end).format("HH:mm")
//             })
//         })
//         console.log('result')
//     }
//     return result
// }

export const get_unavailable_hours = (date, workHours, breakHours, appointments, service) => {
    const result = {};
    if (date) {
        const selected_day = moment(date).format("dddd").toLowerCase();
        const work_hours_for_selected_day = (workHours || []).find(x => findHoursForSelectedDay(x, selected_day));
        const break_hours_for_selected_day = (breakHours || {}).find(x => findHoursForSelectedDay(x, selected_day));
        const fullTimeFormat = "HH:mm:ss";
        const timeFormat = "HH:mm";
        const dateFormat = "YYYY-MM-DD";
        if (work_hours_for_selected_day) {
            console.log('work hours', work_hours_for_selected_day);
            result.minTime = moment(work_hours_for_selected_day.day_start_time, fullTimeFormat).format(timeFormat);
            result.maxTime = moment(work_hours_for_selected_day.day_end_time, fullTimeFormat).format(timeFormat);
            const now = moment();
            const dateToday = now.format(dateFormat);
            const currentDate = moment(date).format(dateFormat);
            if (dateToday === currentDate) {
                // set the minutes as the next nearest multiple of 5.
                now.set('minutes', now.get('minutes') + (5 - now.get('minutes') % 5));
                const currentTime = now.format(timeFormat);
                // change the min time based on the current time
                if (moment(result.minTime, timeFormat).isBefore(moment(currentTime, timeFormat))) {
                    result.minTime = currentTime;
                }
            }
        }

        result.unavailables = [];
        if (break_hours_for_selected_day) {
            result.unavailables.push({
                start: moment(break_hours_for_selected_day.break_start_time, fullTimeFormat).format(timeFormat),
                end: moment(break_hours_for_selected_day.break_end_time, fullTimeFormat).format(timeFormat)
            });
        }
        console.log('appointments', appointments);
        appointments.forEach((appo) => {
            result.unavailables.push({
                start: moment(appo.scheduled_at).format(timeFormat),
                end: moment(appo.end).format(timeFormat),
                isAppointment: true,
                id: appo.id
            });
        });

        if (service && service.service_minutes) {
            sortTimeSlots(result.unavailables);
            const timeSlots = [...result.unavailables];
            // let currentStart = moment(result.minTime, timeFormat);
            // const end = moment(result.maxTime, timeFormat);
            console.log('sortTimeSlots', result);
            for (let t of timeSlots) {
                if (t.isAppointment) {
                    const new_start = moment(t.start, timeFormat).subtract(service.service_minutes, 'minutes');
                    t.start = new_start.format(timeFormat);
                }
            }
            result.unavailables = timeSlots;

            // for (let t of timeSlots) {
            //     const tStart = moment(t.start, timeFormat);
            //     let currentEnd = moment(currentStart).add(service.service_minutes, "m");
            //     if (currentStart.isBefore(end)) { // is before maxTime
            //         while (currentStart.isBefore(tStart)) { // loop as many times as you can fit the service duration interval
            //             if (currentEnd.isAfter(tStart)) { // if interval overlaps
            //                 result.unavailables.push({
            //                     start: currentStart.format(timeFormat),
            //                     end: t.start,
            //                     isAppointment: false
            //                 });
            //                 currentStart = moment(t.end, timeFormat);
            //                 break;
            //             } else { // else try the next interval 
            //                 currentStart.add(5, "m");
            //                 currentEnd = moment(currentStart).add(service.service_minutes, "m");
            //             }
            //         }
            //     } else {
            //         break;
            //     }
            // }
        }

        // sortTimeSlots(result.unavailables);
        // console.log('service duration', service.service_minutes);
        console.log('result', result);
    }
    return result;
};

export const get_unavailable_dates = (holidays) => {
    const result = holidays.map(x => x.holiday_date);
    return result;
};

export const handleApiExceptions = (error) => {
    let error_messages = "";
    if (error && error.response && error.response.data && typeof error.response.data === "object") {
        let response = (error.response.data || {}).message || (error.response.data || {});
        for (let key in response) {
            if (typeof (response[key]) == "object") {
                for (let msg of response[key]) {
                    error_messages = key + ": " + error_messages + msg + "\n";
                }
            } else if (typeof (response[key]) == "string") {
                error_messages = key + ": " + error_messages + response[key] + "\n";
            }
        }
        alertify("Oops!!!", error_messages, "error");
    } else if (error && error.response && error.response.data && error.response.data.indexOf("Duplicate entry") > -1) {
        alertify("Oops!!!", "Duplicate entry found with provided details.", "error");
    }
};

export const sortTimeSlots = (array) => {
    return array.sort((a, b) => {
        if (parseInt(a.start.split(":")[0]) - parseInt(b.start.split(":")[0]) === 0) {
            return parseInt(a.start.split(":")[1]) - parseInt(b.start.split(":")[1]);
        } else {
            return parseInt(a.start.split(":")[0]) - parseInt(b.start.split(":")[0]);
        }
    });
};

export const getCustomerSearchType = (searchValue) => {
    if (searchValue.length > 2) {
        if (searchValue.includes('@') && searchValue.includes('.')) {
            return { type: 'email', description: 'Add Customer using Email' };
        } else if (/^\d+$/.test(searchValue)) {
            return { type: 'phone', description: 'Add Customer using Mobile' };
        } else {
            return { type: 'name', description: 'Add Customer using Name' };
        }
    }
    return false;
};