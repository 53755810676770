import React, { Component } from 'react';
import { Avatar, Table, Switch, Icon, Typography } from 'antd';
import { BusinessService, UserService, auth } from '../../../../services';
import { isMobile } from 'react-device-detect';
import { alertify, user_fullname } from '../../../../app_methods';

class StaffServices extends Component {
    columns = [{
        title: 'Service',
        key: 'name',
        width: "50%",
        fixed: 'left',
        render: (record) => {
            return (
                <div className="d-flex">
                    <div>
                        <Avatar icon="user" />
                    </div>
                    <div className="d-flex flex-column align-items-start ml-2">
                        <Typography.Text>{record.name}</Typography.Text>
                        <Typography.Text type="secondary">{record.description}</Typography.Text>
                    </div>
                </div>
            )
        }
    }, {
        title: 'Amount',
        dataIndex: 'sell_price',
        key: 'sell_price',
    }, {
        title: 'Duration',
        dataIndex: 'service_minutes',
        key: 'service_minutes',
    }, {
        title: 'Buffer Time',
        dataIndex: 'buffer_minutes',
        key: 'buffer_minutes',
    }, {
        title: 'Action',
        key: 'action',
        width: "20%",
        fixed: 'right',
        render: (record) => <Switch loading={this.state.processing} checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} onChange={this.updateUserServices.bind(this, record.id)} checked={(this.props.staffDetails.services || []).includes(record.id)} />
    }];
    state = {
        services: [],
        userServices: [],
        processing: false
    }

    componentDidMount() {
        this.fetchServices();
        // this.fetchUserServices(this.props.staffDetails.id)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.staffDetails.id !== nextProps.staffDetails.id) {
            this.fetchServices();
            // this.fetchUserServices(nextProps.staffDetails.id);
        }
    }

    fetchServices() {
        BusinessService.list({ merchant: auth.user.merchant.id }).then((response) => {
            if (response.success) {
                this.setState({ services: response.data });
            } else {
                alertify("Oops!!!", "Something went wrong. Try again", "error");
            }
        }).catch(() => {
            alertify("Oops!!!", "Something went wrong. Try again", "error");
        })
    }

    // fetchUserServices(id) {
    //     UserService.getServices({ id }).then((response) => {
    //         if (response.success) {
    //             this.setState({ userServices: response.data.map(x => x.id) });
    //         } else {
    //             alertify("Oops!!!", "Something went wrong. Try again", "error");
    //         }
    //     }).catch(() => {
    //         alertify("Oops!!!", "Something went wrong. Try again", "error");
    //     })
    // }

    updateUserServices(service_id, selected) {
        if (!this.props.staffDetails.id) {
            alertify("Oops!!!", "You must select staff first.", "error");
            return;
        }
        this.setState({ processing: true })
        const staff_details = this.props.staffDetails;
        selected ? staff_details.services.push(service_id) : staff_details.services.splice(staff_details.services.indexOf(service_id), 1);
        UserService.update({ id: this.props.staffDetails.id, services: staff_details.services }).then((response) => {
            if (response.success) {
                this.setState({ staffDetails: staff_details });
            } else {
                alertify("Oops!!!", "Something went wrong. Try again", "error");
            }
            this.setState({ processing: false })
        }).catch(() => {
            alertify("Oops!!!", "Something went wrong. Try again", "error");
            this.setState({ processing: false })
        })
    }

    render() {
        return (
            <div id="staff-services" className="mx-auto" style={{ width: "100%" }}>
                <Typography.Title level={4} className="mb-3 mt-3" style={{ color: "#455862" }}>Services provided by {user_fullname(this.props.staffDetails)}</Typography.Title>
                <Table scroll={{ x: isMobile ? true : false }} columns={this.columns} dataSource={this.state.services} pagination={false} bordered={false} />
            </div>
        )
    }
}

export default StaffServices;
