import SecureLS from 'secure-ls';
const encodingType = (process.env.NODE_ENV==='development')? '' : 'des';
let storage  = new SecureLS({ encodingType: encodingType, isCompression: false, encryptionSecret: process.env.REACT_APP_STORAGE_SECRET });

class LocalStorage {
  set = function(key, value){
    storage.set(key, value);
  }

  get = function(key){
    return storage.get(key) || {};
  }

  getAllKeys = function(){
    storage.getAllKeys();
  }

  removeAll = function(){
    storage.removeAll();
  }

  remove = function(key){
    storage.remove(key);
  }

  getOrSet = function(key, value){
    let result = storage.get(key);
    if(!result){
      storage.set(key, value);
      result = value;
    }
    return result;
  }
}

export { LocalStorage };
