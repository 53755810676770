import React from 'react';
import Sider from 'antd/lib/layout/Sider';
import { isMobile } from 'react-device-detect';
import './index.scss';

const SidebarFull = (props) => {
    return (
        <Sider breakpoint="sm"
            trigger={null}
            className={`am-sidebar ${isMobile ? 'sidebar-shadow' : ''}`}
            width={isMobile ? '75%' : 240}
            collapsedWidth={isMobile ? 0 : 56}
            collapsed={props.sidebarCollapsed}
            style={styles.sider} >
            {props.children}
        </Sider>
    );
};

const styles = {
    sider: {
        background: "#2E363E",
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        // boxShadow: 'rgb(153, 153, 153) 0px 0px 3px 0px',
        zIndex: 99
    }
};

export { SidebarFull };