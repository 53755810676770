import React, { Component } from 'react';
import { Layout, Menu, message } from 'antd';
import { isMobile } from 'react-device-detect';
import { Sidebar, SidebarMenuItem } from '../../components/Common';
import { auth } from '../../services';
import { SuperAdminNavbar } from './SuperAdminNavbar';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
const { Content } = Layout;

class SuperAdminLayout extends Component {
  state = {
    sidebarCollapsed: isMobile ? true : false,
    activeSidebar: []
  };

  componentDidMount() {
    if (!auth.is_loggedin()) {
      message.warning("Seems like you are not logged in. Login again.");
      this.props.redirect('/users/login');
    }
  }

  toggleSidebar = () => {
    if (isMobile) this.setState({ sidebarCollapsed: !this.state.sidebarCollapsed });
  };

  onChildMount = (pathkey) => {
    this.setState({ activeSidebar: [pathkey || this.props.children.props.pathkey] });
  };

  render() {
    return (
      <div className="superadmin-layout-container h-100">
        <div className="superadmin-layout-body h-100">
          <Layout>
            <Sidebar sidebarCollapsed={this.state.sidebarCollapsed} >
              <div style={{ padding: 10, height: 60 }} className="sidebar-top">
                <div className="m-auto">
                  <img style={{ width: "100%" }} alt="" src="" />
                </div>
              </div>
              <Menu theme="light" mode="inline" selectedKeys={this.state.activeSidebar}>
                <SidebarMenuItem
                  key="superadmin_merchant"
                  onClick={this.props.redirect.bind(this, '/superadmin/merchants/list', this.toggleSidebar)}
                  label="Merchants"
                  icon={faUsers} />
              </Menu>
            </Sidebar>
            <Layout
              className="superadmin-layout-content-wrapper"
              style={this.state.sidebarCollapsed ? styles.layoutInnerCollapsed : styles.layoutInner}>
              <SuperAdminNavbar
                toggleSidebar={this.toggleSidebar}
                sidebarCollapsed={this.state.sidebarCollapsed}
                redirect={this.props.redirect.bind(this)} />
              <Content style={styles.content}>
                {auth.is_loggedin() && React.cloneElement(this.props.children, {
                  onChildMount: this.onChildMount.bind(this),
                  changeTheme: this.props.changeTheme
                })}
              </Content>
            </Layout>
          </Layout>
        </div>
      </div>
    );
  }
}

const styles = {
  layoutInnerCollapsed: {
    marginLeft: 0,
    transition: 'all .2s',
    WebkitTransition: 'all .2s'
  },
  layoutInner: {
    marginLeft: isMobile ? 0 : 80,
    transition: 'all .2s',
    WebkitTransition: 'all .2s'
  },
  content: {
    background: '#eee',
    padding: '0px',
    overflow: 'initial',
    marginTop: 60
  }
};

export default SuperAdminLayout;
