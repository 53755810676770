import { get, post, patch } from '../HttpService';

export class BreakHourService {
    static list = (queryData = {}, config = {}) => {
        return get(`/v1/admin/break_hours/`, queryData, config);
    }

    static update = (queryData = {}, config = {}) => {
        return patch(`/v1/admin/break_hours/${queryData.id}/`, queryData, config);
    }
    
}