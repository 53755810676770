import React, { Component } from 'react';
import { DatePicker, Button, Table, Typography, Select, Tag, Switch, Card, List, Divider } from 'antd';
import moment from 'moment/moment';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import StaffList from '../Settings/Staff/StaffList';
import { UserService, auth, AppointmentService, SessionService, QueueService } from '../../../services';
import './index.scss';
import { daterange_for_filter, humanizeDate, user_fullname, handleApiExceptions } from '../../../app_methods';
import { isMobile } from 'react-device-detect';
import { EaseModal } from '../../../libs/EaseModal';
import { SessionForm, Bill, CustomerForm, Checkout } from '../../Common';
import { PermissionHoc } from '../../HOC';
import { DATE_RANGES } from '../../../constants';
// import { SessionColumns } from './sessions';
const PermissableButton = PermissionHoc(Button, '', { is_component: true, fallbackComponent: null });
const SessionType = { appointment: 'appointment', walkin: 'walkin' };
class AppointmentQueue extends Component {
  user_permissions = auth.permissions();

  state = {
    fetchingData: false,
    staffList: [],
    customerList: [],
    activeStaff: {},
    activeCustomer: {},
    appointments: [],
    sessions: [],
    sessionModal: false,
    addWalkinsModal: false,
    activeSession: {},
    billModal: false,
    checkoutModal: false,
    walkins: [],
    queueList: [],
    queueFilters: {},
    appointmentQueueDrawer: false,
    sessionType: SessionType.appointment,
    filters: {
      is_cancelled: false,
      session_requests__staff: undefined,
      scheduled_at__gte: daterange_for_filter(moment(), moment())[0],
      scheduled_at__lte: daterange_for_filter(moment(), moment())[1],
      checkin_time__isnull: true,
    }
  };

  componentDidMount() {
    this.fetchStaffList();
    this.fetchCustomerList();
    this.fetchSessions();
  }

  fetchStaffList = () => {
    const daily_queue_permission = this.user_permissions.DAILY_QUEUE;
    UserService.getStaffList({
      merchant: auth.user.merchant.id,
      id: daily_queue_permission == "SELF" ? auth.user.id : undefined
    }).then((response) => {
      if (response.success) {
        this.setState({ staffList: response.data.results }, () => {
          if (_isEmpty(this.state.activeStaff)) {
            const filters = this.state.filters;
            const staff = response.data[0] || {};
            filters.session_requests__staff = staff.id;

            this.setState({ activeStaff: staff, filters }, () => {
              this.fetchAppointments();
              this.fetchWalkins();
              this.fetchQueue();
            });
          }
        });
      }
    }).catch((error) => {
      handleApiExceptions(error);
    });
  };

  fetchAppointments = () => {
    this.setState({ fetchingData: true });
    AppointmentService.list(this.state.filters).then((response) => {
      if (response.success) {
        this.setState({
          appointments: _orderBy(response.data.results, 'scheduled_at')
        });
      }
      this.setState({ fetchingData: false });
    }).catch((error) => {
      this.setState({ fetchingData: false });
      handleApiExceptions(error);
    });
  };

  fetchSessions = () => {
    this.setState({ fetchingData: true });
    SessionService.list().then((response) => {
      if (response.success) {
        this.setState({
          sessions: _orderBy(response.data.results, 'checkin_time')
        });
      }
      this.setState({ fetchingData: false });
    }).catch((error) => {
      this.setState({ fetchingData: false });
      handleApiExceptions(error);
    });
  };

  fetchWalkins = () => {
    this.setState({ fetchingData: true });
    SessionService.list({
      checkin_type: 'walkin',
      created_at__gte: daterange_for_filter(moment(), moment())[0],
      created_at__lte: daterange_for_filter(moment(), moment())[1],
      checkin_time__isnull: true,
    }).then((response) => {
      if (response.success) {
        this.setState({
          walkins: _orderBy(response.data.results, 'created_at')
        });
      }
      this.setState({ fetchingData: false });
    }).catch((error) => {
      this.setState({ fetchingData: false });
      handleApiExceptions(error);
    });
  };

  createWalkins = (customer) => {
    this.setState({ fetchingData: true });
    SessionService.create({
      customer: customer.id,
      note: "walkin",
      created_by: auth.user.id,
    }).then((response) => {
      if (response.success) {
        this.setState({
          addWalkinsModal: false,
          activeCustomer: {}
        }, () => {
          this.fetchWalkins();
        });
      }
      this.setState({ fetchingData: false });
    }).catch((error) => {
      this.setState({ fetchingData: false });
      handleApiExceptions(error);
    });
  };

  fetchQueue = () => {
    this.setState({ fetchingData: true });
    const filters = { session__checkout_time__isnull: true };
    Object.keys(this.state.queueFilters).forEach(x => {
      if (this.state.queueFilters[x]) {
        filters[x] = this.state.queueFilters[x];
      }
    });
    QueueService.list({
      ...filters,
      queue_date: moment().format("YYYY-MM-DD"),
    }).then((response) => {
      if (response.success) {
        this.setState({
          queueList: _orderBy(response.data.results, 'created_at')
        });
      }
      this.setState({ fetchingData: false });
    }).catch((error) => {
      this.setState({ fetchingData: false });
      handleApiExceptions(error);
    });
  };

  moveToQueue = (session) => {
    console.log('moving to queue', session);
    this.setState({ fetchingData: true });
    QueueService.create({
      session: session.id,
      created_by: auth.user.id,
      session_service_requested: session.services_requested[0].id,
      queue_date: moment().format("YYYY-MM-DD")
    }).then((response) => {
      if (response.success) {
        this.fetchQueue();
        this.fetchAppointments();
        this.fetchWalkins();
      }
      this.setState({ fetchingData: false });
    }).catch((error) => {
      this.setState({ fetchingData: false });
      handleApiExceptions(error);
    });
  };

  deleteQueue = (queue) => {
    this.setState({ fetchingData: true });
    QueueService.delete({ id: queue.id }).then((response) => {
      if (response.success) {
        this.fetchQueue();
        this.fetchAppointments();
        this.fetchWalkins();
      }
      this.setState({ fetchingData: false });
    }).catch((error) => {
      this.setState({ fetchingData: false });
      handleApiExceptions(error);
    });
  };

  fetchCustomerList = () => {
    this.setState({ fetchingData: true });
    UserService.getCustomerList().then((response) => {
      if (response.success) {
        this.setState({
          customerList: response.data.results
        });
      }
      this.setState({ fetchingData: false });
    }).catch((error) => {
      this.setState({ fetchingData: false });
      handleApiExceptions(error);
    });
  };

  selectStaff = (id) => {
    const staff = this.state.staffList.find(x => x.id == id);
    const filters = this.state.filters;
    filters.session_requests__staff = staff ? staff.id : undefined;
    this.setState({ activeStaff: staff, filters }, () => this.fetchAppointments());
  };

  handleCancelledToggle = (show) => {
    const filters = this.state.filters;
    filters.is_cancelled__in = show ? "true,false" : "false";
    this.setState({ filters }, () => this.fetchAppointments());
  };

  setDateRange = (range) => {
    const date_range = daterange_for_filter(range[0], range[1]);
    const filters = this.state.filters;
    filters.scheduled_at__gte = date_range[0];
    filters.scheduled_at__lte = date_range[1];
    this.setState({ filters: filters });
  };

  setStatus = (id) => {
    const filters = this.state.filters;
    filters.status__in = (id || []).join(",");
    if (!id || !id.length) { filters.status__in = undefined; }
    this.setState({ filters: filters });
  };

  toggleSessionModal = (session, sessionType, is_edit, for_queue = false, schedule_for_later = false) => {
    for_queue = typeof for_queue === "boolean" ? for_queue : false;
    schedule_for_later = typeof schedule_for_later === "boolean" ? schedule_for_later : false;
    this.setState({
      sessionModal: !this.state.sessionModal,
      activeSession: { ...session, is_edit, for_queue, schedule_for_later },
      sessionType: sessionType
    });
  };

  toggleBillModal = (session) => {
    this.setState({ activeSession: session, billModal: !this.state.billModal });
  };

  toggleCheckoutModal = (session) => {
    this.setState({ activeSession: session, checkoutModal: !this.state.checkoutModal });
  };

  toggleWalkinsModal = () => {
    this.setState({
      addWalkinsModal: !this.state.addWalkinsModal,
      activeCustomer: {}
    });
  };

  setQueueFilter = (key, value) => {
    const queue_filters = this.state.queueFilters;
    queue_filters[key] = value.join(",");
    this.setState({ queueFilters: queue_filters });
  };

  render() {
    // console.log('sessionType', this.state.sessionType);
    return (
      <div id="appointment-queue-wrapper" className="d-flex w-100">
        <div className={`staff-appointments p-3 w-100`}>
          <Typography.Text strong style={{ fontSize: 20 }}>Daily Queue for {humanizeDate(moment(), false)}</Typography.Text>
          <div className="appointment-list mt-2">
            <div className="row">
              {(!this.user_permissions['DAILY_QUEUE:WALKINS']) ? null :
                <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                  <Card title="Walk-Ins" style={{ height: "85vh" }}
                    extra={<PermissableButton
                      required_permission={["DAILY_QUEUE:WALKINS:ADD"]}
                      icon="plus" size="small"
                      loading={this.state.fetchingData}
                      onClick={() => this.toggleSessionModal({}, SessionType.walkin, false)} />}>
                    <List
                      size="large"
                      bordered={false}
                      dataSource={this.state.walkins}
                      renderItem={item => <List.Item>
                        <div className="d-flex align-items-start justify-content-between w-100 px-2">
                          <div className="d-flex flex-column">
                            <div>
                              <Typography.Text strong>Customer:</Typography.Text>
                              <Typography.Text className="ml-2">{user_fullname((item.customer_obj || {}))}</Typography.Text>
                            </div>
                            <div>
                              <Typography.Text strong>Service:</Typography.Text>
                              <div className="d-flex flex-column">
                                {item.services_requested.map(sr =>
                                  <Typography.Text className="ml-2" key={sr.id}>{sr.service.name}</Typography.Text>
                                )}
                              </div>
                            </div>
                            <div>
                              <Typography.Text strong>Schedule:</Typography.Text>
                              <Typography.Text className="ml-2">{humanizeDate(item.scheduled_at)}</Typography.Text>
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <Button loading={this.state.fetchingData}
                              onClick={() => this.moveToQueue(item)}>Move to Queue</Button>
                            <Button className="mt-1"
                              loading={this.state.fetchingData}
                              onClick={() => this.toggleSessionModal(item, SessionType.walkin, true, false, true)}>Schedule for Later</Button>
                          </div>
                        </div>
                      </List.Item>}
                    />
                  </Card>
                </div>
              }
              {(!this.user_permissions['DAILY_QUEUE:APPOINTMENTS']) ? null :
                <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                  <Card title="Appointments" style={{ height: "85vh" }}
                    extra={<PermissableButton
                      required_permission={["DAILY_QUEUE:APPOINTMENTS:ADD"]}
                      icon="plus" size="small"
                      loading={this.state.fetchingData}
                      onClick={() => this.toggleSessionModal({}, SessionType.appointment, false)} />}>
                    <div className="d-flex align-items-center justify-content-between py-2 border-bottom"
                      style={{ background: "rgba(0,0,0,0.1)" }}>
                      <div>
                        <Select size="default"
                          value={this.state.activeStaff ? this.state.activeStaff.id : {}}
                          placeholder="Select Staff"
                          allowClear={false}
                          className="ml-2" style={{ minWidth: 150, maxWidth: 350 }}
                          onChange={this.selectStaff}>
                          <Select.Option key={'all'} value={{}}>All Staff</Select.Option>
                          {
                            (this.state.staffList || []).map((staff, index) => (
                              <Select.Option key={index} value={staff.id}>{user_fullname(staff)}</Select.Option>
                            ))
                          }
                        </Select>
                        <Select
                          mode="multiple" size="default" placeholder="Select Status"
                          style={{ minWidth: 150, maxWidth: 350 }}
                          className="ml-2"
                          onChange={this.setStatus}
                          allowClear>
                          {
                            (auth.user.merchant.appointment_status || []).map((status, index) => (
                              <Select.Option key={index} value={status.id}>{status.key_label}</Select.Option>
                            ))
                          }
                        </Select>
                        <Button icon="search" className="ml-2" onClick={this.fetchAppointments}>Apply Filter</Button>
                      </div>
                    </div>
                    <List
                      size="large"
                      bordered={false}
                      dataSource={this.state.appointments.filter(x => !this.state.queueList.map(y => y.appointment).includes(x.id))}
                      renderItem={item => <List.Item>
                        <div className="d-flex d-flex align-items-start justify-content-between w-100 px-2">
                          <div className="d-flex flex-column">
                            <div>
                              <Typography.Text strong>Customer:</Typography.Text>
                              <Typography.Text className="ml-2">{user_fullname((item.customer_obj || {}))}</Typography.Text>
                            </div>
                            <div>
                              <Typography.Text strong>Service:</Typography.Text>
                              <div className="d-flex flex-column">
                                {item.services_requested.map(sr =>
                                  <Typography.Text className="ml-2" key={sr.id}>{sr.service.name}</Typography.Text>
                                )}
                              </div>
                            </div>
                            <div>
                              <Typography.Text strong>Schedule:</Typography.Text>
                              <Typography.Text className="ml-2">{humanizeDate(item.scheduled_at)}</Typography.Text>
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <PermissableButton required_permission={["DAILY_QUEUE:APPOINTMENTS:EDIT"]}
                              loading={this.state.fetchingData}
                              onClick={() => this.moveToQueue(item)}>Check-In</PermissableButton>
                            <PermissableButton required_permission={["DAILY_QUEUE:APPOINTMENTS:EDIT"]}
                              className="mt-1"
                              loading={this.state.fetchingData}
                              onClick={() => this.toggleSessionModal(item, SessionType.appointment, true)}>Reschedule</PermissableButton>
                          </div>
                        </div>
                      </List.Item>}
                    />
                  </Card>
                </div>
              }
              {(!this.user_permissions['DAILY_QUEUE:QUEUE']) ? null :
                <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                  <Card title="Queue" style={{ height: "85vh" }} >
                    <div className="d-flex align-items-center justify-content-between py-2 border-bottom"
                      style={{ background: "rgba(0,0,0,0.1)" }}>
                      <div>
                        <Select mode="multiple"
                          size="default"
                          placeholder="Select Status"
                          style={{ minWidth: 150, maxWidth: 350 }}
                          className="ml-2"
                          onChange={() => this.setQueueFilter('appointment__status__in')}
                          allowClear>
                          {
                            (auth.user.merchant.appointment_status || []).map((status, index) => (
                              <Select.Option key={index} value={status.id}>{status.key_label}</Select.Option>
                            ))
                          }
                        </Select>
                        <Button icon="search" className="ml-2" onClick={this.fetchQueue}>Apply Filter</Button>
                      </div>
                    </div>
                    <List
                      size="large"
                      bordered={false}
                      dataSource={this.state.queueList}
                      renderItem={item => {
                        const session = item.session_obj;
                        return (
                          <List.Item extra={
                            <div className="d-flex flex-column mr-2">
                              <PermissableButton
                                required_permission={["DAILY_QUEUE:QUEUE:EDIT"]}
                                style={{ minWidth: 80 }}
                                loading={this.state.fetchingData}
                                onClick={() => this.deleteQueue(item)}>Revert</PermissableButton>
                              <PermissableButton required_permission={["DAILY_QUEUE:QUEUE:EDIT"]}
                                style={{ minWidth: 80 }}
                                loading={this.state.fetchingData}
                                className="mt-1"
                                onClick={() => this.toggleSessionModal(session, session.checkin_type, true)}>Edit</PermissableButton>
                              <PermissableButton required_permission={["DAILY_QUEUE:QUEUE:EDIT"]}
                                style={{ minWidth: 80 }}
                                loading={this.state.fetchingData}
                                className="mt-1"
                                onClick={() => this.toggleCheckoutModal(session)}>Checkout</PermissableButton>
                            </div>
                          }>
                            <div className="d-flex d-flex align-items-start justify-content-between w-100 px-2">
                              <div className="d-flex flex-column">
                                <div>
                                  <Typography.Text strong>Customer:</Typography.Text>
                                  <Typography.Text className="ml-2">{user_fullname(session.customer_obj)} (Token - {item.token_no})</Typography.Text>
                                </div>
                                <div>
                                  <Typography.Text strong>Service:</Typography.Text>
                                  <Typography.Text className="ml-2">{session.services_requested[0].service.name}</Typography.Text>
                                </div>
                                {/* <div>
                                  <Typography.Text strong>Service:</Typography.Text>
                                  <Typography.Text className="ml-2">{session.services_requested[0].service.name}</Typography.Text>
                                </div> */}
                                <div>
                                  <Typography.Text strong>Checkin time:</Typography.Text>
                                  <Typography.Text className="ml-2">{humanizeDate(session.checkin_time)}</Typography.Text>
                                </div>
                                <div>
                                  <Typography.Text strong>Scheduled at:</Typography.Text>
                                  <Typography.Text className="ml-2">{humanizeDate(session.scheduled_at)}</Typography.Text>
                                </div>
                                <div>
                                  <Typography.Text strong>Staff:</Typography.Text>
                                  <Typography.Text className="ml-2">{user_fullname(session.services_requested[0].staff_obj)}</Typography.Text>
                                </div>
                                <div>
                                  <Typography.Text strong>Status:</Typography.Text>
                                  <Typography.Text className="ml-2">{session.status_name}</Typography.Text>
                                </div>
                              </div>
                            </div>
                          </List.Item>
                        );
                      }
                      }
                    />
                  </Card>
                </div>
              }
            </div>
          </div>
        </div>
        <EaseModal
          title={this.state.sessionType === SessionType.appointment ? "Appointment" : "Walkin"}
          hideFooter
          visible={this.state.sessionModal}
          onCancel={() => this.toggleSessionModal({}, this.state.sessionType, false)}
        >
          <SessionForm
            sessionType={this.state.sessionType}
            disableCustomer={!!this.state.activeSession.id}
            defaultCustomer={this.state.activeSession.contact_obj}
            session={this.state.activeSession}
            submitText={this.state.activeSession.for_queue ? "Create and Move to Queue" : undefined}
            afterSubmit={(session) => {
              if (this.state.activeSession.for_queue) {
                this.moveToQueue(session);
                if (this.state.activeSession.checkin_type === 'appointment') {
                  this.fetchAppointments();
                } else {
                  this.fetchWalkins();
                }
              }
              if (this.state.sessionType === 'appointment') {
                this.fetchAppointments();
              } else {
                this.fetchWalkins();
              }
              this.toggleSessionModal({}, false);
            }}
          />
        </EaseModal>

        <EaseModal
          className="bill-modal"
          title={false}
          hideFooter
          visible={this.state.billModal}
          onCancel={() => this.toggleBillModal({})}
        >
          <Bill session={this.state.activeSession} afterUpdate={this.fetchAppointments} />
        </EaseModal>
        <EaseModal
          title={'Customer Checkout'}
          width={isMobile ? '80%' : 600}
          hideFooter
          visible={this.state.checkoutModal}
          onCancel={() => this.toggleCheckoutModal({})}
        >
          <Checkout session={this.state.activeSession}
            servicesProvided={this.state.activeSession.services_requested}
            afterUpdate={this.fetchQueue}
            onCancel={() => this.toggleCheckoutModal({})} />
        </EaseModal>
        {/* <EaseModal
          className="add-walkins-modal"
          title="Add Walkin Customer"
          hideFooter
          visible={this.state.addWalkinsModal}
          onCancel={this.toggleWalkinsModal}
        >
          <Typography.Text>Select Customer</Typography.Text>
          <div className="d-flex">
            <Select size="default"
              placeholder="Select Customer"
              className="w-100 mr-2"
              onChange={(value) => {
                this.setState({
                  activeCustomer: this.state.customerList.find(c => c.id == value) || {}
                });
              }}>
              {
                (this.state.customerList || []).map((customer, index) => (
                  <Select.Option
                    key={index}
                    value={customer.id}>{user_fullname(customer)}</Select.Option>
                ))
              }
            </Select>
            <Button
              loading={this.state.fetchingData}
              disabled={!this.state.activeCustomer.id}
              onClick={() => this.createWalkins(this.state.activeCustomer)}>Add</Button>
          </div>
          <Divider className="my-3">Or</Divider>
          <Typography.Text>Add New Customer</Typography.Text>
          <CustomerForm customer={{}} afterSubmit={this.createWalkins} />
          <div className="clearfix" />
        </EaseModal> */}
      </div >
    );
  }
}

export default PermissionHoc(AppointmentQueue, ["DAILY_QUEUE"]);