import React from 'react';
import moment from 'moment/moment';
import './index.scss';
import { Select } from 'antd';

const TimePicker = (props) => {
    return (
        <Select
            style={{ width: 100 }}
            className={props.className}
            defaultValue={props.defaultValue ? moment(props.defaultValue, "HH:mm").format("HH:mm") : undefined}
            value={props.value ? moment(props.value, "HH:mm").format("HH:mm") : undefined}
            onChange={(time) => onChange(time, props)}
            disabled={props.disabled}
            style={props.style}
        >
            {renderTimeOptions(props)}
        </Select>
    );
};

const renderTimeOptions = (props) => {
    const options = [];
    const time_range = getTimeRange(props);
    for (let i = 0; i <= 23; i++) {
        if (i >= time_range.minHour && i <= time_range.maxHour) {
            const minJ = i == time_range.minHour ? time_range.minMinute : 0;
            const maxJ = i == time_range.maxHour ? time_range.maxMinute : 59;
            for (let j = minJ; j <= maxJ; j++) {
                if (j % 5 == 0) {
                    const hrStr = (i.toString().length == 1) ? `0${i}` : `${i}`;
                    const minStr = (j.toString().length == 1) ? `0${j}` : `${j}`;
                    const samay = `${hrStr}:${minStr}`;
                    let is_disabled = false;
                    props.disabledRanges.forEach(range => {
                        const start = moment(range.start, "HH:mm");
                        const end = moment(range.end, "HH:mm");
                        if (moment(samay, "HH:mm").isBetween(start, end, undefined, true)) {
                            is_disabled = true;
                        }
                    });
                    if (!is_disabled) {
                        options.push(<Select.Option key={`${hrStr}${minStr}`} value={samay}>{samay}</Select.Option>);
                    }
                }
            }
        }
    }
    return options;
};

const onChange = (time, props) => {
    props.onChange(moment(time, 'HH:mm'));
};

const getTimeRange = (props) => {
    const minHour = props.minTime.split(":")[0];
    const minMinute = props.minTime.split(":")[1];
    const maxHour = props.maxTime.split(":")[0];
    const maxMinute = props.maxTime.split(":")[1];
    return { minHour, minMinute, maxHour, maxMinute };
};

TimePicker.defaultProps = {
    minTime: "00:00",
    maxTime: "23:59",
    className: "",
    onChange: () => { },
    defaultValue: undefined,
    disabledRanges: [],
    disabled: false,
    style: {}
};

export default TimePicker;