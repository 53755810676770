import React, { Component } from 'react';
import { Form, Input, Avatar, Tag, Table, Button, Switch, Icon, Typography, TimePicker, Empty } from 'antd';
import { isMobile } from 'react-device-detect';
import { MerchantService, auth } from '../../../../services';
import { handleApiExceptions } from '../../../../app_methods';

class BusinessReview extends Component {
    columns = [{
        title: 'Rating',
        dataIndex: 'rating',
        key: 'rating',
    }, {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
    }];

    state = {
        reviews: []
    }

    componentDidMount(){
        this.fetchReviews();
    }

    fetchReviews() {
        MerchantService.getReviews({ merchant: auth.user.merchant.id }).then((response) => {
            if (response.success) {
                this.setState({ reviews: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        })
    }

    render() {
        return (
            <div>
                <Typography.Title level={4} className="mb-3 mt-3" style={{ color: "#455862" }}>Business Review</Typography.Title>
                <Table scroll={{ x: isMobile ? true : false }} columns={this.columns} dataSource={this.state.reviews} pagination={false} bordered={false} />
            </div>
        )
    }
}

export default BusinessReview;
