import React, { Component } from 'react';
import './index.scss';
import SettingOptions from './SettingOptions';
import { isMobile } from 'react-device-detect';
import SettingOptionsMobile from './SettingOptionsMobile';
import { MerchantBasicDetails, BusinessReview, Holiday } from '../../../Common/Forms';
import Preferences from '../../../Common/Forms/MerchantForm/Preferences';
import { MerchantService, auth } from '../../../../services';
import { handleApiExceptions } from '../../../../app_methods';

class AccountSettings extends Component {
    selectOption = this.selectOption.bind(this);
    state = {
        activeOption: {},
        merchant: {}
    }

    componentDidMount() {
        this.fetchMerchant();
    }

    fetchMerchant() {
        MerchantService.show({ id: auth.user.merchant.id }).then((response) => {
            if (response.success) {
                this.setState({
                    merchant: { ...response.data, is_edit: true }
                }, () => {
                    this.selectOption({ id: 1, name: "Basic Details", component: <MerchantBasicDetails merchant={this.state.merchant} /> })
                })
            }
        }).catch((error) => {
            handleApiExceptions(error);
        })
    }

    selectOption(option) {
        this.setState({ activeOption: option });
    }

    render() {
        const options = [
            { id: 1, name: "Basic Details", component: <MerchantBasicDetails merchant={this.state.merchant} /> },
            { id: 2, name: "Holidays", component: <Holiday /> },
            { id: 3, name: "Preferences", component: <Preferences changeTheme={this.props.changeTheme} /> },
            { id: 4, name: "Reviews", component: <BusinessReview /> },
        ]
        return (
            <div id="account-settings-wrapper" className="d-flex w-100">
                <div className={`account-setting-options p-3 ${isMobile ? 'd-none' : ''}`}>
                    <SettingOptions options={options} selectOption={this.selectOption} activeOption={this.state.activeOption} changeTheme={this.props.changeTheme} />
                </div>
                <div className={`account-setting-details p-3 ${isMobile ? 'w-100' : ''}`}>
                    <SettingOptionsMobile options={options} activeOption={this.state.activeOption} selectOption={this.selectOption.bind(this)} />
                    {this.state.activeOption.component}
                </div>
            </div>
        )
    }
}

export default AccountSettings;