export const PageTitles = {
  customerBooking: {
    routeTo: '/customer/booking',
    pageTitle: 'New Appointment',
    matchRoute: 'booking'
  },
  customerVisits: {
    routeTo: '/customer/sessions',
    pageTitle: 'My Visits',
    matchRoute: 'sessions'
  },
  customerProfile: {
    routeTo: '/customer/profile',
    pageTitle: 'My Profile',
    matchRoute: 'profile'
  },
  customerSettings: {
    routeTo: '/customer/settings',
    pageTitle: 'Settings',
    matchRoute: 'settings'
  },
}