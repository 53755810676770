import React, { Component } from 'react';
import { Input, Button } from 'antd';
import { NumericInput } from '../../NumericInput';

export const CustomerModalForm = (props) => {
  return (
    <div className="d-flex flex-column">
      <Input name="first_name"
        value={props.customer.first_name}
        onChange={props.handleFirstNameChange}
        placeholder="First Name"
        style={props.fullWidth ? null : { width: "200px" }} />
      <Input name="last_name"
        value={props.customer.last_name}
        onChange={props.handleLastNameChange}
        placeholder="Last Name"
        style={props.fullWidth ? null : { width: "200px" }}
        className="mt-2" />
      <Input name="email"
        value={props.customer.email}
        onChange={props.handleEmailChange}
        placeholder="Email Address"
        style={props.fullWidth ? null : { width: "200px" }}
        className="mt-2" />
      <NumericInput
        name="phone"
        value={props.customer.phone}
        onChange={props.handleMobileChange}
        placeholder="Mobile"
        style={props.fullWidth ? null : { width: "200px" }}
        maxLength={10}
        className="mt-2" />
      <Button type="primary" onClick={props.addCustomer} className="mt-2">Add</Button>
    </div>
  );
};