import React, { Component } from 'react';
import { Table, Switch, Typography } from 'antd';
import moment from 'moment/moment';
import { isMobile } from 'react-device-detect';
import { WorkingHourService, auth } from '../../../../services';
import { TimePicker } from '../../index';
import { handleApiExceptions, alertify, user_fullname } from '../../../../app_methods';


class StaffWorkingHours extends Component {
    fetchStaffWorkingHours = this.fetchStaffWorkingHours.bind(this);
    handleActiveChange = this.handleActiveChange.bind(this);
    // disabledHours = this.disabledHours.bind(this)

    state = {
        workingHours: [],
        processing: false,
        settings: auth.settings() || {}
    };

    columns = [{
        title: 'Day',
        key: 'day',
        render: (record) => <span>{record.display_day}</span>
    }, {
        title: 'On/Off',
        key: 'on_off',
        render: (record) => <Switch loading={this.state.processing} checkedChildren="On" unCheckedChildren="Off" defaultChecked={record.currently_active} checked={record.currently_active} onChange={this.handleActiveChange.bind(this, record)} />
    }, {
        title: 'Work Hours',
        key: 'work_hours',
        render: (record) => {
            return (
                <div className="d-flex align-items-center">
                    <TimePicker
                        style={{ width: 100 }}
                        className="mr-2"
                        minTime={(this.state.settings.business_hours || {}).start}
                        maxTime={(this.state.settings.business_hours || {}).end}
                        defaultValue={moment(record.day_start_time, 'HH:mm')}
                        value={moment(record.day_start_time, 'HH:mm')}
                        disabled={!record.currently_active || this.state.processing}
                        onChange={(val) => this.handleTimeChange(record, "day_start_time", val)}
                    />
                    <span>to</span>
                    <TimePicker
                        style={{ width: 100 }}
                        className="ml-2"
                        minTime={record.day_start_time}
                        maxTime={(this.state.settings.business_hours || {}).end}
                        defaultValue={moment(record.day_end_time, 'HH:mm')}
                        value={moment(record.day_end_time, 'HH:mm')}
                        disabled={!record.currently_active || this.state.processing}
                        onChange={(val) => this.handleTimeChange(record, "day_end_time", val)}
                    />
                </div>
            );
        }
    }];

    componentDidMount() {
        this.fetchStaffWorkingHours(this.props.staffDetails.id);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.staffDetails.id !== nextProps.staffDetails.id) {
            this.fetchStaffWorkingHours(nextProps.staffDetails.id);
        }
    }

    getDisabledHours(day_start_time) {
        const hours = [];
        for (let i = 0; i < moment(day_start_time, "HH:mm").hour(); i++) {
            hours.push(i);
        }
        return hours;
    }

    getDisabledMinutes(day_start_time, selected_hour) {
        const minutes = [];
        if (selected_hour === moment(day_start_time, "HH:mm").hour()) {
            for (let i = 0; i <= moment(day_start_time, "mm:mm").minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    fetchStaffWorkingHours(id) {
        if (!id) { alertify("Oops!!!", "Select staff to continue.", "warning"); return; }
        WorkingHourService.list({ staff: id }).then((response) => {
            if (response.success) {
                this.setState({ workingHours: response.data });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    }

    handleActiveChange(record, active) {
        this.updateWorkingHours({ ...record, currently_active: active });
    }

    handleTimeChange(record, key, value) {
        const business_hours = this.state.settings.business_hours;
        if (key === "day_start_time") {
            if (moment(business_hours.start, "HH:mm").isAfter(value)) {
                alertify("Oops!!!", `Business hours are ${business_hours.start} to ${business_hours.end}. Staff working hours must be between business hours.`, "warning");
                this.fetchStaffWorkingHours(this.props.staffDetails.id);
                return;
            }
        } else if (key === "day_end_time") {
            if (moment(business_hours.end, "HH:mm").isBefore(value)) {
                alertify("Oops!!!", `Business hours are ${business_hours.start} to ${business_hours.end}. Staff working hours must be between business hours.`, "warning");
                this.fetchStaffWorkingHours(this.props.staffDetails.id);
                return;
            }
        }
        this.updateWorkingHours({ ...record, [key]: value.format("HH:mm") });
    }

    updateWorkingHours(work_hours) {
        this.setState({ processing: true });
        WorkingHourService.update(work_hours).then((response) => {
            if (response.success) {
                this.fetchStaffWorkingHours(this.props.staffDetails.id);
            }
            this.setState({ processing: false });
        }).catch((error) => {
            handleApiExceptions(error);
            this.setState({ processing: false });
        });
    }

    render() {
        return (
            <div id="staff-services" className="mx-auto" style={{ width: "100%" }}>
                <Typography.Title level={4} className="mb-3 mt-3" style={{ color: "#455862" }}>{user_fullname(this.props.staffDetails)}'s Working Hours</Typography.Title>
                <Table scroll={{ x: isMobile ? true : false }} columns={this.columns} dataSource={this.state.workingHours} pagination={false} bordered={false} />
            </div>
        );
    }
}

export default StaffWorkingHours;
