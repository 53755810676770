import React, { Component } from 'react';
import './index.scss';
import { Typography, Button, Popover, Input, List, Avatar } from 'antd';
import { StaffForm } from '../../../Common/Forms';

class StaffWizard extends Component {
    state = {}

    render() {
        return (
            <div>
                <StaffForm fetchStaffList={this.props.fetchStaffList} staffDetails={this.props.staffDetails} />
            </div>
        )
    }
}

export default StaffWizard;