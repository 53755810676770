import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Card, Avatar, Typography, Icon } from 'antd';
import './index.scss';
import { CustomerBookingForm } from '../../Common/Forms';
import { MerchantService } from '../../../services';
import { handleApiExceptions, resolve_server_image } from '../../../app_methods';


class CustomerBooking extends Component {
    state = {
        merchant: {},
        cardStyles: {}
    };

    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
        this.merchantUid = this.props.match.params.merchant_uid;
        this.fetchMerchant(this.merchantUid);
        
    }

    fetchMerchant(uid) {
        MerchantService.list({ uid }).then((response) => {
            if (response.success) {
                this.setState({ merchant: response.data.length ? response.data[0] : {} });
            }
        }).catch((error) => {
            handleApiExceptions(error);
        });
    }

    getCardStyles() {
        return 
    }

    render() {
        if (!this.state.merchant.id) { return <div>Loading...</div>; }
        return (
            <div style={{ height: '100%', overflow: 'auto' }}>
                <div style={{ width: 1200, marginTop: 24, maxWidth: "90%" }} className="mx-auto booking-wrapper">
                    <div className="merchant-info d-flex align-items-center justify-content-between px-3 mb-2 header">
                        <div className="d-flex align-items-center">
                            <Avatar size="large" src={this.state.merchant.image ? resolve_server_image(this.state.merchant.image) : require('../../../assets/images/default.png')} />
                            <Typography.Text strong className="ml-3" style={{ fontSize: 22 }}>{this.state.merchant.name}</Typography.Text>
                        </div>
                        <div className="d-flex flex-column">
                            <Typography.Text><Icon type="mobile" className="mr-2" />{this.state.merchant.spoc_number || "---"}</Typography.Text>
                            <Typography.Text><Icon type="mail" className="mr-2" />{this.state.merchant.spoc_email || "---"}</Typography.Text>
                        </div>
                    </div>
                    <Card style={this.state.cardStyles} className="mx-auto shadow-sm">
                        {/* <BookingForm merchant={this.state.merchant} /> */}
                        <CustomerBookingForm merchant={this.state.merchant} />
                    </Card>
                </div>
            </div>
        );
    }
}



export default withRouter(CustomerBooking);