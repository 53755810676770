import React, { Component } from 'react';
import { Typography, Divider, Button } from 'antd';
import { humanizeDate, user_fullname, handleApiExceptions, alertify } from '../../../app_methods';
import { AppointmentService } from '../../../services';

class Bill extends Component {

    state = {
        processing: false,
        is_paid: false
    };

    markAsPaid = () => {
        this.setState({ processing: true });
        AppointmentService.update({ id: this.props.session.id, is_paid: true }).then((response) => {
            if (response.success) {
                alertify("Awesome!!!", "Appointment marked as paid.", "success");
                this.setState({ processing: false, is_paid: true }, () => {
                    this.props.afterUpdate();
                });
            } else {
                this.setState({ processing: false });
                alertify("Oops!!!", "Could not update appintment. Try again", "error");
            }
        }).catch((error) => {
            this.setState({ processing: false });
            handleApiExceptions(error);
        });
    };

    render() {
        return (
            <div>
                <Typography.Title title={4} style={{ fontSize: 24 }}>Receipt</Typography.Title>
                <Typography.Text className="float-right">{humanizeDate(this.props.session.scheduled_at, false)}</Typography.Text>
                <div className="clearfix" />
                <Divider className="m-1" />
                <div className="customer-details">
                    <Typography.Text className="d-block" strong>{user_fullname(this.props.session.customer_obj)}</Typography.Text>
                    <Typography.Text className="d-block">{this.props.session.customer_obj.email}</Typography.Text>
                    <Typography.Text className="d-block">{this.props.session.customer_obj.phone}</Typography.Text>
                </div>
                <div className="invoice-items d-table w-100 mt-4">
                    <div className="d-table-row">
                        <div className="d-table-cell pr-3 border-bottom">
                            <Typography.Text strong>Service</Typography.Text>
                        </div>
                        <div className="d-table-cell pr-3 border-bottom">
                            <Typography.Text strong>Duration</Typography.Text>
                        </div>
                        <div className="d-table-cell pr-3 border-bottom">
                            <Typography.Text strong>Rate</Typography.Text>
                        </div>
                    </div>
                    <div className="d-table-row">
                        <div className="d-table-cell pr-3 pt-2">
                            <div className="d-flex flex-column">
                                <Typography.Text>{this.props.session.services_requested[0].service.name}</Typography.Text>
                                {/* <Typography.Text type="secondary">{this.props.session.service_obj.description}</Typography.Text> */}
                            </div>
                        </div>
                        <div className="d-table-cell pr-3 pt-2">{this.props.session.services_requested[0].service.service_minutes} minutes</div>
                        <div className="d-table-cell pr-3 pt-2">Rs. {this.props.session.amount}/-</div>
                    </div>
                </div>
                <div className="staff-notes mt-4">
                    <Typography.Text className="d-block" strong>Staff Note:</Typography.Text>
                    <Typography.Text className="d-block">{this.props.session.services_requested[0].note_by_staff || "---"}</Typography.Text>
                </div>

                <Button className="float-right mt-3"
                    loading={this.state.processing}
                    disabled={this.state.is_paid || this.props.session.is_paid}
                    onClick={this.markAsPaid}>Mark as Paid</Button>
                <div className="clearfix" />
            </div>
        );
    }
}

Bill.defaultProps = {
    session: {},
    afterUpdate: () => { }
};

export default Bill;